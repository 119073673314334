#footer{
    color: $blanc;
    >.inner{
        @media #{$menu_switch}{
            display: flex;
        }
        > div{
            display: flex;
            //align-items: center;
            justify-content: center;
            > div{
                max-width: 400px;
                margin-top: $gutter*2;
                margin-bottom: $gutter*2;
            }
        }
        .left{
            background-color: $bleu1;
            width: 100%;
            @media #{$menu_switch}{
                width: 70%;
            }
        }
        .right{
            background-color: $bleu2;
            width: 100%;
            @media #{$menu_switch}{
                width: 30%;
            }
        }
    }
    h3{
        @extend .font2;
        font-size: 150%;
        text-transform: uppercase;
        letter-spacing: 4px;
    }
    .bloc_text{
        padding-left: $gutter;
        padding-right: $gutter;
        > * {
            margin-bottom: $gutter;
        }
    }
}

.socials{
    display: flex;
    gap: $gutter/4;
    .social{
        $social_size: 40px;
        border-radius: 50%;
        width: $social_size;
        height: $social_size;
        background-color: $blanc;
        color: $bleu1;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
            &:before{
                vertical-align: middle;
                line-height: 1;
            }
        }
        transform: scale(1) rotate(0deg);
        &:hover{
            transform: scale(1.2) rotate(10deg);
        }
    }
}
