.item_list_wrapper{
    display: flex;
    flex-wrap: wrap;
    padding-left: $gutter/2;
    padding-right: $gutter/2;
    padding-bottom: $gutter*2;
    //gap: $gutter/1;
}

.intro.blog{
    display: inline-block;    
    box-shadow: $box-shadow;
    border-radius: 0.5em;
    //overflow: hidden;
    hyphens: auto;
    background-color: $blanc;
    width: 98%;
    margin: 0 2% $gutter 2%;
    //margin-bottom: $gutter;

    @media #{$md}{
        width: 46%;
    }
    @media #{$lg}{
        width: 29.33%;
    }
    .intro_wrapper{
        .top{
            position: relative;
            .wrapper_image_couverture{
                .couverture{
                    height: 200px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
            }
        }
        .bottom{
            padding: $gutter;
            .titre_block{
                margin-bottom: $gutter/2;
                margin-top: -4rem;
                text-align: center;
                a{
                    background-color: $blanc;
                    padding: $gutter/4 $gutter/2;
                    display: inline-block;
                    h2{

                    }
                    transform: scale(1) rotate(0deg);
                    transition: transform 0.3s;
                    &:hover{
                        transform: scale(1.1) rotate(5deg);
                    }
                }
            }
            
            .description_block{
                p{}
            }
        }
    }
}

.tags_block{
    display: flex;
    flex-wrap: wrap;
    gap: $gutter/8;    
    justify-content: center;
    align-items: center;
    margin-bottom: $gutter/1;
    a{
        span{
            &.hash{}
            &.hash_text{}
        }
        transform: scale(1) rotate(0deg);
        transition: transform 0.3s;
        z-index: 0;
        &:hover{
            transform: scale(1.2) rotate(-5deg);
            z-index: 1;
        }
    }
}

.blog_list_links{
    margin-top: $gutter;
    width: 100%;
    text-align: center;
    >a {
        padding: 10px;
        border-radius: 10px;
        box-shadow : 0px 2px 4px 0px rgba(#000, 0.2);
        & + a {
            margin-left: $gutter/2;
        }
        &:hover{
            box-shadow : inset 0px 2px 4px 0px rgba(#000, 0.2);
        }
    }
}

.categorie_item{
    span{        
        font-size: 90%;
        @extend .sb;
        &:before{
            vertical-align: middle;
            margin-right: 0.25rem;
        }
    }
}