@mixin font1 {
	font-family: 'Montserrat',
	sans-serif;
}
@mixin font2 {
	font-family: 'Londrina Solid',
	cursive;
}

//@mixin font2 {
//	font-family: 'Bowlby One SC', cursive;
//}

.font1 {
	@include font1;
	font-weight: 400;
}

.font2{
	@include font2;
	font-weight: 900;
}

//.font2 {
//	@include font2;
//	font-weight: 400;
//}

.sb {
	font-weight: 600;
}

.xbi {
	font-weight: 800;
	font-style: italic;
}

body{
	color: $noir;
}

.disabled-color {
	color: $disabled;
}

.ls {
	letter-spacing: 0.1em;
}

.caps {
	text-transform: uppercase;
}

.tal {
	text-align: left;
}

.tac {
	text-align: center;
}

.tar {
	text-align: right;
}

a,
a:hover,
a:visited,
a:focus {
	color: inherit;
	text-decoration: none;
	outline: 0;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

$box-shadow : 0px 4px 10px 0px rgba(#000, 0.2);

.box-shadow{
	box-shadow: $box-shadow;
}
/* 
button.bouton {
	text-transform: uppercase;
	@extend .sb;
	padding: (($gutter/2) - 0.3) ($gutter - 0.3);
	text-align: center;
	border-radius: 3em;
	letter-spacing: 0.1em;

	transition: color, background-color 0.3s;

	color: $blanc;
	background-color: $bleu1;
	border: 0.3em solid $bleu1;

	&:hover {
		color: $bleu1;
		background-color: $blanc;
	}

	&.reverse {
		color: $bleu1;
		background-color: $blanc;
		border: 0.3em solid $blanc;

		&:hover {
			color: $blanc;
			background-color: $bleu1;
		}
	}
} */

.important {
	padding: 0.1em 0.25em;
	line-height: 1.9;
}

.block_waves {
	margin-bottom: $gutter*4;
	width: 100%;
	text-align: center;
	.block_waves_inner{
		.block_texte{
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	>svg {}
}

.svg_wrapper{
	font-size: 0;
}

.title {
	line-height: 1.2;
	display: block;
	hyphens: auto;
	&.centered{
		text-align: center;
	}
}
h1.title, .h1{
	@extend .font2;
	font-size: 200%;
	letter-spacing: 0.05rem;
	@media #{$md}{	font-size: 300%;	}
	@media #{$lg}{	font-size: 500%;	}
}
h2.title, .h2{
	@extend .font2;
	font-size: 180%;
	letter-spacing: 0.05rem;
	@media #{$md}{	font-size: 200%;	}
	@media #{$lg}{	font-size: 350%;	}
}
h3.title, .h3 {
	font-size: 150%;
	@extend .font1, .sb;
	@media #{$md}{	font-size: 180%;	}
	@media #{$lg}{	font-size: 200%;	}
}

p {
	line-height: 1.7;
}

.paragraphe{
	p {}
	strong{
		@extend .sb;
	}
	a{
		@extend .sb;
		color: $bleu1;
	}
}

a.tags{
	display: inline-block;
	padding: 0.2em 0.5em;
	font-size: 1rem;
	border-radius: 0.3em;
	margin-bottom: $gutter/4;
	@extend .box-shadow;
	color: $blanc;
	span{
		text-transform: lowercase;
		display: inline-block;
	}
	.hash{
		@extend .sb;
		line-height: 1;
		font-size: 130%;
		transform: translateY(7%);
		margin-right: 0.2em;
	}
}

.boutons{
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;;
}
a.bouton, button.bouton, button {
	@extend .font1, .sb;
	display: inline-block;
	text-transform: uppercase;
	font-size: 80%;
	letter-spacing: 0.6px;
	padding: $gutter/3 $gutter/2;
	/* & + a.bouton,	& + button.bouton{
		margin-left: $gutter/2;
	} */
	&.bouton{
		color: $blanc;
	}
}


.block_texte {
	>*:not(:last-child) {
		margin-bottom: $gutter;
	}
	&.reverse{
		.title, .description {
			color: $blanc;
		}
		.bouton {
			background-color: $blanc;
			color: $bleu1;
		}
	}
}


/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
	position: relative;
	z-index: 2;
	cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
	visibility: hidden;
	//-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	//filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
	opacity: 0;
	pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
	position: absolute;
	bottom: 150%;
	left: 50%;
	margin-bottom: 5px;
	margin-left: -80px;
	padding: 7px;
	width: 160px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background-color: #000;
	background-color: hsla(0, 0%, 20%, 0.9);
	color: #fff;
	content: attr(data-tooltip);
	text-align: center;
	font-size: 14px;
	line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
	position: absolute;
	bottom: 150%;
	left: 50%;
	margin-left: -5px;
	width: 0;
	border-top: 5px solid #000;
	border-top: 5px solid hsla(0, 0%, 20%, 0.9);
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	content: " ";
	font-size: 0;
	line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
	visibility: visible;
	//-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	//filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
	opacity: 1;
}