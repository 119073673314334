.type_filter{
    display: flex;
    justify-content: center;
    margin-bottom: $gutter;
    .sujet.tags{
        font-size: 150%;
        .hash{
            vertical-align: top;
            margin-right: 1rem;
        }
    }
}