.page_header{
    text-align: center;
    &.list, &.search{
        margin-bottom: $gutter;
    }
    .page_header_wrapper {
        display: inline-block;
        //text-align: left;
        .title{
            &.parent_cat{
                text-transform: uppercase;
                @extend .sb;
                font-size: 120%;
            }
            &.cat{
            }
        }
        .title_block{
            &.article{
                .titre_top{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 0.25rem;
                    font-size: 1.2rem;
                    @extend .sb;
                    .menu_parent{
                        opacity: 0.8;
                    }
                }   
            }
        }
    }
}