//----------------------------------*\
// Flex + Responsive Mixin
//----------------------------------*/

.df{    display: flex;    }
.fdc{   flex-direction: column; }
.fdr{   flex-direction: row;    }
.fdcr{   flex-direction: column-reverse; }
.fdrr{   flex-direction: row-reverse;    }
.jcc{   justify-content: center;    }
.jcfs{  justify-content: flex-start;    }
.jcfe{  justify-content: flex-end;  }
.jcsa{  justify-content: space-around;  }
.jcsb{  justify-content: space-between; }
.aifs{  align-items: flex-start;    }
.aife{  align-items: flex-end;  }
.aic{   align-items: center;    }
.ais{   align-items: stretch;   }
.fwnw{  flex-wrap: nowrap;  }
.fww{   flex-wrap: wrap;    }
.mra{   margin-right: auto; }
.mla{   margin-left: auto;  }
.mta{   margin-top: auto;   }
.mba{   margin-bottom: auto;    }
.fg1{   flex-grow: 1;   }
.fgi{   flex-grow: initial;   }

@mixin flexHelpers($size_screen) {
    .#{$size_screen}-df{   display: flex; }
    .#{$size_screen}-db{   display: block; }
    .#{$size_screen}-fdc{   flex-direction: column; }
    .#{$size_screen}-fdr{   flex-direction: row;    }
    .#{$size_screen}-fdcr{   flex-direction: column-reverse; }
    .#{$size_screen}-fdrr{   flex-direction: row-reverse;    }
    .#{$size_screen}-jcc{   justify-content: center;    }
    .#{$size_screen}-jcfs{  justify-content: flex-start;    }
    .#{$size_screen}-jcfe{  justify-content: flex-end;  }
    .#{$size_screen}-jcsa{  justify-content: space-around;  }
    .#{$size_screen}-jcsb{  justify-content: space-between; }
    .#{$size_screen}-aifs{  align-items: flex-start;    }
    .#{$size_screen}-aife{  align-items: flex-end;  }
    .#{$size_screen}-aic{   align-items: center;    }
    .#{$size_screen}-ais{   align-items: stretch;   }
    .#{$size_screen}-fwnw{  flex-wrap: nowrap;  }
    .#{$size_screen}-fww{   flex-wrap: wrap;    }
    .#{$size_screen}-mra{   margin-right: auto; }
    .#{$size_screen}-mla{   margin-left: auto;  }
    .#{$size_screen}-mta{   margin-top: auto;   }
    .#{$size_screen}-mba{   margin-bottom: auto;    }      
    .#{$size_screen}-fg1{   flex-grow: 1;;    }      
    .#{$size_screen}-fgi{   flex-grow: initial;    }      
}


@media #{$xs}{    @include flexHelpers('xs'); }
@media #{$sm}{    @include flexHelpers('sm'); }
@media #{$md}{    @include flexHelpers('md'); }
@media #{$lg}{    @include flexHelpers('lg'); }
@media #{$xl}{    @include flexHelpers('xl'); }
@media #{$xl}{    @include flexHelpers('xxl'); }
@media #{$xl}{    @include flexHelpers('xxxl'); }



