/* @font-face {
    font-family: "aquasynchro";
    src: url("../fonts/aquasynchro.eot");
    src: url("../fonts/aquasynchro.eot?#iefix") format("embedded-opentype"),
         url("../fonts/aquasynchro.woff") format("woff"),
         url("../fonts/aquasynchro.ttf") format("truetype"),
         url("../fonts/aquasynchro.svg#aquasynchro") format("svg");
    font-weight: normal;
    font-style: normal;
}
 
[data-icon]:before,
  .cck_button_del > span:before,
  .cck_button_add > span:before,
  .cck_button_drag > span:before {
    font-family: "aquasynchro" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before,
.cck_button_del > span:before,
.cck_button_add > span:before,
.cck_button_drag > span:before {
    vertical-align: middle;
    font-family: "aquasynchro" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\61";
}
.icon-envelope-o:before {
  content: "\62";
}
.icon-caret-right:before {
  content: "\63";
}
.icon-caret-left:before {
  content: "\64";
}
.icon-trash:before {
  content: "\67";
}
.icon-picture-o:before {
  content: "\68";
}
.icon-list-ul:before {
  content: "\69";
}
.icon-align-justify:before {
  content: "\6a";
}
.icon-arrows:before {
  content: "\6b";
}
.icon-plus:before {
  content: "\65";
}
.icon-minus:before {
  content: "\66";
}
.icon-eye:before {
  content: "\6c";
}
.icon-pencil:before {
  content: "\6d";
} */




[class^="icon-"],
[class*=" icon-"] {
    line-height: 1;
}

//sidebar
[icon-size="1"]{font-size: 1.1rem;}


///////////// SEBLOD

.cck_cgx.cck_cgx_button {
  width: 7rem;
  margin-left: 1rem;

  .cck_button {
    display: inline-block;
    vertical-align: middle;
    & + .cck_button{
      margin-left: $gutter/4;
    }

    &_del,
    &_add,
    &_drag {
      width: 1.5rem;
      height: 1.5rem;
      overflow: visible;
      > span {
        float: none;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        vertical-align: middle;
        font-size: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1;
        padding: 0;
        outline: 0;
      }
    }

    &_del > span {
      margin-right: 1rem;

      &:before {
        content: "\67";
        color: red;
      }

    }

    &_add > span {
      margin-right: 1rem;

      &:before {
        content: "\65";
        color: green;
      }

    }

    &_drag > span {
      &:before {
        content: "\6b";
        color: blue;
      }

    }
  }
}
