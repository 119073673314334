$navbar_right_width: 6rem;
$navbar_left_width: 4rem;

$menu_switch : $lg;

$gutter: 2rem;

$menu_height: 2*$gutter;

$spacetop: $gutter*3;

$space-icon: 0.5rem;
$space-icons-group: 0.5rem;

$contentPadding: 40px;

$seekbar_height: 0.5em;
$seekball_height: 1.5em;