#main_menu_container{
    //display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $bleu2;
    overflow-y: auto;
    @extend .scrollable.vertical;
    opacity: 0;
    visibility: hidden;
    #main_menu_inner{
        //margin-top: $gutter;
        //margin-bottom: $gutter;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        //height: 100%;
        //width: 400px;
        //margin-left: auto;
        //margin-right: auto;
        #main_menu_wrapper {
            margin-top: 5rem;
            margin-bottom: 5rem;

            .menu_titre{
                .title{
                    @extend .font2;
                    font-size: 200%;
                    letter-spacing: 10px;
                    color: $blanc;
                }
            }
        }
    }
}

ul.nav{
                color: $blanc;
                ul{}
                li{}
                a{
                }
                a + ul {                    
                    margin-top: $gutter/6;
                }
                li.active{
                    > a{
                        @extend .sb;
                        text-decoration: wavy underline;;
                    }
                }
                > li{
                    margin-bottom: $gutter/6;
                    
                    >a {
                        font-size: 120%;
                    }
                    &.parent{
                        > a {
                            //pointer-events: none;
                            //opacity: 1;
                            &:before{
                                content: "-";
                                margin-right: 0.25rem;
                            }
                        }
                    }
                    >ul {
                        padding-left: $gutter/1.5;
                        >li {
                            margin-bottom: $gutter/6;
                            &.active {
                                >a {
                                    //color: $bleu1;
                                }
                            }
                            > a {
                                font-size: 100%;
                            }
                            >ul {
                                padding-left: $gutter*2;
                                >li {
                                    margin-bottom: $gutter/8;
                                    a {
                                        font-size: 100%;
                                    }
                                }
                            }

                        }
                    }
                }
            }

.menu_titre{
    span{}
    margin-bottom: $gutter/6;
}

