

.overlay{
	position: absolute;
	top: - $menu_height;
	left: 0;
	right: 0;
	//bottom: 30%;
	height: 420px;
	z-index: -1;
	> div{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		&.overlay_cover.blur{    
			background-size: cover;
    		background-position: center center;
			top: -5%;
			bottom: -5%;
			left: -5%;
			right: -5%;
			filter:blur(10px);
		}
	}
}

.cp{
	cursor: pointer;
}
.ci{
	cursor: initial;
}


.h100 {height: 100%;}
.w100 {width: 100%;}

.img_resp{    
	max-width: 100%;
		height: auto;
}
.img_fullw{
	width: 100%;
	height: auto;
}
.img_fullh{
	height: 100%;
	width: auto;
}

.posr{position: relative;}
.posa{position: absolute;}
.posf{position: fixed;}

.db{
	display: block;
}
.dn{
	display: none;
}
.dib{
	display: inline-block;
}

.df{
	display: flex;
	&.fdc{
		flex-direction: column;
	}
	&.jcc{
		justify-content: center;
	}
	&.jcfs{
		justify-content: flex-start;
	}
	&.jcfe{
		justify-content: flex-end;
	}
	&.jcsa{
		justify-content: space-around;
	}
	&.jcsb{
		justify-content: space-between;
	}
	&.aifs{
		align-items: flex-start;
	}
	&.aife{
		align-items: flex-end;
	}
	&.aic{
		align-items: center;
	}
	&.ais{
		align-items: stretch;
	}
}
.fwnw{
	flex-wrap: nowrap;
}
.fww{
	flex-wrap: wrap;
}
.mra{
	margin-right: auto;
}
.mla{
	margin-left: auto;
}
.mta{
	margin-top: auto;
}
.mba{
	margin-bottom: auto;
}


.border{
	&.top{
		border-top-width: 0.5px;
	}
	&.left{
		border-left-width: 0.5px;
	}
	&.bottom{
		border-bottom-width: 0.5px;
	}
	&.right{
		border-right-width: 0.5px;
	}
	&.solid{
		border-style: solid;
	}
}

.ovh{overflow: hidden;}
.ovxh{overflow-x: hidden;}
.ovyh{overflow-y: hidden;}
.ovxa{overflow-x: auto;}
.ovya{overflow-y: auto;}

.scroll-line {
		position: fixed;
		overflow: hidden;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3003;
		margin-bottom: 0px;
		width: 0%;
		background: red;
}

.md_down_hide{
	display: none;
	@media #{$md}{
		display: block;
		&.df,&.xs-df,.md-df{
			display: flex;
		}
	}
}

.md_up_hide{
	display: block;
	&.df,&.xs-df,.md-df{
		display: flex;
	}
	@media #{$md}{
		display: none;
	}
}


// hide visually, but available for screenreaders
.visuallyhidden,
%visuallyhidden {
	position: absolute;
	margin: -1px;
	padding: 0;
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
}

// extends the .visuallyhidden class to allow the element to
// be focusable when navigated to via the keyboard
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	position: static;
	margin: 0;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
}


.scrollable{
    &.vertical{
        &::-webkit-scrollbar {
            width: 12px;
            padding-left: 2px;
            padding-right: 2px;
			background-color: rgba($bleu2, 0.1);
        }
        &::-webkit-scrollbar-thumb {
        	background-color: $bleu1;
            min-height:100px;
        }
    }
    &.horizontal{
        &::-webkit-scrollbar {
            height: 6px;
            padding-top: 3px;
			padding-bottom: 3px;
        }
        &::-webkit-scrollbar-thumb{
            min-width:100px;
        }
    }
    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        background-clip: content-box;
        border-radius: 3em;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    
}

.scroll{
    &.vertical {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
    }

    &.horizontal {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
    }
}
