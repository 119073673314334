
@mixin screenpositioning($size_screen) {

  @for $i from 0 to 11 {
    .#{$size_screen}-top-#{$i}-rem { top: ($i * 1rem);}
    .#{$size_screen}-right-#{$i}-rem { right: ($i * 1rem);}
    .#{$size_screen}-bottom-#{$i}-rem { bottom: ($i * 1rem);}
    .#{$size_screen}-left-#{$i}-rem { left: ($i * 1rem);}

    .#{$size_screen}-top-#{$i}-p { top: ($i * 10%);}
    .#{$size_screen}-right-#{$i}-p { right: ($i * 10%);}
    .#{$size_screen}-bottom-#{$i}-p { bottom: ($i * 10%);}
    .#{$size_screen}-left-#{$i}-p { left: ($i * 10%);}
  }
}

@media #{$xs}{    @include screenpositioning('xs'); }
@media #{$sm}{    @include screenpositioning('sm'); }
@media #{$md}{    @include screenpositioning('md'); }
@media #{$lg}{    @include screenpositioning('lg'); }
@media #{$xl}{    @include screenpositioning('xl'); }
@media #{$xxl}{    @include screenpositioning('xxl'); }
@media #{$xxxl}{    @include screenpositioning('xxxl'); }

@mixin screenfzpourcent($size_screen) {
    @for $i from 6 to 26 {
    .#{$size_screen}-fz-#{($i * 10)}p {
      font-size: ($i * 10%);
    }
  }
}

@media #{$xs}{    @include screenfzpourcent('xs'); }
@media #{$sm}{    @include screenfzpourcent('sm'); }
@media #{$md}{    @include screenfzpourcent('md'); }
@media #{$lg}{    @include screenfzpourcent('lg'); }
@media #{$xl}{    @include screenfzpourcent('xl'); }
@media #{$xxl}{    @include screenfzpourcent('xxl'); }
@media #{$xxxl}{    @include screenfzpourcent('xxxl'); }

@mixin fzpourcent() {
  @for $i from 6 to 26 {
    .fz-#{($i * 10)}p {
      font-size: ($i * 10%);
    }
  }
}

@include fzpourcent();

@mixin screenfzem($size_screen) {
    @for $i from 1 to 26 {
    .#{$size_screen}-fz-#{(($i))}em {
      font-size: (($i / 10) * 1em);
    }
  }
}

@media #{$xs}{    @include screenfzem('xs'); }
@media #{$sm}{    @include screenfzem('sm'); }
@media #{$md}{    @include screenfzem('md'); }
@media #{$lg}{    @include screenfzem('lg'); }
@media #{$xl}{    @include screenfzem('xl'); }
@media #{$xxl}{    @include screenfzem('xxl'); }
@media #{$xxxl}{    @include screenfzem('xxxl'); }

@mixin fzem() {
  @for $i from 1 to 26 {
    .fz-#{($i)}em {
      font-size: (($i / 10) * 1em);
    }
  }
}

@include fzem();

@mixin screenfzrem($size_screen) {
    @for $i from 1 to 26 {
    .#{$size_screen}-fz-#{(($i))}rem {
      font-size: (($i / 10) * 1rem);
    }
  }
}

@media #{$xs}{    @include screenfzrem('xs'); }
@media #{$sm}{    @include screenfzrem('sm'); }
@media #{$md}{    @include screenfzrem('md'); }
@media #{$lg}{    @include screenfzrem('lg'); }
@media #{$xl}{    @include screenfzrem('xl'); }
@media #{$xxl}{    @include screenfzrem('xxl'); }
@media #{$xxxl}{    @include screenfzrem('xxxl'); }

@mixin fzrem() {
  @for $i from 1 to 26 {
    .fz-#{($i)}rem {
      font-size: (($i / 10) * 1rem);
    }
  }
}

@include fzrem();




// gradients
@mixin gradient($angle, $color1, $color2, $opacity) {
  background-image: linear-gradient(#{$angle}deg, rgba($color1, $opacity) 0%, rgba($color2, $opacity) 100%);
}

@mixin grad($color1, $color2) {
  background-image: linear-gradient(to bottom, $color1, $color2 100%);
}


@mixin collection_color($famille, $color) {
  .#{$famille}, [data-color="#{$famille}"]{ color: $color;  }
  .bg-#{$famille}, [data-bcolor="#{$famille}"]{ background-color: $color;  }
  .br-#{$famille}{ border-color: $color;  }
  .fill-#{$famille}{ fill: $color;  }
  .bg-grad-light-#{$famille}{ background-image: linear-gradient(to bottom, $color, lighten($color, 6%) 100%);}
  .bg-grad-dark-#{$famille}{ background-image: linear-gradient(to bottom, $color, darken($color, 6%) 100%);}

  @for $i from 1 to 9 {
    .#{$famille}-op#{$i}{ color: rgba( $color, ($i / 10)); }
    .bg-#{$famille}-op#{$i}{ background-color: rgba( $color, ($i / 10)); }
  }
}

@include collection_color(noir, $noir);
@include collection_color(blanc, $blanc);
@include collection_color(gris_sombre, $gris_sombre);
@include collection_color(bleu1, $bleu1);
@include collection_color(bleu2, $bleu2);
@include collection_color(rose1, $rose1);
@include collection_color(rose2, $rose2);
@include collection_color(jaune1, $jaune1);
@include collection_color(jaune2, $jaune2);
@include collection_color(vert1, $vert1);
@include collection_color(vert2, $vert2);

@include collection_color(class_nat_artistique, $rose1);
@include collection_color(class_nat_course, $vert1);
@include collection_color(class_loisir_sante, $jaune1);
@include collection_color(class_sujet_divers, $bleu1);
@include collection_color(class_evenement, $bleu2);


@mixin famille_color($famille, $color) {


  .#{$famille}-color {
    color: $color;
  }

  .#{$famille}-bg-color {
    background-color: $color;
  }

  .#{$famille}, [data-source="#{$famille}"] {
    .color{ color: $color;  }
    .bg-color{ background-color: $color;  }
    .color{ color: $color;  }
    .active.active-color{ color: $color;  }
    .active.active-color-children{ .children-active {color: $color;  } }
    .active.active-bg-color{ background-color: $color;  }
    .active.active-br-color{ border-color: $color;  }
    [type="checkbox"]:not(:checked)+label:after, [type="checkbox"]:checked+label:after { color: $color;}
    .fill-color{ fill: $color;  }
    .scroll-color::-webkit-scrollbar-thumb{ background-color: $color;  }
    .bg-grad-light-color{ background-image: linear-gradient(to bottom, $color, lighten($color, 6%) 100%);}
    .bg-grad-dark-color{ background-image: linear-gradient(to bottom, $color, darken($color, 6%) 100%);}

    .active{
      .active-ts-color{
        text-shadow: $color 0 0 1.3rem,
        $color 0 0 1rem;
      }
    }

    .ts-color {
      text-shadow: $color 0 0 1.3rem,
        $color 0 0 1rem;
    }

    @for $i from 5 to 9 {
      .color-op#{$i}{ color: rgba( $color, ($i / 10)); }
      .bg-color-op#{$i}{ background-color: rgba( $color, ($i / 10)); }
    }
  }
}

//@include famille_color(site, $bleu);
//@include famille_color(videos, $vert);
//@include famille_color(playlists, $rouge);
//@include famille_color(users, $or);
//@include famille_color(wish, $violet);











@mixin opacity() {
  @for $i from 0 to 11 {
    .opacity-#{($i * 10)} {
      opacity: ($i / 10);
    }
  }
}

@include opacity();


@mixin positioning() {
  @for $i from 0 to 11 {
    .top-#{$i}-rem { top: ($i * 1rem);}
    .right-#{$i}-rem { right: ($i * 1rem);}
    .bottom-#{$i}-rem { bottom: ($i * 1rem);}
    .left-#{$i}-rem { left: ($i * 1rem);}

    .top-#{$i}-p { top: ($i * 10%);}
    .right-#{$i}-p { right: ($i * 10%);}
    .bottom-#{$i}-p { bottom: ($i * 10%);}
    .left-#{$i}-p { left: ($i * 10%);}
  }
}

@include positioning();

@mixin screenSizeHideDown($size_screen, $size_screen2) {
  .#{$size_screen}_down_hide, .#{$size_screen}_down_hide.df {
    display: none;

    @media #{$size_screen2} {
      display: block;

      &.#{$size_screen}-df,&.df{
        display: flex;
      }

    }
  }
}

@include screenSizeHideDown('xs', $xs);
@include screenSizeHideDown('sm', $sm);
@include screenSizeHideDown('md', $md);
@include screenSizeHideDown('lg', $lg);
@include screenSizeHideDown('xl', $xl);
@include screenSizeHideDown('xxl', $xxl);


@mixin screenSizeHideUp($size_screen, $size_screen2) {
  .#{$size_screen}_up_hide, .#{$size_screen}_up_hide.df {
    display: block;

    &.#{$size_screen}-df,&.df {
      display: flex;
    }

    @media #{$size_screen2} {
      display: none;

    }
  }
}

@include screenSizeHideUp('xs', $xs_max);
@include screenSizeHideUp('sm', $sm_max);
@include screenSizeHideUp('md', $md_max);
@include screenSizeHideUp('lg', $lg_max);
@include screenSizeHideUp('xl', $xl_max);
@include screenSizeHideUp('xxl', $xxl_max);
