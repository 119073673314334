.admin_block{
    text-align: center;
    a{
        &.supprimer{
            color: rgb(255, 82, 82);
        }
        &.editer{
            color: rgb(42, 187, 107);
        }

        & + a{
            margin-left: $gutter /4;
        }
        &:before{
            margin-right: $gutter /8;
        }

        display: inline-block;
        border: solid 2px;
        border-radius: 0.25rem;
        padding: $gutter /6 $gutter /4;
    }
}

.blog.content{
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 $gutter $gutter $gutter;
    margin-bottom: $gutter;
    .content_wrapper{
        .header_block{
            text-align: center;
            margin-bottom: $gutter;
            .titre_block{
                margin-bottom: $gutter;
            }
            .tags_block{
                margin-bottom: $gutter;
            }
            .description_block{
                margin-bottom: $gutter/2;
                p{
                    @extend .sb;
                }
            }
            .image_couverture {
                position: relative;
                .img_content{
                    position: relative;
                    width: 100%;
                    height: auto;
                }                
            }
            .article_infos{
                text-align: left;
                .tags{
                    display: block;
                    color: $bleu1;
                    margin-bottom: 5px;
                    &.agenda.tags{
                        .hash_text{
                            &:before {
                                line-height: 1;
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 0.6rem;
                            }
                        }
                    }

                    @extend .sb;
                    letter-spacing: 0.5px;
                    border-top-left-radius: 0rem;
                    border-top-right-radius: 0rem;
                }
                
            }
        }
        .content_block{
            .groupx_block{

                & + .groupx_block.titre,
                & + .groupx_block.sous_titre
                {
                    margin-top: $gutter*2;
                }
                & + .groupx_block{
                    margin-top: $gutter;
                }
            }
        }
    }
}

.groupx_block {
    margin-bottom: $gutter;
    &.titre{
        //@extend .font2,.bleu1;
        //letter-spacing: 0.05rem;
        //font-size: 300%;
    }
    &.sous_titre {
        //@extend .sb,.bleu1;
        //font-size: 150%;
    }
    &.paragraphe {
        a{
            @extend .bleu1, .sb;
            text-decoration: underline;
        }
        li{
            line-height: 1.7;
            list-style: inside;
            list-style-type: square;
            margin-bottom: 1rem;
        }
    }
    &.video {
        text-align: center;
        .video-container{
            position: relative;
            overflow: hidden;                    
            padding-top: 56.25%;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &.photo {
        text-align: center;
        img{
            margin-bottom: 0.5rem;
            margin-right: auto;
            margin-left: auto;
        }
        span{
            display: block;
            @extend .sb;
            line-height: 1;
            i{
                margin-right: 0.5rem;
                color: $bleu1;
                display: inline-block;
                vertical-align: top;
            }
        }
        [data-width="original"]{  img{    }}
        [data-width="25"]{  img{    width: 25%}}
        [data-width="50"]{  img{    width: 50%}}
        [data-width="75"]{  img{    width: 75%}}
        [data-width="100"]{  img{    width: 100%}}
    }
    &.fichier {
        text-align: center;
        .box{
            display: inline-block;
            padding: 2rem;
            background: aliceblue;        
            span {
                i {
                    margin-right: 0.1rem;
                    color: $bleu1;
                    display: inline-block;
                    vertical-align: text-bottom;
                }
            }
            a{
                @extend .bleu1, .sb;
                text-decoration: underline;
            }
        }
    }
    &.lien {
        text-align: center;
        .box{
            display: inline-block;
            padding: 2rem;
            background: rgb(232, 222, 248);        
            span {
                i {
                    margin-right: 0.1rem;
                    color: $bleu1;
                    display: inline-block;
                    vertical-align: text-bottom;
                }
            }
            a{
                @extend .bleu1, .sb;
                text-decoration: underline;
            }
        }
    }
}

.titre_galerie{
    text-align: center;
}
.galerie_block {
    margin-bottom: $gutter;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .galerie_photo_item{
        text-align: center;
        margin-bottom: $gutter/2;
        & + .galerie_photo_item{
            margin-left: $gutter;
        }
        a{
            display: block;
            img{}
        }
        span{
            display: block;
            i {
                margin-right: 0.5rem;
                color: $bleu1;
                display: inline-block;
                vertical-align: text-bottom;
            }
        }
    }

}