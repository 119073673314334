#navTop{
    width: 100%;
    background-color: $bleu1;
    color: $blanc;
    margin-bottom: $spacetop;
    .navTop_inner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2*$gutter;
        padding: $gutter/14 $gutter/14;
        @media #{$menu_switch}{
            padding: $gutter/2 $gutter/2;
        }
    }
}

.joomMenu{
    display: none;
    @media #{$md}{
        display: flex;
    }
    > a {
        font-size: 80%;
        & + a{
            margin-left: $gutter/2;
        }
    }
}

svg * { 
    transform-box: fill-box;
    //transform-origin: center center;
}

#logo{
    height: (3*$gutter)+(($gutter/8)*2);
    width: (3*$gutter)+(($gutter/8)*2);
    position: relative;
    transform: translateY($menu_height/2);
    margin-left: $gutter;
    margin-right: $gutter;
    .logo_wrapper{
        display: block;
        display: flex;
        align-items: center;  
        justify-content: center;
        svg{
            display: block;
            height: 100%;
            width: 100%;
            //transform-origin: center center;
        }
        #logo_svg{
            #logo_g{
                > *:not(#cercle) {
                    //transform-origin: center;
                    visibility: hidden;
                    opacity: 0;
                    transform: translateY(100%);
                }
                #cercle{
                    //transform-origin: center;
                    visibility: hidden;
                    opacity: 0;
                    transform: scale(0);
                }
                #texte{transform: translateY(-100%);}
                #nageur{}
                #nageuse{}
            }
        }
    }
}
#joomMenu1, #social{
    margin-left: auto;
}
#social{
    margin-right: 0;
    justify-self: flex-end;
    font-size: 100%;
    display: flex;
    align-items: center;   
    justify-content: center;
    > a{
        & + a {
            margin-left: $gutter/2;
        }
        display: block;
        width: $gutter;
        height: $gutter;
        display: flex;
        align-items: center;    
        justify-content: center;
        background-color: $blanc;
        border-radius: 50%;
        span{
            color: $bleu1;
        }
    }
}


#menu_ico {
    #menu_icon{
        padding: $gutter;
        cursor: pointer;
        .bar{
            transform: translateY(0rem) rotate(0deg);
            display: block;
            height: 0.15rem;
            width: $gutter;
            background-color: $blanc;
            &:nth-child(2){
                width: $gutter - 0.5rem;
                margin-left: 0.5rem;
            }
            & + .bar {
                margin-top: 0.4rem;
            }
        }
    }
}