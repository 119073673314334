#activitiesCards {
    margin-bottom: $gutter*2;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    @media #{$menu_switch}{
        width: 80%;
    }
    .activitiesCards_inner {
        width: 100%;
        @media #{$menu_switch}{
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
        }
    }
}

.activityCard {
    margin-bottom: $gutter*2;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
        @media #{$menu_switch}{
            & + .activityCard{
                margin-left: 4%;
            }
            width: 30%;
            //max-width: 370px;
        }
    .activityCard_inner{
        background-color: $blanc;
        box-shadow: $box-shadow;
        padding: $gutter*2 $gutter $gutter $gutter;
        text-align: center;

        .activityLine{
            margin-bottom: $gutter;
            margin-top: $gutter/2;
            div{
                margin-left: auto;
                margin-right: auto;                
                width: 50px;
                height: 8px;                
            }
        }
        .activityTitle{
            margin-bottom: $gutter;
            @extend .sb;
            font-size: 150%;
        }
        .activityDescription {
            text-align: left;
            margin-bottom: $gutter;
        }
        .activityMyButton{
            @extend .bouton;
            margin-bottom: $gutter;
        }
    }    
}


.activityPicture {
    margin-bottom: - $gutter;
    text-align: center;
    img{
        display: inline-block;
        //transform: translateY(-100%);
    }
}