#popover_form{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: hidden;
    > .inner{
        position: relative;
        width: 100%;
        height: 100%;
        background-color: $blanc;
        transform-style: preserve-3d;
        perspective: 600px;
        .form_bg{
            position: absolute;
            top: 0;
            height: 100%;
            width: 50%;
            right: 0;
            background-color: $bleu1;
            z-index: 0;
            transform: translateX(100%);
        }

        form {
            z-index: 10;
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 70%;
            transform: translate(-50%, -50%) rotateY(-90deg);
            transform-origin: left center;
            background-color: $blanc;
            padding: $gutter*2 0;
            @media #{$menu_switch}{
                padding: $gutter*2 $gutter*2 $gutter;
            }
            box-shadow: 0rem 1.3rem 1.5rem -1.5rem rgba($bleu1, 0.7);

            .fermer_form {
                position: absolute;
                top: $gutter/2;
                right: $gutter/2;
                font-size: 1.6em;
                z-index: 1000;
                font-family: arial;
                color: $bleu2;
                &:hover{
                    color: $bleu1;
                }
            }

            
            .form_top{
                margin-bottom: $gutter;
                .form_icon{
                    text-align: center;
                    display: block;
                    margin-bottom: $gutter/4;
                    font-size: 300%;
                    color: $bleu1;
                }
                .title{
                    @extend .font2;
                    letter-spacing: 0.25em;
                    text-transform: uppercase;
                    color: $bleu1;
                }
                .form_top_texte{
                    font-size: 200%;
                }
            }

            .form_fields {
                margin-bottom: $gutter*2;
                .form_field{
                    & + .form_field{
                        margin-top: $gutter;
                    }
                    > * {
                        display: block;
                    }
                    input{}
                    label{
                        margin-bottom: $gutter/8;
                    }
    
                    &[data-form-field="honey"]{
                        display: none;
                    }
                }
            }

            .form_button {
                margin-bottom: $gutter;
            }

            .form_result{}
        }

    }
}

input[type="text"],
textarea{
    width: 80%;
    padding: 0.5rem 1rem;
    border-right-width: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-bottom-width: 0.6px;
    border-bottom-color: $bleu1;
}
textarea {
    min-height: 5rem;
}

[data-form="contact"]{
    cursor: pointer;
}

body {
    overflow-y: auto;

    &[data-mail-opened="false"] {
        overflow-y: auto;

        .form_wrapper {
            opacity: 0;
            visibility: hidden;
            //    transform: translateX(200%);
            overflow-y: hidden;
            .form{
                opacity: 0;
                visibility: hidden;
                //transform: rotateY('90deg');
            }
        }
    }

    &[data-mail-opened="true"] {
        overflow-y: hidden;

        .form_wrapper {
        //    transform: translateX(0%);
        //    opacity: 1;
              overflow-y: auto;
              overflow-x: hidden;
        }
    }
}