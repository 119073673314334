#system-message-container{
    margin-bottom: $gutter*2;
    #system-message{
        display: flex;
        justify-content: center;
        > div {
            max-width: 400px;
            padding: $gutter/2 $gutter;
            color: $blanc;
            background-color: rgba($bleu1, 0.7);
            &.alert-message{
                color: $blanc;
                background-color: rgba(rgb(255, 0, 0), 0.7);
            }
            .alert-heading{
                @extend .font2;
                font-size: 150%;
                letter-spacing: 4px;
                text-transform: uppercase;
            }
            a.close{
                @extend .sb;
                font-size: 200%;
                cursor: pointer;
                border: 2px solid #fff;
                padding: 0 0.5rem;
            }
            a.close+h4{
                margin-bottom: 10px;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
}