#carousel{    
    width: 100%;
    position: relative;
    margin-bottom: $gutter*2;
    @media #{$menu_switch}{
        margin-bottom: 0;
    }
    .carousel_wrapper{
        @media #{$menu_switch}{
            min-height: 75vh;
        }
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap-reverse;
        .carousel_split{
            width: 100%;
            @media #{$menu_switch}{
                width: 50%;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            //padding-top: $gutter;
            //padding-bottom: $gutter;
            &#carousel_right {
                padding-left: $gutter*1;
                padding-right: $gutter*1;
                text-align: center;
                #carouselTitle{
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
            &#carousel_left {
                padding-left: $gutter*1;
                padding-right: $gutter*1;
            }
            @media #{$menu_switch} {
                //padding-top: $gutter*3;
                padding-bottom: $gutter*3;
                &#carousel_left {
                    padding-left: $gutter*4;
                    .carousel_split_inner{
                        min-width: 80%;
                    }
                }
                &#carousel_right {
                    text-align: left;
                    padding-right: $gutter*4;
                    justify-content: flex-start;
                    .carousel_split_inner {
                        max-width: 600px;
                    }
                    #carouselLink{
                    }
                    #carouselTitle{
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
        .carousel_nav{            
            top: -0.5rem;
            @media #{$menu_switch} {
                top: 40%;
            }
            position: absolute;
            width: $gutter*2;
            height: $gutter*2;
            //background-color: $bleu1;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                color: $blanc;
                font-size: 200%;
            }
            &#prev{
                left: 0;
                //margin-right: auto;
            }
            &#next{
                right: 0;
                //margin-left: auto;
            }
        }
    }
}


.block_image_carousel{
    position: relative;
    width: 100%;
    height: 100%;
    .pix_wrapper{
        &.waves_wrapper,&.activity_wrapper{
            position: absolute; 
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;       
        }
        display: flex;
        align-items: center;
        justify-content: center;
        > img{
            max-width: 100%;
            height: auto;
        }
        &.waves_wrapper{
            animation: waves 2s ease-in-out infinite;
        }
    }
}


@keyframes waves{
    0%, 100%{
        transform:translate(0px, 0px);
    }
    50%{
        transform:translate(2px, 2px);
    }
}