#block_form{
    max-width: 800px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $gutter;
    margin-bottom: $gutter;
    >* {
        margin-bottom: $gutter;
    }
    label{
        @extend .sb;
        display: inline-block;
        margin-bottom: 0.5rem;
    }
}
.form_top{
    .form_icon{
        text-align: center;
        display: block;
        margin-bottom: $gutter/4;
        font-size: 300%;
        color: $bleu1;
    }
    .title{
        @extend .font2;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        color: $bleu1;
    }
    .form_top_texte{
        font-size: 200%;
    }
}

.form_block {
    .infos{
        @extend .sb;
        color: $bleu1;
        font-size: 200%;
    }
    .field_blocks{
        padding: $gutter / 2;
        padding-left: $gutter;
        border-left: 4px solid $bleu1;
        .field_block {
            width: 100%;
            &+.field_block {
                margin-top: $gutter;
            }           
        }

        .field_label {
            @extend .sb;
            display: block;
            margin-bottom: 6px;

        }
        .field_form{
            .checkboxes{
                label{
                    margin-left: 0.2rem;
                    margin-right: 0.5rem;
                }

            }
            input:not([type='checkbox']),
            textarea {
                display: block;
                @extend .font1;
                padding: 8px;
                border-color: $bleu1;
                width: 100%;

                outline: 0;
                border-width: 0;
                border-color: $bleu1;
                border-style: solid;
                border-left-width: 3px;
                border-bottom-width: 3px;
                transition: all 0.3s;

                &:focus {
                    border-left-width: 2px;
                    border-bottom-width: 2px;
                }
            
                &[type="file"] {
                                        
                }
                &[type='checkbox']{

                }
                
            }
            select {
                @extend .font1;
                padding: 8px;
                min-width: 300px;
            }
        }


    }
}



.cck_group_x {
    width: 100%;
    .cck_form_group_x {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        &+.cck_form_group_x {
            margin-top: $gutter;
        }

        .horizontal_gx {
            .cck_form_group_x {
                .cck_cgx_button {}

                .cck_cgx_form {
                    width: 100%;
                    float: none;
                    display: block;
                    background: aliceblue;
                    padding: 2rem;
                    .cck_select_simple{
                        margin-bottom: $gutter/2;
                    }
                }
            }
        }
    }
}

