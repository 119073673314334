body > .login{
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}


.gestion{
    text-align: center;
    padding-bottom: $gutter*2;
    &.search{
        margin-bottom: $gutter*2;
    }
    .titre_gestion{
        .box{
            a{
                display: inline-block;
                background-color: $bleu1;
                padding: 1rem 2rem;
                color: $blanc;
                box-shadow: 0 0.25rem 0.5rem rgba($noir, 0.5);
                
            }
            #blog_categorie, #blog_ordre{
                box-shadow: 0 0.25rem 0.5rem rgba($noir, 0.5);
                padding: 1rem 1rem;

            }
        }
        &.titre_gestion{
            margin-top: $gutter/2;
        }
    }
}


////ITEMS
.gestion_site_item{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: $gutter/2;
    .box{
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .blog_list_cover{
            //overflow: hidden;
            width: $gutter;
            height: $gutter;
            >.inner{
                background-size: cover;
                background-position: 50% 50%;
                width: 100%;
                height: 100%;
            }
        }
        .blog_list_infos{}
        .blog_list_links{}
        a{
            background-color:aliceblue;
            margin-left: $gutter/2;
            padding: $gutter/4;
            & + a{
            }
        }
    }
}