#hero_top{
    .title{
        @extend .font2;
        font-size: 10vw;
        text-align: center;
        line-height: 1;
        @media #{$header_switch}{
            font-size: 300%;
            text-align: left;
        }
        text-transform: uppercase;
        letter-spacing: 4px;
    }
}