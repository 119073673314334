@viewport {
  width: device-width;
  zoom: 1.0; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
ol, ul, li,
fieldset, form, label, legend,
caption,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer,
header, hgroup, main, menu, nav, section, summary {
  display: block; }

*,
*:before,
*:after {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

html {
  background: #fff;
  text-size-adjust: 100%; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

body {
  line-height: 1.5; }

ol, ul {
  list-style: none; }

a {
  background-color: transparent; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

img,
video {
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

iframe,
object,
embed {
  max-width: 100%; }

img {
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

mybutton,
html input[type="mybutton"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer; }

input[type="search"] {
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-mybutton,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

mybutton::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

[data-color=color-club] {
  color: #2ebfff; }

.bg-color-club {
  background-color: #2ebfff; }

@media (min-width: 0px) {
  .xs-top-0-rem {
    top: 0rem; }
  .xs-right-0-rem {
    right: 0rem; }
  .xs-bottom-0-rem {
    bottom: 0rem; }
  .xs-left-0-rem {
    left: 0rem; }
  .xs-top-0-p {
    top: 0%; }
  .xs-right-0-p {
    right: 0%; }
  .xs-bottom-0-p {
    bottom: 0%; }
  .xs-left-0-p {
    left: 0%; }
  .xs-top-1-rem {
    top: 1rem; }
  .xs-right-1-rem {
    right: 1rem; }
  .xs-bottom-1-rem {
    bottom: 1rem; }
  .xs-left-1-rem {
    left: 1rem; }
  .xs-top-1-p {
    top: 10%; }
  .xs-right-1-p {
    right: 10%; }
  .xs-bottom-1-p {
    bottom: 10%; }
  .xs-left-1-p {
    left: 10%; }
  .xs-top-2-rem {
    top: 2rem; }
  .xs-right-2-rem {
    right: 2rem; }
  .xs-bottom-2-rem {
    bottom: 2rem; }
  .xs-left-2-rem {
    left: 2rem; }
  .xs-top-2-p {
    top: 20%; }
  .xs-right-2-p {
    right: 20%; }
  .xs-bottom-2-p {
    bottom: 20%; }
  .xs-left-2-p {
    left: 20%; }
  .xs-top-3-rem {
    top: 3rem; }
  .xs-right-3-rem {
    right: 3rem; }
  .xs-bottom-3-rem {
    bottom: 3rem; }
  .xs-left-3-rem {
    left: 3rem; }
  .xs-top-3-p {
    top: 30%; }
  .xs-right-3-p {
    right: 30%; }
  .xs-bottom-3-p {
    bottom: 30%; }
  .xs-left-3-p {
    left: 30%; }
  .xs-top-4-rem {
    top: 4rem; }
  .xs-right-4-rem {
    right: 4rem; }
  .xs-bottom-4-rem {
    bottom: 4rem; }
  .xs-left-4-rem {
    left: 4rem; }
  .xs-top-4-p {
    top: 40%; }
  .xs-right-4-p {
    right: 40%; }
  .xs-bottom-4-p {
    bottom: 40%; }
  .xs-left-4-p {
    left: 40%; }
  .xs-top-5-rem {
    top: 5rem; }
  .xs-right-5-rem {
    right: 5rem; }
  .xs-bottom-5-rem {
    bottom: 5rem; }
  .xs-left-5-rem {
    left: 5rem; }
  .xs-top-5-p {
    top: 50%; }
  .xs-right-5-p {
    right: 50%; }
  .xs-bottom-5-p {
    bottom: 50%; }
  .xs-left-5-p {
    left: 50%; }
  .xs-top-6-rem {
    top: 6rem; }
  .xs-right-6-rem {
    right: 6rem; }
  .xs-bottom-6-rem {
    bottom: 6rem; }
  .xs-left-6-rem {
    left: 6rem; }
  .xs-top-6-p {
    top: 60%; }
  .xs-right-6-p {
    right: 60%; }
  .xs-bottom-6-p {
    bottom: 60%; }
  .xs-left-6-p {
    left: 60%; }
  .xs-top-7-rem {
    top: 7rem; }
  .xs-right-7-rem {
    right: 7rem; }
  .xs-bottom-7-rem {
    bottom: 7rem; }
  .xs-left-7-rem {
    left: 7rem; }
  .xs-top-7-p {
    top: 70%; }
  .xs-right-7-p {
    right: 70%; }
  .xs-bottom-7-p {
    bottom: 70%; }
  .xs-left-7-p {
    left: 70%; }
  .xs-top-8-rem {
    top: 8rem; }
  .xs-right-8-rem {
    right: 8rem; }
  .xs-bottom-8-rem {
    bottom: 8rem; }
  .xs-left-8-rem {
    left: 8rem; }
  .xs-top-8-p {
    top: 80%; }
  .xs-right-8-p {
    right: 80%; }
  .xs-bottom-8-p {
    bottom: 80%; }
  .xs-left-8-p {
    left: 80%; }
  .xs-top-9-rem {
    top: 9rem; }
  .xs-right-9-rem {
    right: 9rem; }
  .xs-bottom-9-rem {
    bottom: 9rem; }
  .xs-left-9-rem {
    left: 9rem; }
  .xs-top-9-p {
    top: 90%; }
  .xs-right-9-p {
    right: 90%; }
  .xs-bottom-9-p {
    bottom: 90%; }
  .xs-left-9-p {
    left: 90%; }
  .xs-top-10-rem {
    top: 10rem; }
  .xs-right-10-rem {
    right: 10rem; }
  .xs-bottom-10-rem {
    bottom: 10rem; }
  .xs-left-10-rem {
    left: 10rem; }
  .xs-top-10-p {
    top: 100%; }
  .xs-right-10-p {
    right: 100%; }
  .xs-bottom-10-p {
    bottom: 100%; }
  .xs-left-10-p {
    left: 100%; } }

@media (min-width: 544px) {
  .sm-top-0-rem {
    top: 0rem; }
  .sm-right-0-rem {
    right: 0rem; }
  .sm-bottom-0-rem {
    bottom: 0rem; }
  .sm-left-0-rem {
    left: 0rem; }
  .sm-top-0-p {
    top: 0%; }
  .sm-right-0-p {
    right: 0%; }
  .sm-bottom-0-p {
    bottom: 0%; }
  .sm-left-0-p {
    left: 0%; }
  .sm-top-1-rem {
    top: 1rem; }
  .sm-right-1-rem {
    right: 1rem; }
  .sm-bottom-1-rem {
    bottom: 1rem; }
  .sm-left-1-rem {
    left: 1rem; }
  .sm-top-1-p {
    top: 10%; }
  .sm-right-1-p {
    right: 10%; }
  .sm-bottom-1-p {
    bottom: 10%; }
  .sm-left-1-p {
    left: 10%; }
  .sm-top-2-rem {
    top: 2rem; }
  .sm-right-2-rem {
    right: 2rem; }
  .sm-bottom-2-rem {
    bottom: 2rem; }
  .sm-left-2-rem {
    left: 2rem; }
  .sm-top-2-p {
    top: 20%; }
  .sm-right-2-p {
    right: 20%; }
  .sm-bottom-2-p {
    bottom: 20%; }
  .sm-left-2-p {
    left: 20%; }
  .sm-top-3-rem {
    top: 3rem; }
  .sm-right-3-rem {
    right: 3rem; }
  .sm-bottom-3-rem {
    bottom: 3rem; }
  .sm-left-3-rem {
    left: 3rem; }
  .sm-top-3-p {
    top: 30%; }
  .sm-right-3-p {
    right: 30%; }
  .sm-bottom-3-p {
    bottom: 30%; }
  .sm-left-3-p {
    left: 30%; }
  .sm-top-4-rem {
    top: 4rem; }
  .sm-right-4-rem {
    right: 4rem; }
  .sm-bottom-4-rem {
    bottom: 4rem; }
  .sm-left-4-rem {
    left: 4rem; }
  .sm-top-4-p {
    top: 40%; }
  .sm-right-4-p {
    right: 40%; }
  .sm-bottom-4-p {
    bottom: 40%; }
  .sm-left-4-p {
    left: 40%; }
  .sm-top-5-rem {
    top: 5rem; }
  .sm-right-5-rem {
    right: 5rem; }
  .sm-bottom-5-rem {
    bottom: 5rem; }
  .sm-left-5-rem {
    left: 5rem; }
  .sm-top-5-p {
    top: 50%; }
  .sm-right-5-p {
    right: 50%; }
  .sm-bottom-5-p {
    bottom: 50%; }
  .sm-left-5-p {
    left: 50%; }
  .sm-top-6-rem {
    top: 6rem; }
  .sm-right-6-rem {
    right: 6rem; }
  .sm-bottom-6-rem {
    bottom: 6rem; }
  .sm-left-6-rem {
    left: 6rem; }
  .sm-top-6-p {
    top: 60%; }
  .sm-right-6-p {
    right: 60%; }
  .sm-bottom-6-p {
    bottom: 60%; }
  .sm-left-6-p {
    left: 60%; }
  .sm-top-7-rem {
    top: 7rem; }
  .sm-right-7-rem {
    right: 7rem; }
  .sm-bottom-7-rem {
    bottom: 7rem; }
  .sm-left-7-rem {
    left: 7rem; }
  .sm-top-7-p {
    top: 70%; }
  .sm-right-7-p {
    right: 70%; }
  .sm-bottom-7-p {
    bottom: 70%; }
  .sm-left-7-p {
    left: 70%; }
  .sm-top-8-rem {
    top: 8rem; }
  .sm-right-8-rem {
    right: 8rem; }
  .sm-bottom-8-rem {
    bottom: 8rem; }
  .sm-left-8-rem {
    left: 8rem; }
  .sm-top-8-p {
    top: 80%; }
  .sm-right-8-p {
    right: 80%; }
  .sm-bottom-8-p {
    bottom: 80%; }
  .sm-left-8-p {
    left: 80%; }
  .sm-top-9-rem {
    top: 9rem; }
  .sm-right-9-rem {
    right: 9rem; }
  .sm-bottom-9-rem {
    bottom: 9rem; }
  .sm-left-9-rem {
    left: 9rem; }
  .sm-top-9-p {
    top: 90%; }
  .sm-right-9-p {
    right: 90%; }
  .sm-bottom-9-p {
    bottom: 90%; }
  .sm-left-9-p {
    left: 90%; }
  .sm-top-10-rem {
    top: 10rem; }
  .sm-right-10-rem {
    right: 10rem; }
  .sm-bottom-10-rem {
    bottom: 10rem; }
  .sm-left-10-rem {
    left: 10rem; }
  .sm-top-10-p {
    top: 100%; }
  .sm-right-10-p {
    right: 100%; }
  .sm-bottom-10-p {
    bottom: 100%; }
  .sm-left-10-p {
    left: 100%; } }

@media (min-width: 768px) {
  .md-top-0-rem {
    top: 0rem; }
  .md-right-0-rem {
    right: 0rem; }
  .md-bottom-0-rem {
    bottom: 0rem; }
  .md-left-0-rem {
    left: 0rem; }
  .md-top-0-p {
    top: 0%; }
  .md-right-0-p {
    right: 0%; }
  .md-bottom-0-p {
    bottom: 0%; }
  .md-left-0-p {
    left: 0%; }
  .md-top-1-rem {
    top: 1rem; }
  .md-right-1-rem {
    right: 1rem; }
  .md-bottom-1-rem {
    bottom: 1rem; }
  .md-left-1-rem {
    left: 1rem; }
  .md-top-1-p {
    top: 10%; }
  .md-right-1-p {
    right: 10%; }
  .md-bottom-1-p {
    bottom: 10%; }
  .md-left-1-p {
    left: 10%; }
  .md-top-2-rem {
    top: 2rem; }
  .md-right-2-rem {
    right: 2rem; }
  .md-bottom-2-rem {
    bottom: 2rem; }
  .md-left-2-rem {
    left: 2rem; }
  .md-top-2-p {
    top: 20%; }
  .md-right-2-p {
    right: 20%; }
  .md-bottom-2-p {
    bottom: 20%; }
  .md-left-2-p {
    left: 20%; }
  .md-top-3-rem {
    top: 3rem; }
  .md-right-3-rem {
    right: 3rem; }
  .md-bottom-3-rem {
    bottom: 3rem; }
  .md-left-3-rem {
    left: 3rem; }
  .md-top-3-p {
    top: 30%; }
  .md-right-3-p {
    right: 30%; }
  .md-bottom-3-p {
    bottom: 30%; }
  .md-left-3-p {
    left: 30%; }
  .md-top-4-rem {
    top: 4rem; }
  .md-right-4-rem {
    right: 4rem; }
  .md-bottom-4-rem {
    bottom: 4rem; }
  .md-left-4-rem {
    left: 4rem; }
  .md-top-4-p {
    top: 40%; }
  .md-right-4-p {
    right: 40%; }
  .md-bottom-4-p {
    bottom: 40%; }
  .md-left-4-p {
    left: 40%; }
  .md-top-5-rem {
    top: 5rem; }
  .md-right-5-rem {
    right: 5rem; }
  .md-bottom-5-rem {
    bottom: 5rem; }
  .md-left-5-rem {
    left: 5rem; }
  .md-top-5-p {
    top: 50%; }
  .md-right-5-p {
    right: 50%; }
  .md-bottom-5-p {
    bottom: 50%; }
  .md-left-5-p {
    left: 50%; }
  .md-top-6-rem {
    top: 6rem; }
  .md-right-6-rem {
    right: 6rem; }
  .md-bottom-6-rem {
    bottom: 6rem; }
  .md-left-6-rem {
    left: 6rem; }
  .md-top-6-p {
    top: 60%; }
  .md-right-6-p {
    right: 60%; }
  .md-bottom-6-p {
    bottom: 60%; }
  .md-left-6-p {
    left: 60%; }
  .md-top-7-rem {
    top: 7rem; }
  .md-right-7-rem {
    right: 7rem; }
  .md-bottom-7-rem {
    bottom: 7rem; }
  .md-left-7-rem {
    left: 7rem; }
  .md-top-7-p {
    top: 70%; }
  .md-right-7-p {
    right: 70%; }
  .md-bottom-7-p {
    bottom: 70%; }
  .md-left-7-p {
    left: 70%; }
  .md-top-8-rem {
    top: 8rem; }
  .md-right-8-rem {
    right: 8rem; }
  .md-bottom-8-rem {
    bottom: 8rem; }
  .md-left-8-rem {
    left: 8rem; }
  .md-top-8-p {
    top: 80%; }
  .md-right-8-p {
    right: 80%; }
  .md-bottom-8-p {
    bottom: 80%; }
  .md-left-8-p {
    left: 80%; }
  .md-top-9-rem {
    top: 9rem; }
  .md-right-9-rem {
    right: 9rem; }
  .md-bottom-9-rem {
    bottom: 9rem; }
  .md-left-9-rem {
    left: 9rem; }
  .md-top-9-p {
    top: 90%; }
  .md-right-9-p {
    right: 90%; }
  .md-bottom-9-p {
    bottom: 90%; }
  .md-left-9-p {
    left: 90%; }
  .md-top-10-rem {
    top: 10rem; }
  .md-right-10-rem {
    right: 10rem; }
  .md-bottom-10-rem {
    bottom: 10rem; }
  .md-left-10-rem {
    left: 10rem; }
  .md-top-10-p {
    top: 100%; }
  .md-right-10-p {
    right: 100%; }
  .md-bottom-10-p {
    bottom: 100%; }
  .md-left-10-p {
    left: 100%; } }

@media (min-width: 992px) {
  .lg-top-0-rem {
    top: 0rem; }
  .lg-right-0-rem {
    right: 0rem; }
  .lg-bottom-0-rem {
    bottom: 0rem; }
  .lg-left-0-rem {
    left: 0rem; }
  .lg-top-0-p {
    top: 0%; }
  .lg-right-0-p {
    right: 0%; }
  .lg-bottom-0-p {
    bottom: 0%; }
  .lg-left-0-p {
    left: 0%; }
  .lg-top-1-rem {
    top: 1rem; }
  .lg-right-1-rem {
    right: 1rem; }
  .lg-bottom-1-rem {
    bottom: 1rem; }
  .lg-left-1-rem {
    left: 1rem; }
  .lg-top-1-p {
    top: 10%; }
  .lg-right-1-p {
    right: 10%; }
  .lg-bottom-1-p {
    bottom: 10%; }
  .lg-left-1-p {
    left: 10%; }
  .lg-top-2-rem {
    top: 2rem; }
  .lg-right-2-rem {
    right: 2rem; }
  .lg-bottom-2-rem {
    bottom: 2rem; }
  .lg-left-2-rem {
    left: 2rem; }
  .lg-top-2-p {
    top: 20%; }
  .lg-right-2-p {
    right: 20%; }
  .lg-bottom-2-p {
    bottom: 20%; }
  .lg-left-2-p {
    left: 20%; }
  .lg-top-3-rem {
    top: 3rem; }
  .lg-right-3-rem {
    right: 3rem; }
  .lg-bottom-3-rem {
    bottom: 3rem; }
  .lg-left-3-rem {
    left: 3rem; }
  .lg-top-3-p {
    top: 30%; }
  .lg-right-3-p {
    right: 30%; }
  .lg-bottom-3-p {
    bottom: 30%; }
  .lg-left-3-p {
    left: 30%; }
  .lg-top-4-rem {
    top: 4rem; }
  .lg-right-4-rem {
    right: 4rem; }
  .lg-bottom-4-rem {
    bottom: 4rem; }
  .lg-left-4-rem {
    left: 4rem; }
  .lg-top-4-p {
    top: 40%; }
  .lg-right-4-p {
    right: 40%; }
  .lg-bottom-4-p {
    bottom: 40%; }
  .lg-left-4-p {
    left: 40%; }
  .lg-top-5-rem {
    top: 5rem; }
  .lg-right-5-rem {
    right: 5rem; }
  .lg-bottom-5-rem {
    bottom: 5rem; }
  .lg-left-5-rem {
    left: 5rem; }
  .lg-top-5-p {
    top: 50%; }
  .lg-right-5-p {
    right: 50%; }
  .lg-bottom-5-p {
    bottom: 50%; }
  .lg-left-5-p {
    left: 50%; }
  .lg-top-6-rem {
    top: 6rem; }
  .lg-right-6-rem {
    right: 6rem; }
  .lg-bottom-6-rem {
    bottom: 6rem; }
  .lg-left-6-rem {
    left: 6rem; }
  .lg-top-6-p {
    top: 60%; }
  .lg-right-6-p {
    right: 60%; }
  .lg-bottom-6-p {
    bottom: 60%; }
  .lg-left-6-p {
    left: 60%; }
  .lg-top-7-rem {
    top: 7rem; }
  .lg-right-7-rem {
    right: 7rem; }
  .lg-bottom-7-rem {
    bottom: 7rem; }
  .lg-left-7-rem {
    left: 7rem; }
  .lg-top-7-p {
    top: 70%; }
  .lg-right-7-p {
    right: 70%; }
  .lg-bottom-7-p {
    bottom: 70%; }
  .lg-left-7-p {
    left: 70%; }
  .lg-top-8-rem {
    top: 8rem; }
  .lg-right-8-rem {
    right: 8rem; }
  .lg-bottom-8-rem {
    bottom: 8rem; }
  .lg-left-8-rem {
    left: 8rem; }
  .lg-top-8-p {
    top: 80%; }
  .lg-right-8-p {
    right: 80%; }
  .lg-bottom-8-p {
    bottom: 80%; }
  .lg-left-8-p {
    left: 80%; }
  .lg-top-9-rem {
    top: 9rem; }
  .lg-right-9-rem {
    right: 9rem; }
  .lg-bottom-9-rem {
    bottom: 9rem; }
  .lg-left-9-rem {
    left: 9rem; }
  .lg-top-9-p {
    top: 90%; }
  .lg-right-9-p {
    right: 90%; }
  .lg-bottom-9-p {
    bottom: 90%; }
  .lg-left-9-p {
    left: 90%; }
  .lg-top-10-rem {
    top: 10rem; }
  .lg-right-10-rem {
    right: 10rem; }
  .lg-bottom-10-rem {
    bottom: 10rem; }
  .lg-left-10-rem {
    left: 10rem; }
  .lg-top-10-p {
    top: 100%; }
  .lg-right-10-p {
    right: 100%; }
  .lg-bottom-10-p {
    bottom: 100%; }
  .lg-left-10-p {
    left: 100%; } }

@media (min-width: 1280px) {
  .xl-top-0-rem {
    top: 0rem; }
  .xl-right-0-rem {
    right: 0rem; }
  .xl-bottom-0-rem {
    bottom: 0rem; }
  .xl-left-0-rem {
    left: 0rem; }
  .xl-top-0-p {
    top: 0%; }
  .xl-right-0-p {
    right: 0%; }
  .xl-bottom-0-p {
    bottom: 0%; }
  .xl-left-0-p {
    left: 0%; }
  .xl-top-1-rem {
    top: 1rem; }
  .xl-right-1-rem {
    right: 1rem; }
  .xl-bottom-1-rem {
    bottom: 1rem; }
  .xl-left-1-rem {
    left: 1rem; }
  .xl-top-1-p {
    top: 10%; }
  .xl-right-1-p {
    right: 10%; }
  .xl-bottom-1-p {
    bottom: 10%; }
  .xl-left-1-p {
    left: 10%; }
  .xl-top-2-rem {
    top: 2rem; }
  .xl-right-2-rem {
    right: 2rem; }
  .xl-bottom-2-rem {
    bottom: 2rem; }
  .xl-left-2-rem {
    left: 2rem; }
  .xl-top-2-p {
    top: 20%; }
  .xl-right-2-p {
    right: 20%; }
  .xl-bottom-2-p {
    bottom: 20%; }
  .xl-left-2-p {
    left: 20%; }
  .xl-top-3-rem {
    top: 3rem; }
  .xl-right-3-rem {
    right: 3rem; }
  .xl-bottom-3-rem {
    bottom: 3rem; }
  .xl-left-3-rem {
    left: 3rem; }
  .xl-top-3-p {
    top: 30%; }
  .xl-right-3-p {
    right: 30%; }
  .xl-bottom-3-p {
    bottom: 30%; }
  .xl-left-3-p {
    left: 30%; }
  .xl-top-4-rem {
    top: 4rem; }
  .xl-right-4-rem {
    right: 4rem; }
  .xl-bottom-4-rem {
    bottom: 4rem; }
  .xl-left-4-rem {
    left: 4rem; }
  .xl-top-4-p {
    top: 40%; }
  .xl-right-4-p {
    right: 40%; }
  .xl-bottom-4-p {
    bottom: 40%; }
  .xl-left-4-p {
    left: 40%; }
  .xl-top-5-rem {
    top: 5rem; }
  .xl-right-5-rem {
    right: 5rem; }
  .xl-bottom-5-rem {
    bottom: 5rem; }
  .xl-left-5-rem {
    left: 5rem; }
  .xl-top-5-p {
    top: 50%; }
  .xl-right-5-p {
    right: 50%; }
  .xl-bottom-5-p {
    bottom: 50%; }
  .xl-left-5-p {
    left: 50%; }
  .xl-top-6-rem {
    top: 6rem; }
  .xl-right-6-rem {
    right: 6rem; }
  .xl-bottom-6-rem {
    bottom: 6rem; }
  .xl-left-6-rem {
    left: 6rem; }
  .xl-top-6-p {
    top: 60%; }
  .xl-right-6-p {
    right: 60%; }
  .xl-bottom-6-p {
    bottom: 60%; }
  .xl-left-6-p {
    left: 60%; }
  .xl-top-7-rem {
    top: 7rem; }
  .xl-right-7-rem {
    right: 7rem; }
  .xl-bottom-7-rem {
    bottom: 7rem; }
  .xl-left-7-rem {
    left: 7rem; }
  .xl-top-7-p {
    top: 70%; }
  .xl-right-7-p {
    right: 70%; }
  .xl-bottom-7-p {
    bottom: 70%; }
  .xl-left-7-p {
    left: 70%; }
  .xl-top-8-rem {
    top: 8rem; }
  .xl-right-8-rem {
    right: 8rem; }
  .xl-bottom-8-rem {
    bottom: 8rem; }
  .xl-left-8-rem {
    left: 8rem; }
  .xl-top-8-p {
    top: 80%; }
  .xl-right-8-p {
    right: 80%; }
  .xl-bottom-8-p {
    bottom: 80%; }
  .xl-left-8-p {
    left: 80%; }
  .xl-top-9-rem {
    top: 9rem; }
  .xl-right-9-rem {
    right: 9rem; }
  .xl-bottom-9-rem {
    bottom: 9rem; }
  .xl-left-9-rem {
    left: 9rem; }
  .xl-top-9-p {
    top: 90%; }
  .xl-right-9-p {
    right: 90%; }
  .xl-bottom-9-p {
    bottom: 90%; }
  .xl-left-9-p {
    left: 90%; }
  .xl-top-10-rem {
    top: 10rem; }
  .xl-right-10-rem {
    right: 10rem; }
  .xl-bottom-10-rem {
    bottom: 10rem; }
  .xl-left-10-rem {
    left: 10rem; }
  .xl-top-10-p {
    top: 100%; }
  .xl-right-10-p {
    right: 100%; }
  .xl-bottom-10-p {
    bottom: 100%; }
  .xl-left-10-p {
    left: 100%; } }

@media (min-width: 1920px) {
  .xxl-top-0-rem {
    top: 0rem; }
  .xxl-right-0-rem {
    right: 0rem; }
  .xxl-bottom-0-rem {
    bottom: 0rem; }
  .xxl-left-0-rem {
    left: 0rem; }
  .xxl-top-0-p {
    top: 0%; }
  .xxl-right-0-p {
    right: 0%; }
  .xxl-bottom-0-p {
    bottom: 0%; }
  .xxl-left-0-p {
    left: 0%; }
  .xxl-top-1-rem {
    top: 1rem; }
  .xxl-right-1-rem {
    right: 1rem; }
  .xxl-bottom-1-rem {
    bottom: 1rem; }
  .xxl-left-1-rem {
    left: 1rem; }
  .xxl-top-1-p {
    top: 10%; }
  .xxl-right-1-p {
    right: 10%; }
  .xxl-bottom-1-p {
    bottom: 10%; }
  .xxl-left-1-p {
    left: 10%; }
  .xxl-top-2-rem {
    top: 2rem; }
  .xxl-right-2-rem {
    right: 2rem; }
  .xxl-bottom-2-rem {
    bottom: 2rem; }
  .xxl-left-2-rem {
    left: 2rem; }
  .xxl-top-2-p {
    top: 20%; }
  .xxl-right-2-p {
    right: 20%; }
  .xxl-bottom-2-p {
    bottom: 20%; }
  .xxl-left-2-p {
    left: 20%; }
  .xxl-top-3-rem {
    top: 3rem; }
  .xxl-right-3-rem {
    right: 3rem; }
  .xxl-bottom-3-rem {
    bottom: 3rem; }
  .xxl-left-3-rem {
    left: 3rem; }
  .xxl-top-3-p {
    top: 30%; }
  .xxl-right-3-p {
    right: 30%; }
  .xxl-bottom-3-p {
    bottom: 30%; }
  .xxl-left-3-p {
    left: 30%; }
  .xxl-top-4-rem {
    top: 4rem; }
  .xxl-right-4-rem {
    right: 4rem; }
  .xxl-bottom-4-rem {
    bottom: 4rem; }
  .xxl-left-4-rem {
    left: 4rem; }
  .xxl-top-4-p {
    top: 40%; }
  .xxl-right-4-p {
    right: 40%; }
  .xxl-bottom-4-p {
    bottom: 40%; }
  .xxl-left-4-p {
    left: 40%; }
  .xxl-top-5-rem {
    top: 5rem; }
  .xxl-right-5-rem {
    right: 5rem; }
  .xxl-bottom-5-rem {
    bottom: 5rem; }
  .xxl-left-5-rem {
    left: 5rem; }
  .xxl-top-5-p {
    top: 50%; }
  .xxl-right-5-p {
    right: 50%; }
  .xxl-bottom-5-p {
    bottom: 50%; }
  .xxl-left-5-p {
    left: 50%; }
  .xxl-top-6-rem {
    top: 6rem; }
  .xxl-right-6-rem {
    right: 6rem; }
  .xxl-bottom-6-rem {
    bottom: 6rem; }
  .xxl-left-6-rem {
    left: 6rem; }
  .xxl-top-6-p {
    top: 60%; }
  .xxl-right-6-p {
    right: 60%; }
  .xxl-bottom-6-p {
    bottom: 60%; }
  .xxl-left-6-p {
    left: 60%; }
  .xxl-top-7-rem {
    top: 7rem; }
  .xxl-right-7-rem {
    right: 7rem; }
  .xxl-bottom-7-rem {
    bottom: 7rem; }
  .xxl-left-7-rem {
    left: 7rem; }
  .xxl-top-7-p {
    top: 70%; }
  .xxl-right-7-p {
    right: 70%; }
  .xxl-bottom-7-p {
    bottom: 70%; }
  .xxl-left-7-p {
    left: 70%; }
  .xxl-top-8-rem {
    top: 8rem; }
  .xxl-right-8-rem {
    right: 8rem; }
  .xxl-bottom-8-rem {
    bottom: 8rem; }
  .xxl-left-8-rem {
    left: 8rem; }
  .xxl-top-8-p {
    top: 80%; }
  .xxl-right-8-p {
    right: 80%; }
  .xxl-bottom-8-p {
    bottom: 80%; }
  .xxl-left-8-p {
    left: 80%; }
  .xxl-top-9-rem {
    top: 9rem; }
  .xxl-right-9-rem {
    right: 9rem; }
  .xxl-bottom-9-rem {
    bottom: 9rem; }
  .xxl-left-9-rem {
    left: 9rem; }
  .xxl-top-9-p {
    top: 90%; }
  .xxl-right-9-p {
    right: 90%; }
  .xxl-bottom-9-p {
    bottom: 90%; }
  .xxl-left-9-p {
    left: 90%; }
  .xxl-top-10-rem {
    top: 10rem; }
  .xxl-right-10-rem {
    right: 10rem; }
  .xxl-bottom-10-rem {
    bottom: 10rem; }
  .xxl-left-10-rem {
    left: 10rem; }
  .xxl-top-10-p {
    top: 100%; }
  .xxl-right-10-p {
    right: 100%; }
  .xxl-bottom-10-p {
    bottom: 100%; }
  .xxl-left-10-p {
    left: 100%; } }

@media (min-width: 3000px) {
  .xxxl-top-0-rem {
    top: 0rem; }
  .xxxl-right-0-rem {
    right: 0rem; }
  .xxxl-bottom-0-rem {
    bottom: 0rem; }
  .xxxl-left-0-rem {
    left: 0rem; }
  .xxxl-top-0-p {
    top: 0%; }
  .xxxl-right-0-p {
    right: 0%; }
  .xxxl-bottom-0-p {
    bottom: 0%; }
  .xxxl-left-0-p {
    left: 0%; }
  .xxxl-top-1-rem {
    top: 1rem; }
  .xxxl-right-1-rem {
    right: 1rem; }
  .xxxl-bottom-1-rem {
    bottom: 1rem; }
  .xxxl-left-1-rem {
    left: 1rem; }
  .xxxl-top-1-p {
    top: 10%; }
  .xxxl-right-1-p {
    right: 10%; }
  .xxxl-bottom-1-p {
    bottom: 10%; }
  .xxxl-left-1-p {
    left: 10%; }
  .xxxl-top-2-rem {
    top: 2rem; }
  .xxxl-right-2-rem {
    right: 2rem; }
  .xxxl-bottom-2-rem {
    bottom: 2rem; }
  .xxxl-left-2-rem {
    left: 2rem; }
  .xxxl-top-2-p {
    top: 20%; }
  .xxxl-right-2-p {
    right: 20%; }
  .xxxl-bottom-2-p {
    bottom: 20%; }
  .xxxl-left-2-p {
    left: 20%; }
  .xxxl-top-3-rem {
    top: 3rem; }
  .xxxl-right-3-rem {
    right: 3rem; }
  .xxxl-bottom-3-rem {
    bottom: 3rem; }
  .xxxl-left-3-rem {
    left: 3rem; }
  .xxxl-top-3-p {
    top: 30%; }
  .xxxl-right-3-p {
    right: 30%; }
  .xxxl-bottom-3-p {
    bottom: 30%; }
  .xxxl-left-3-p {
    left: 30%; }
  .xxxl-top-4-rem {
    top: 4rem; }
  .xxxl-right-4-rem {
    right: 4rem; }
  .xxxl-bottom-4-rem {
    bottom: 4rem; }
  .xxxl-left-4-rem {
    left: 4rem; }
  .xxxl-top-4-p {
    top: 40%; }
  .xxxl-right-4-p {
    right: 40%; }
  .xxxl-bottom-4-p {
    bottom: 40%; }
  .xxxl-left-4-p {
    left: 40%; }
  .xxxl-top-5-rem {
    top: 5rem; }
  .xxxl-right-5-rem {
    right: 5rem; }
  .xxxl-bottom-5-rem {
    bottom: 5rem; }
  .xxxl-left-5-rem {
    left: 5rem; }
  .xxxl-top-5-p {
    top: 50%; }
  .xxxl-right-5-p {
    right: 50%; }
  .xxxl-bottom-5-p {
    bottom: 50%; }
  .xxxl-left-5-p {
    left: 50%; }
  .xxxl-top-6-rem {
    top: 6rem; }
  .xxxl-right-6-rem {
    right: 6rem; }
  .xxxl-bottom-6-rem {
    bottom: 6rem; }
  .xxxl-left-6-rem {
    left: 6rem; }
  .xxxl-top-6-p {
    top: 60%; }
  .xxxl-right-6-p {
    right: 60%; }
  .xxxl-bottom-6-p {
    bottom: 60%; }
  .xxxl-left-6-p {
    left: 60%; }
  .xxxl-top-7-rem {
    top: 7rem; }
  .xxxl-right-7-rem {
    right: 7rem; }
  .xxxl-bottom-7-rem {
    bottom: 7rem; }
  .xxxl-left-7-rem {
    left: 7rem; }
  .xxxl-top-7-p {
    top: 70%; }
  .xxxl-right-7-p {
    right: 70%; }
  .xxxl-bottom-7-p {
    bottom: 70%; }
  .xxxl-left-7-p {
    left: 70%; }
  .xxxl-top-8-rem {
    top: 8rem; }
  .xxxl-right-8-rem {
    right: 8rem; }
  .xxxl-bottom-8-rem {
    bottom: 8rem; }
  .xxxl-left-8-rem {
    left: 8rem; }
  .xxxl-top-8-p {
    top: 80%; }
  .xxxl-right-8-p {
    right: 80%; }
  .xxxl-bottom-8-p {
    bottom: 80%; }
  .xxxl-left-8-p {
    left: 80%; }
  .xxxl-top-9-rem {
    top: 9rem; }
  .xxxl-right-9-rem {
    right: 9rem; }
  .xxxl-bottom-9-rem {
    bottom: 9rem; }
  .xxxl-left-9-rem {
    left: 9rem; }
  .xxxl-top-9-p {
    top: 90%; }
  .xxxl-right-9-p {
    right: 90%; }
  .xxxl-bottom-9-p {
    bottom: 90%; }
  .xxxl-left-9-p {
    left: 90%; }
  .xxxl-top-10-rem {
    top: 10rem; }
  .xxxl-right-10-rem {
    right: 10rem; }
  .xxxl-bottom-10-rem {
    bottom: 10rem; }
  .xxxl-left-10-rem {
    left: 10rem; }
  .xxxl-top-10-p {
    top: 100%; }
  .xxxl-right-10-p {
    right: 100%; }
  .xxxl-bottom-10-p {
    bottom: 100%; }
  .xxxl-left-10-p {
    left: 100%; } }

@media (min-width: 0px) {
  .xs-fz-60p {
    font-size: 60%; }
  .xs-fz-70p {
    font-size: 70%; }
  .xs-fz-80p {
    font-size: 80%; }
  .xs-fz-90p {
    font-size: 90%; }
  .xs-fz-100p {
    font-size: 100%; }
  .xs-fz-110p {
    font-size: 110%; }
  .xs-fz-120p {
    font-size: 120%; }
  .xs-fz-130p {
    font-size: 130%; }
  .xs-fz-140p {
    font-size: 140%; }
  .xs-fz-150p {
    font-size: 150%; }
  .xs-fz-160p {
    font-size: 160%; }
  .xs-fz-170p {
    font-size: 170%; }
  .xs-fz-180p {
    font-size: 180%; }
  .xs-fz-190p {
    font-size: 190%; }
  .xs-fz-200p {
    font-size: 200%; }
  .xs-fz-210p {
    font-size: 210%; }
  .xs-fz-220p {
    font-size: 220%; }
  .xs-fz-230p {
    font-size: 230%; }
  .xs-fz-240p {
    font-size: 240%; }
  .xs-fz-250p {
    font-size: 250%; } }

@media (min-width: 544px) {
  .sm-fz-60p {
    font-size: 60%; }
  .sm-fz-70p {
    font-size: 70%; }
  .sm-fz-80p {
    font-size: 80%; }
  .sm-fz-90p {
    font-size: 90%; }
  .sm-fz-100p {
    font-size: 100%; }
  .sm-fz-110p {
    font-size: 110%; }
  .sm-fz-120p {
    font-size: 120%; }
  .sm-fz-130p {
    font-size: 130%; }
  .sm-fz-140p {
    font-size: 140%; }
  .sm-fz-150p {
    font-size: 150%; }
  .sm-fz-160p {
    font-size: 160%; }
  .sm-fz-170p {
    font-size: 170%; }
  .sm-fz-180p {
    font-size: 180%; }
  .sm-fz-190p {
    font-size: 190%; }
  .sm-fz-200p {
    font-size: 200%; }
  .sm-fz-210p {
    font-size: 210%; }
  .sm-fz-220p {
    font-size: 220%; }
  .sm-fz-230p {
    font-size: 230%; }
  .sm-fz-240p {
    font-size: 240%; }
  .sm-fz-250p {
    font-size: 250%; } }

@media (min-width: 768px) {
  .md-fz-60p {
    font-size: 60%; }
  .md-fz-70p {
    font-size: 70%; }
  .md-fz-80p {
    font-size: 80%; }
  .md-fz-90p {
    font-size: 90%; }
  .md-fz-100p {
    font-size: 100%; }
  .md-fz-110p {
    font-size: 110%; }
  .md-fz-120p {
    font-size: 120%; }
  .md-fz-130p {
    font-size: 130%; }
  .md-fz-140p {
    font-size: 140%; }
  .md-fz-150p {
    font-size: 150%; }
  .md-fz-160p {
    font-size: 160%; }
  .md-fz-170p {
    font-size: 170%; }
  .md-fz-180p {
    font-size: 180%; }
  .md-fz-190p {
    font-size: 190%; }
  .md-fz-200p {
    font-size: 200%; }
  .md-fz-210p {
    font-size: 210%; }
  .md-fz-220p {
    font-size: 220%; }
  .md-fz-230p {
    font-size: 230%; }
  .md-fz-240p {
    font-size: 240%; }
  .md-fz-250p {
    font-size: 250%; } }

@media (min-width: 992px) {
  .lg-fz-60p {
    font-size: 60%; }
  .lg-fz-70p {
    font-size: 70%; }
  .lg-fz-80p {
    font-size: 80%; }
  .lg-fz-90p {
    font-size: 90%; }
  .lg-fz-100p {
    font-size: 100%; }
  .lg-fz-110p {
    font-size: 110%; }
  .lg-fz-120p {
    font-size: 120%; }
  .lg-fz-130p {
    font-size: 130%; }
  .lg-fz-140p {
    font-size: 140%; }
  .lg-fz-150p {
    font-size: 150%; }
  .lg-fz-160p {
    font-size: 160%; }
  .lg-fz-170p {
    font-size: 170%; }
  .lg-fz-180p {
    font-size: 180%; }
  .lg-fz-190p {
    font-size: 190%; }
  .lg-fz-200p {
    font-size: 200%; }
  .lg-fz-210p {
    font-size: 210%; }
  .lg-fz-220p {
    font-size: 220%; }
  .lg-fz-230p {
    font-size: 230%; }
  .lg-fz-240p {
    font-size: 240%; }
  .lg-fz-250p {
    font-size: 250%; } }

@media (min-width: 1280px) {
  .xl-fz-60p {
    font-size: 60%; }
  .xl-fz-70p {
    font-size: 70%; }
  .xl-fz-80p {
    font-size: 80%; }
  .xl-fz-90p {
    font-size: 90%; }
  .xl-fz-100p {
    font-size: 100%; }
  .xl-fz-110p {
    font-size: 110%; }
  .xl-fz-120p {
    font-size: 120%; }
  .xl-fz-130p {
    font-size: 130%; }
  .xl-fz-140p {
    font-size: 140%; }
  .xl-fz-150p {
    font-size: 150%; }
  .xl-fz-160p {
    font-size: 160%; }
  .xl-fz-170p {
    font-size: 170%; }
  .xl-fz-180p {
    font-size: 180%; }
  .xl-fz-190p {
    font-size: 190%; }
  .xl-fz-200p {
    font-size: 200%; }
  .xl-fz-210p {
    font-size: 210%; }
  .xl-fz-220p {
    font-size: 220%; }
  .xl-fz-230p {
    font-size: 230%; }
  .xl-fz-240p {
    font-size: 240%; }
  .xl-fz-250p {
    font-size: 250%; } }

@media (min-width: 1920px) {
  .xxl-fz-60p {
    font-size: 60%; }
  .xxl-fz-70p {
    font-size: 70%; }
  .xxl-fz-80p {
    font-size: 80%; }
  .xxl-fz-90p {
    font-size: 90%; }
  .xxl-fz-100p {
    font-size: 100%; }
  .xxl-fz-110p {
    font-size: 110%; }
  .xxl-fz-120p {
    font-size: 120%; }
  .xxl-fz-130p {
    font-size: 130%; }
  .xxl-fz-140p {
    font-size: 140%; }
  .xxl-fz-150p {
    font-size: 150%; }
  .xxl-fz-160p {
    font-size: 160%; }
  .xxl-fz-170p {
    font-size: 170%; }
  .xxl-fz-180p {
    font-size: 180%; }
  .xxl-fz-190p {
    font-size: 190%; }
  .xxl-fz-200p {
    font-size: 200%; }
  .xxl-fz-210p {
    font-size: 210%; }
  .xxl-fz-220p {
    font-size: 220%; }
  .xxl-fz-230p {
    font-size: 230%; }
  .xxl-fz-240p {
    font-size: 240%; }
  .xxl-fz-250p {
    font-size: 250%; } }

@media (min-width: 3000px) {
  .xxxl-fz-60p {
    font-size: 60%; }
  .xxxl-fz-70p {
    font-size: 70%; }
  .xxxl-fz-80p {
    font-size: 80%; }
  .xxxl-fz-90p {
    font-size: 90%; }
  .xxxl-fz-100p {
    font-size: 100%; }
  .xxxl-fz-110p {
    font-size: 110%; }
  .xxxl-fz-120p {
    font-size: 120%; }
  .xxxl-fz-130p {
    font-size: 130%; }
  .xxxl-fz-140p {
    font-size: 140%; }
  .xxxl-fz-150p {
    font-size: 150%; }
  .xxxl-fz-160p {
    font-size: 160%; }
  .xxxl-fz-170p {
    font-size: 170%; }
  .xxxl-fz-180p {
    font-size: 180%; }
  .xxxl-fz-190p {
    font-size: 190%; }
  .xxxl-fz-200p {
    font-size: 200%; }
  .xxxl-fz-210p {
    font-size: 210%; }
  .xxxl-fz-220p {
    font-size: 220%; }
  .xxxl-fz-230p {
    font-size: 230%; }
  .xxxl-fz-240p {
    font-size: 240%; }
  .xxxl-fz-250p {
    font-size: 250%; } }

.fz-60p {
  font-size: 60%; }

.fz-70p {
  font-size: 70%; }

.fz-80p {
  font-size: 80%; }

.fz-90p {
  font-size: 90%; }

.fz-100p {
  font-size: 100%; }

.fz-110p {
  font-size: 110%; }

.fz-120p {
  font-size: 120%; }

.fz-130p {
  font-size: 130%; }

.fz-140p {
  font-size: 140%; }

.fz-150p {
  font-size: 150%; }

.fz-160p {
  font-size: 160%; }

.fz-170p {
  font-size: 170%; }

.fz-180p {
  font-size: 180%; }

.fz-190p {
  font-size: 190%; }

.fz-200p {
  font-size: 200%; }

.fz-210p {
  font-size: 210%; }

.fz-220p {
  font-size: 220%; }

.fz-230p {
  font-size: 230%; }

.fz-240p {
  font-size: 240%; }

.fz-250p {
  font-size: 250%; }

@media (min-width: 0px) {
  .xs-fz-1em {
    font-size: 0.1em; }
  .xs-fz-2em {
    font-size: 0.2em; }
  .xs-fz-3em {
    font-size: 0.3em; }
  .xs-fz-4em {
    font-size: 0.4em; }
  .xs-fz-5em {
    font-size: 0.5em; }
  .xs-fz-6em {
    font-size: 0.6em; }
  .xs-fz-7em {
    font-size: 0.7em; }
  .xs-fz-8em {
    font-size: 0.8em; }
  .xs-fz-9em {
    font-size: 0.9em; }
  .xs-fz-10em {
    font-size: 1em; }
  .xs-fz-11em {
    font-size: 1.1em; }
  .xs-fz-12em {
    font-size: 1.2em; }
  .xs-fz-13em {
    font-size: 1.3em; }
  .xs-fz-14em {
    font-size: 1.4em; }
  .xs-fz-15em {
    font-size: 1.5em; }
  .xs-fz-16em {
    font-size: 1.6em; }
  .xs-fz-17em {
    font-size: 1.7em; }
  .xs-fz-18em {
    font-size: 1.8em; }
  .xs-fz-19em {
    font-size: 1.9em; }
  .xs-fz-20em {
    font-size: 2em; }
  .xs-fz-21em {
    font-size: 2.1em; }
  .xs-fz-22em {
    font-size: 2.2em; }
  .xs-fz-23em {
    font-size: 2.3em; }
  .xs-fz-24em {
    font-size: 2.4em; }
  .xs-fz-25em {
    font-size: 2.5em; } }

@media (min-width: 544px) {
  .sm-fz-1em {
    font-size: 0.1em; }
  .sm-fz-2em {
    font-size: 0.2em; }
  .sm-fz-3em {
    font-size: 0.3em; }
  .sm-fz-4em {
    font-size: 0.4em; }
  .sm-fz-5em {
    font-size: 0.5em; }
  .sm-fz-6em {
    font-size: 0.6em; }
  .sm-fz-7em {
    font-size: 0.7em; }
  .sm-fz-8em {
    font-size: 0.8em; }
  .sm-fz-9em {
    font-size: 0.9em; }
  .sm-fz-10em {
    font-size: 1em; }
  .sm-fz-11em {
    font-size: 1.1em; }
  .sm-fz-12em {
    font-size: 1.2em; }
  .sm-fz-13em {
    font-size: 1.3em; }
  .sm-fz-14em {
    font-size: 1.4em; }
  .sm-fz-15em {
    font-size: 1.5em; }
  .sm-fz-16em {
    font-size: 1.6em; }
  .sm-fz-17em {
    font-size: 1.7em; }
  .sm-fz-18em {
    font-size: 1.8em; }
  .sm-fz-19em {
    font-size: 1.9em; }
  .sm-fz-20em {
    font-size: 2em; }
  .sm-fz-21em {
    font-size: 2.1em; }
  .sm-fz-22em {
    font-size: 2.2em; }
  .sm-fz-23em {
    font-size: 2.3em; }
  .sm-fz-24em {
    font-size: 2.4em; }
  .sm-fz-25em {
    font-size: 2.5em; } }

@media (min-width: 768px) {
  .md-fz-1em {
    font-size: 0.1em; }
  .md-fz-2em {
    font-size: 0.2em; }
  .md-fz-3em {
    font-size: 0.3em; }
  .md-fz-4em {
    font-size: 0.4em; }
  .md-fz-5em {
    font-size: 0.5em; }
  .md-fz-6em {
    font-size: 0.6em; }
  .md-fz-7em {
    font-size: 0.7em; }
  .md-fz-8em {
    font-size: 0.8em; }
  .md-fz-9em {
    font-size: 0.9em; }
  .md-fz-10em {
    font-size: 1em; }
  .md-fz-11em {
    font-size: 1.1em; }
  .md-fz-12em {
    font-size: 1.2em; }
  .md-fz-13em {
    font-size: 1.3em; }
  .md-fz-14em {
    font-size: 1.4em; }
  .md-fz-15em {
    font-size: 1.5em; }
  .md-fz-16em {
    font-size: 1.6em; }
  .md-fz-17em {
    font-size: 1.7em; }
  .md-fz-18em {
    font-size: 1.8em; }
  .md-fz-19em {
    font-size: 1.9em; }
  .md-fz-20em {
    font-size: 2em; }
  .md-fz-21em {
    font-size: 2.1em; }
  .md-fz-22em {
    font-size: 2.2em; }
  .md-fz-23em {
    font-size: 2.3em; }
  .md-fz-24em {
    font-size: 2.4em; }
  .md-fz-25em {
    font-size: 2.5em; } }

@media (min-width: 992px) {
  .lg-fz-1em {
    font-size: 0.1em; }
  .lg-fz-2em {
    font-size: 0.2em; }
  .lg-fz-3em {
    font-size: 0.3em; }
  .lg-fz-4em {
    font-size: 0.4em; }
  .lg-fz-5em {
    font-size: 0.5em; }
  .lg-fz-6em {
    font-size: 0.6em; }
  .lg-fz-7em {
    font-size: 0.7em; }
  .lg-fz-8em {
    font-size: 0.8em; }
  .lg-fz-9em {
    font-size: 0.9em; }
  .lg-fz-10em {
    font-size: 1em; }
  .lg-fz-11em {
    font-size: 1.1em; }
  .lg-fz-12em {
    font-size: 1.2em; }
  .lg-fz-13em {
    font-size: 1.3em; }
  .lg-fz-14em {
    font-size: 1.4em; }
  .lg-fz-15em {
    font-size: 1.5em; }
  .lg-fz-16em {
    font-size: 1.6em; }
  .lg-fz-17em {
    font-size: 1.7em; }
  .lg-fz-18em {
    font-size: 1.8em; }
  .lg-fz-19em {
    font-size: 1.9em; }
  .lg-fz-20em {
    font-size: 2em; }
  .lg-fz-21em {
    font-size: 2.1em; }
  .lg-fz-22em {
    font-size: 2.2em; }
  .lg-fz-23em {
    font-size: 2.3em; }
  .lg-fz-24em {
    font-size: 2.4em; }
  .lg-fz-25em {
    font-size: 2.5em; } }

@media (min-width: 1280px) {
  .xl-fz-1em {
    font-size: 0.1em; }
  .xl-fz-2em {
    font-size: 0.2em; }
  .xl-fz-3em {
    font-size: 0.3em; }
  .xl-fz-4em {
    font-size: 0.4em; }
  .xl-fz-5em {
    font-size: 0.5em; }
  .xl-fz-6em {
    font-size: 0.6em; }
  .xl-fz-7em {
    font-size: 0.7em; }
  .xl-fz-8em {
    font-size: 0.8em; }
  .xl-fz-9em {
    font-size: 0.9em; }
  .xl-fz-10em {
    font-size: 1em; }
  .xl-fz-11em {
    font-size: 1.1em; }
  .xl-fz-12em {
    font-size: 1.2em; }
  .xl-fz-13em {
    font-size: 1.3em; }
  .xl-fz-14em {
    font-size: 1.4em; }
  .xl-fz-15em {
    font-size: 1.5em; }
  .xl-fz-16em {
    font-size: 1.6em; }
  .xl-fz-17em {
    font-size: 1.7em; }
  .xl-fz-18em {
    font-size: 1.8em; }
  .xl-fz-19em {
    font-size: 1.9em; }
  .xl-fz-20em {
    font-size: 2em; }
  .xl-fz-21em {
    font-size: 2.1em; }
  .xl-fz-22em {
    font-size: 2.2em; }
  .xl-fz-23em {
    font-size: 2.3em; }
  .xl-fz-24em {
    font-size: 2.4em; }
  .xl-fz-25em {
    font-size: 2.5em; } }

@media (min-width: 1920px) {
  .xxl-fz-1em {
    font-size: 0.1em; }
  .xxl-fz-2em {
    font-size: 0.2em; }
  .xxl-fz-3em {
    font-size: 0.3em; }
  .xxl-fz-4em {
    font-size: 0.4em; }
  .xxl-fz-5em {
    font-size: 0.5em; }
  .xxl-fz-6em {
    font-size: 0.6em; }
  .xxl-fz-7em {
    font-size: 0.7em; }
  .xxl-fz-8em {
    font-size: 0.8em; }
  .xxl-fz-9em {
    font-size: 0.9em; }
  .xxl-fz-10em {
    font-size: 1em; }
  .xxl-fz-11em {
    font-size: 1.1em; }
  .xxl-fz-12em {
    font-size: 1.2em; }
  .xxl-fz-13em {
    font-size: 1.3em; }
  .xxl-fz-14em {
    font-size: 1.4em; }
  .xxl-fz-15em {
    font-size: 1.5em; }
  .xxl-fz-16em {
    font-size: 1.6em; }
  .xxl-fz-17em {
    font-size: 1.7em; }
  .xxl-fz-18em {
    font-size: 1.8em; }
  .xxl-fz-19em {
    font-size: 1.9em; }
  .xxl-fz-20em {
    font-size: 2em; }
  .xxl-fz-21em {
    font-size: 2.1em; }
  .xxl-fz-22em {
    font-size: 2.2em; }
  .xxl-fz-23em {
    font-size: 2.3em; }
  .xxl-fz-24em {
    font-size: 2.4em; }
  .xxl-fz-25em {
    font-size: 2.5em; } }

@media (min-width: 3000px) {
  .xxxl-fz-1em {
    font-size: 0.1em; }
  .xxxl-fz-2em {
    font-size: 0.2em; }
  .xxxl-fz-3em {
    font-size: 0.3em; }
  .xxxl-fz-4em {
    font-size: 0.4em; }
  .xxxl-fz-5em {
    font-size: 0.5em; }
  .xxxl-fz-6em {
    font-size: 0.6em; }
  .xxxl-fz-7em {
    font-size: 0.7em; }
  .xxxl-fz-8em {
    font-size: 0.8em; }
  .xxxl-fz-9em {
    font-size: 0.9em; }
  .xxxl-fz-10em {
    font-size: 1em; }
  .xxxl-fz-11em {
    font-size: 1.1em; }
  .xxxl-fz-12em {
    font-size: 1.2em; }
  .xxxl-fz-13em {
    font-size: 1.3em; }
  .xxxl-fz-14em {
    font-size: 1.4em; }
  .xxxl-fz-15em {
    font-size: 1.5em; }
  .xxxl-fz-16em {
    font-size: 1.6em; }
  .xxxl-fz-17em {
    font-size: 1.7em; }
  .xxxl-fz-18em {
    font-size: 1.8em; }
  .xxxl-fz-19em {
    font-size: 1.9em; }
  .xxxl-fz-20em {
    font-size: 2em; }
  .xxxl-fz-21em {
    font-size: 2.1em; }
  .xxxl-fz-22em {
    font-size: 2.2em; }
  .xxxl-fz-23em {
    font-size: 2.3em; }
  .xxxl-fz-24em {
    font-size: 2.4em; }
  .xxxl-fz-25em {
    font-size: 2.5em; } }

.fz-1em {
  font-size: 0.1em; }

.fz-2em {
  font-size: 0.2em; }

.fz-3em {
  font-size: 0.3em; }

.fz-4em {
  font-size: 0.4em; }

.fz-5em {
  font-size: 0.5em; }

.fz-6em {
  font-size: 0.6em; }

.fz-7em {
  font-size: 0.7em; }

.fz-8em {
  font-size: 0.8em; }

.fz-9em {
  font-size: 0.9em; }

.fz-10em {
  font-size: 1em; }

.fz-11em {
  font-size: 1.1em; }

.fz-12em {
  font-size: 1.2em; }

.fz-13em {
  font-size: 1.3em; }

.fz-14em {
  font-size: 1.4em; }

.fz-15em {
  font-size: 1.5em; }

.fz-16em {
  font-size: 1.6em; }

.fz-17em {
  font-size: 1.7em; }

.fz-18em {
  font-size: 1.8em; }

.fz-19em {
  font-size: 1.9em; }

.fz-20em {
  font-size: 2em; }

.fz-21em {
  font-size: 2.1em; }

.fz-22em {
  font-size: 2.2em; }

.fz-23em {
  font-size: 2.3em; }

.fz-24em {
  font-size: 2.4em; }

.fz-25em {
  font-size: 2.5em; }

@media (min-width: 0px) {
  .xs-fz-1rem {
    font-size: 0.1rem; }
  .xs-fz-2rem {
    font-size: 0.2rem; }
  .xs-fz-3rem {
    font-size: 0.3rem; }
  .xs-fz-4rem {
    font-size: 0.4rem; }
  .xs-fz-5rem {
    font-size: 0.5rem; }
  .xs-fz-6rem {
    font-size: 0.6rem; }
  .xs-fz-7rem {
    font-size: 0.7rem; }
  .xs-fz-8rem {
    font-size: 0.8rem; }
  .xs-fz-9rem {
    font-size: 0.9rem; }
  .xs-fz-10rem {
    font-size: 1rem; }
  .xs-fz-11rem {
    font-size: 1.1rem; }
  .xs-fz-12rem {
    font-size: 1.2rem; }
  .xs-fz-13rem {
    font-size: 1.3rem; }
  .xs-fz-14rem {
    font-size: 1.4rem; }
  .xs-fz-15rem {
    font-size: 1.5rem; }
  .xs-fz-16rem {
    font-size: 1.6rem; }
  .xs-fz-17rem {
    font-size: 1.7rem; }
  .xs-fz-18rem {
    font-size: 1.8rem; }
  .xs-fz-19rem {
    font-size: 1.9rem; }
  .xs-fz-20rem {
    font-size: 2rem; }
  .xs-fz-21rem {
    font-size: 2.1rem; }
  .xs-fz-22rem {
    font-size: 2.2rem; }
  .xs-fz-23rem {
    font-size: 2.3rem; }
  .xs-fz-24rem {
    font-size: 2.4rem; }
  .xs-fz-25rem {
    font-size: 2.5rem; } }

@media (min-width: 544px) {
  .sm-fz-1rem {
    font-size: 0.1rem; }
  .sm-fz-2rem {
    font-size: 0.2rem; }
  .sm-fz-3rem {
    font-size: 0.3rem; }
  .sm-fz-4rem {
    font-size: 0.4rem; }
  .sm-fz-5rem {
    font-size: 0.5rem; }
  .sm-fz-6rem {
    font-size: 0.6rem; }
  .sm-fz-7rem {
    font-size: 0.7rem; }
  .sm-fz-8rem {
    font-size: 0.8rem; }
  .sm-fz-9rem {
    font-size: 0.9rem; }
  .sm-fz-10rem {
    font-size: 1rem; }
  .sm-fz-11rem {
    font-size: 1.1rem; }
  .sm-fz-12rem {
    font-size: 1.2rem; }
  .sm-fz-13rem {
    font-size: 1.3rem; }
  .sm-fz-14rem {
    font-size: 1.4rem; }
  .sm-fz-15rem {
    font-size: 1.5rem; }
  .sm-fz-16rem {
    font-size: 1.6rem; }
  .sm-fz-17rem {
    font-size: 1.7rem; }
  .sm-fz-18rem {
    font-size: 1.8rem; }
  .sm-fz-19rem {
    font-size: 1.9rem; }
  .sm-fz-20rem {
    font-size: 2rem; }
  .sm-fz-21rem {
    font-size: 2.1rem; }
  .sm-fz-22rem {
    font-size: 2.2rem; }
  .sm-fz-23rem {
    font-size: 2.3rem; }
  .sm-fz-24rem {
    font-size: 2.4rem; }
  .sm-fz-25rem {
    font-size: 2.5rem; } }

@media (min-width: 768px) {
  .md-fz-1rem {
    font-size: 0.1rem; }
  .md-fz-2rem {
    font-size: 0.2rem; }
  .md-fz-3rem {
    font-size: 0.3rem; }
  .md-fz-4rem {
    font-size: 0.4rem; }
  .md-fz-5rem {
    font-size: 0.5rem; }
  .md-fz-6rem {
    font-size: 0.6rem; }
  .md-fz-7rem {
    font-size: 0.7rem; }
  .md-fz-8rem {
    font-size: 0.8rem; }
  .md-fz-9rem {
    font-size: 0.9rem; }
  .md-fz-10rem {
    font-size: 1rem; }
  .md-fz-11rem {
    font-size: 1.1rem; }
  .md-fz-12rem {
    font-size: 1.2rem; }
  .md-fz-13rem {
    font-size: 1.3rem; }
  .md-fz-14rem {
    font-size: 1.4rem; }
  .md-fz-15rem {
    font-size: 1.5rem; }
  .md-fz-16rem {
    font-size: 1.6rem; }
  .md-fz-17rem {
    font-size: 1.7rem; }
  .md-fz-18rem {
    font-size: 1.8rem; }
  .md-fz-19rem {
    font-size: 1.9rem; }
  .md-fz-20rem {
    font-size: 2rem; }
  .md-fz-21rem {
    font-size: 2.1rem; }
  .md-fz-22rem {
    font-size: 2.2rem; }
  .md-fz-23rem {
    font-size: 2.3rem; }
  .md-fz-24rem {
    font-size: 2.4rem; }
  .md-fz-25rem {
    font-size: 2.5rem; } }

@media (min-width: 992px) {
  .lg-fz-1rem {
    font-size: 0.1rem; }
  .lg-fz-2rem {
    font-size: 0.2rem; }
  .lg-fz-3rem {
    font-size: 0.3rem; }
  .lg-fz-4rem {
    font-size: 0.4rem; }
  .lg-fz-5rem {
    font-size: 0.5rem; }
  .lg-fz-6rem {
    font-size: 0.6rem; }
  .lg-fz-7rem {
    font-size: 0.7rem; }
  .lg-fz-8rem {
    font-size: 0.8rem; }
  .lg-fz-9rem {
    font-size: 0.9rem; }
  .lg-fz-10rem {
    font-size: 1rem; }
  .lg-fz-11rem {
    font-size: 1.1rem; }
  .lg-fz-12rem {
    font-size: 1.2rem; }
  .lg-fz-13rem {
    font-size: 1.3rem; }
  .lg-fz-14rem {
    font-size: 1.4rem; }
  .lg-fz-15rem {
    font-size: 1.5rem; }
  .lg-fz-16rem {
    font-size: 1.6rem; }
  .lg-fz-17rem {
    font-size: 1.7rem; }
  .lg-fz-18rem {
    font-size: 1.8rem; }
  .lg-fz-19rem {
    font-size: 1.9rem; }
  .lg-fz-20rem {
    font-size: 2rem; }
  .lg-fz-21rem {
    font-size: 2.1rem; }
  .lg-fz-22rem {
    font-size: 2.2rem; }
  .lg-fz-23rem {
    font-size: 2.3rem; }
  .lg-fz-24rem {
    font-size: 2.4rem; }
  .lg-fz-25rem {
    font-size: 2.5rem; } }

@media (min-width: 1280px) {
  .xl-fz-1rem {
    font-size: 0.1rem; }
  .xl-fz-2rem {
    font-size: 0.2rem; }
  .xl-fz-3rem {
    font-size: 0.3rem; }
  .xl-fz-4rem {
    font-size: 0.4rem; }
  .xl-fz-5rem {
    font-size: 0.5rem; }
  .xl-fz-6rem {
    font-size: 0.6rem; }
  .xl-fz-7rem {
    font-size: 0.7rem; }
  .xl-fz-8rem {
    font-size: 0.8rem; }
  .xl-fz-9rem {
    font-size: 0.9rem; }
  .xl-fz-10rem {
    font-size: 1rem; }
  .xl-fz-11rem {
    font-size: 1.1rem; }
  .xl-fz-12rem {
    font-size: 1.2rem; }
  .xl-fz-13rem {
    font-size: 1.3rem; }
  .xl-fz-14rem {
    font-size: 1.4rem; }
  .xl-fz-15rem {
    font-size: 1.5rem; }
  .xl-fz-16rem {
    font-size: 1.6rem; }
  .xl-fz-17rem {
    font-size: 1.7rem; }
  .xl-fz-18rem {
    font-size: 1.8rem; }
  .xl-fz-19rem {
    font-size: 1.9rem; }
  .xl-fz-20rem {
    font-size: 2rem; }
  .xl-fz-21rem {
    font-size: 2.1rem; }
  .xl-fz-22rem {
    font-size: 2.2rem; }
  .xl-fz-23rem {
    font-size: 2.3rem; }
  .xl-fz-24rem {
    font-size: 2.4rem; }
  .xl-fz-25rem {
    font-size: 2.5rem; } }

@media (min-width: 1920px) {
  .xxl-fz-1rem {
    font-size: 0.1rem; }
  .xxl-fz-2rem {
    font-size: 0.2rem; }
  .xxl-fz-3rem {
    font-size: 0.3rem; }
  .xxl-fz-4rem {
    font-size: 0.4rem; }
  .xxl-fz-5rem {
    font-size: 0.5rem; }
  .xxl-fz-6rem {
    font-size: 0.6rem; }
  .xxl-fz-7rem {
    font-size: 0.7rem; }
  .xxl-fz-8rem {
    font-size: 0.8rem; }
  .xxl-fz-9rem {
    font-size: 0.9rem; }
  .xxl-fz-10rem {
    font-size: 1rem; }
  .xxl-fz-11rem {
    font-size: 1.1rem; }
  .xxl-fz-12rem {
    font-size: 1.2rem; }
  .xxl-fz-13rem {
    font-size: 1.3rem; }
  .xxl-fz-14rem {
    font-size: 1.4rem; }
  .xxl-fz-15rem {
    font-size: 1.5rem; }
  .xxl-fz-16rem {
    font-size: 1.6rem; }
  .xxl-fz-17rem {
    font-size: 1.7rem; }
  .xxl-fz-18rem {
    font-size: 1.8rem; }
  .xxl-fz-19rem {
    font-size: 1.9rem; }
  .xxl-fz-20rem {
    font-size: 2rem; }
  .xxl-fz-21rem {
    font-size: 2.1rem; }
  .xxl-fz-22rem {
    font-size: 2.2rem; }
  .xxl-fz-23rem {
    font-size: 2.3rem; }
  .xxl-fz-24rem {
    font-size: 2.4rem; }
  .xxl-fz-25rem {
    font-size: 2.5rem; } }

@media (min-width: 3000px) {
  .xxxl-fz-1rem {
    font-size: 0.1rem; }
  .xxxl-fz-2rem {
    font-size: 0.2rem; }
  .xxxl-fz-3rem {
    font-size: 0.3rem; }
  .xxxl-fz-4rem {
    font-size: 0.4rem; }
  .xxxl-fz-5rem {
    font-size: 0.5rem; }
  .xxxl-fz-6rem {
    font-size: 0.6rem; }
  .xxxl-fz-7rem {
    font-size: 0.7rem; }
  .xxxl-fz-8rem {
    font-size: 0.8rem; }
  .xxxl-fz-9rem {
    font-size: 0.9rem; }
  .xxxl-fz-10rem {
    font-size: 1rem; }
  .xxxl-fz-11rem {
    font-size: 1.1rem; }
  .xxxl-fz-12rem {
    font-size: 1.2rem; }
  .xxxl-fz-13rem {
    font-size: 1.3rem; }
  .xxxl-fz-14rem {
    font-size: 1.4rem; }
  .xxxl-fz-15rem {
    font-size: 1.5rem; }
  .xxxl-fz-16rem {
    font-size: 1.6rem; }
  .xxxl-fz-17rem {
    font-size: 1.7rem; }
  .xxxl-fz-18rem {
    font-size: 1.8rem; }
  .xxxl-fz-19rem {
    font-size: 1.9rem; }
  .xxxl-fz-20rem {
    font-size: 2rem; }
  .xxxl-fz-21rem {
    font-size: 2.1rem; }
  .xxxl-fz-22rem {
    font-size: 2.2rem; }
  .xxxl-fz-23rem {
    font-size: 2.3rem; }
  .xxxl-fz-24rem {
    font-size: 2.4rem; }
  .xxxl-fz-25rem {
    font-size: 2.5rem; } }

.fz-1rem {
  font-size: 0.1rem; }

.fz-2rem {
  font-size: 0.2rem; }

.fz-3rem {
  font-size: 0.3rem; }

.fz-4rem {
  font-size: 0.4rem; }

.fz-5rem {
  font-size: 0.5rem; }

.fz-6rem {
  font-size: 0.6rem; }

.fz-7rem {
  font-size: 0.7rem; }

.fz-8rem {
  font-size: 0.8rem; }

.fz-9rem {
  font-size: 0.9rem; }

.fz-10rem {
  font-size: 1rem; }

.fz-11rem {
  font-size: 1.1rem; }

.fz-12rem {
  font-size: 1.2rem; }

.fz-13rem {
  font-size: 1.3rem; }

.fz-14rem {
  font-size: 1.4rem; }

.fz-15rem {
  font-size: 1.5rem; }

.fz-16rem {
  font-size: 1.6rem; }

.fz-17rem {
  font-size: 1.7rem; }

.fz-18rem {
  font-size: 1.8rem; }

.fz-19rem {
  font-size: 1.9rem; }

.fz-20rem {
  font-size: 2rem; }

.fz-21rem {
  font-size: 2.1rem; }

.fz-22rem {
  font-size: 2.2rem; }

.fz-23rem {
  font-size: 2.3rem; }

.fz-24rem {
  font-size: 2.4rem; }

.fz-25rem {
  font-size: 2.5rem; }

.noir, [data-color="noir"] {
  color: #4d4d4d; }

.bg-noir, [data-bcolor="noir"] {
  background-color: #4d4d4d; }

.br-noir {
  border-color: #4d4d4d; }

.fill-noir {
  fill: #4d4d4d; }

.bg-grad-light-noir {
  background-image: linear-gradient(to bottom, #4d4d4d, #5c5c5c 100%); }

.bg-grad-dark-noir {
  background-image: linear-gradient(to bottom, #4d4d4d, #3e3e3e 100%); }

.noir-op1 {
  color: rgba(77, 77, 77, 0.1); }

.bg-noir-op1 {
  background-color: rgba(77, 77, 77, 0.1); }

.noir-op2 {
  color: rgba(77, 77, 77, 0.2); }

.bg-noir-op2 {
  background-color: rgba(77, 77, 77, 0.2); }

.noir-op3 {
  color: rgba(77, 77, 77, 0.3); }

.bg-noir-op3 {
  background-color: rgba(77, 77, 77, 0.3); }

.noir-op4 {
  color: rgba(77, 77, 77, 0.4); }

.bg-noir-op4 {
  background-color: rgba(77, 77, 77, 0.4); }

.noir-op5 {
  color: rgba(77, 77, 77, 0.5); }

.bg-noir-op5 {
  background-color: rgba(77, 77, 77, 0.5); }

.noir-op6 {
  color: rgba(77, 77, 77, 0.6); }

.bg-noir-op6 {
  background-color: rgba(77, 77, 77, 0.6); }

.noir-op7 {
  color: rgba(77, 77, 77, 0.7); }

.bg-noir-op7 {
  background-color: rgba(77, 77, 77, 0.7); }

.noir-op8 {
  color: rgba(77, 77, 77, 0.8); }

.bg-noir-op8 {
  background-color: rgba(77, 77, 77, 0.8); }

.blanc, [data-color="blanc"] {
  color: #FEFEFE; }

.bg-blanc, [data-bcolor="blanc"] {
  background-color: #FEFEFE; }

.br-blanc {
  border-color: #FEFEFE; }

.fill-blanc {
  fill: #FEFEFE; }

.bg-grad-light-blanc {
  background-image: linear-gradient(to bottom, #FEFEFE, white 100%); }

.bg-grad-dark-blanc {
  background-image: linear-gradient(to bottom, #FEFEFE, #efefef 100%); }

.blanc-op1 {
  color: rgba(254, 254, 254, 0.1); }

.bg-blanc-op1 {
  background-color: rgba(254, 254, 254, 0.1); }

.blanc-op2 {
  color: rgba(254, 254, 254, 0.2); }

.bg-blanc-op2 {
  background-color: rgba(254, 254, 254, 0.2); }

.blanc-op3 {
  color: rgba(254, 254, 254, 0.3); }

.bg-blanc-op3 {
  background-color: rgba(254, 254, 254, 0.3); }

.blanc-op4 {
  color: rgba(254, 254, 254, 0.4); }

.bg-blanc-op4 {
  background-color: rgba(254, 254, 254, 0.4); }

.blanc-op5 {
  color: rgba(254, 254, 254, 0.5); }

.bg-blanc-op5 {
  background-color: rgba(254, 254, 254, 0.5); }

.blanc-op6 {
  color: rgba(254, 254, 254, 0.6); }

.bg-blanc-op6 {
  background-color: rgba(254, 254, 254, 0.6); }

.blanc-op7 {
  color: rgba(254, 254, 254, 0.7); }

.bg-blanc-op7 {
  background-color: rgba(254, 254, 254, 0.7); }

.blanc-op8 {
  color: rgba(254, 254, 254, 0.8); }

.bg-blanc-op8 {
  background-color: rgba(254, 254, 254, 0.8); }

.gris_sombre, [data-color="gris_sombre"] {
  color: #0F0F0F; }

.bg-gris_sombre, [data-bcolor="gris_sombre"] {
  background-color: #0F0F0F; }

.br-gris_sombre {
  border-color: #0F0F0F; }

.fill-gris_sombre {
  fill: #0F0F0F; }

.bg-grad-light-gris_sombre {
  background-image: linear-gradient(to bottom, #0F0F0F, #1e1e1e 100%); }

.bg-grad-dark-gris_sombre {
  background-image: linear-gradient(to bottom, #0F0F0F, black 100%); }

.gris_sombre-op1 {
  color: rgba(15, 15, 15, 0.1); }

.bg-gris_sombre-op1 {
  background-color: rgba(15, 15, 15, 0.1); }

.gris_sombre-op2 {
  color: rgba(15, 15, 15, 0.2); }

.bg-gris_sombre-op2 {
  background-color: rgba(15, 15, 15, 0.2); }

.gris_sombre-op3 {
  color: rgba(15, 15, 15, 0.3); }

.bg-gris_sombre-op3 {
  background-color: rgba(15, 15, 15, 0.3); }

.gris_sombre-op4 {
  color: rgba(15, 15, 15, 0.4); }

.bg-gris_sombre-op4 {
  background-color: rgba(15, 15, 15, 0.4); }

.gris_sombre-op5 {
  color: rgba(15, 15, 15, 0.5); }

.bg-gris_sombre-op5 {
  background-color: rgba(15, 15, 15, 0.5); }

.gris_sombre-op6 {
  color: rgba(15, 15, 15, 0.6); }

.bg-gris_sombre-op6 {
  background-color: rgba(15, 15, 15, 0.6); }

.gris_sombre-op7 {
  color: rgba(15, 15, 15, 0.7); }

.bg-gris_sombre-op7 {
  background-color: rgba(15, 15, 15, 0.7); }

.gris_sombre-op8 {
  color: rgba(15, 15, 15, 0.8); }

.bg-gris_sombre-op8 {
  background-color: rgba(15, 15, 15, 0.8); }

.bleu1, .groupx_block.paragraphe a, .groupx_block.fichier .box a, .groupx_block.lien .box a, [data-color="bleu1"] {
  color: #2ebfff; }

.bg-bleu1, [data-bcolor="bleu1"] {
  background-color: #2ebfff; }

.br-bleu1 {
  border-color: #2ebfff; }

.fill-bleu1 {
  fill: #2ebfff; }

.bg-grad-light-bleu1 {
  background-image: linear-gradient(to bottom, #2ebfff, #4dc8ff 100%); }

.bg-grad-dark-bleu1 {
  background-image: linear-gradient(to bottom, #2ebfff, #0fb6ff 100%); }

.bleu1-op1 {
  color: rgba(46, 191, 255, 0.1); }

.bg-bleu1-op1 {
  background-color: rgba(46, 191, 255, 0.1); }

.bleu1-op2 {
  color: rgba(46, 191, 255, 0.2); }

.bg-bleu1-op2 {
  background-color: rgba(46, 191, 255, 0.2); }

.bleu1-op3 {
  color: rgba(46, 191, 255, 0.3); }

.bg-bleu1-op3 {
  background-color: rgba(46, 191, 255, 0.3); }

.bleu1-op4 {
  color: rgba(46, 191, 255, 0.4); }

.bg-bleu1-op4 {
  background-color: rgba(46, 191, 255, 0.4); }

.bleu1-op5 {
  color: rgba(46, 191, 255, 0.5); }

.bg-bleu1-op5 {
  background-color: rgba(46, 191, 255, 0.5); }

.bleu1-op6 {
  color: rgba(46, 191, 255, 0.6); }

.bg-bleu1-op6 {
  background-color: rgba(46, 191, 255, 0.6); }

.bleu1-op7 {
  color: rgba(46, 191, 255, 0.7); }

.bg-bleu1-op7 {
  background-color: rgba(46, 191, 255, 0.7); }

.bleu1-op8 {
  color: rgba(46, 191, 255, 0.8); }

.bg-bleu1-op8 {
  background-color: rgba(46, 191, 255, 0.8); }

.bleu2, [data-color="bleu2"] {
  color: #07a1e5; }

.bg-bleu2, [data-bcolor="bleu2"] {
  background-color: #07a1e5; }

.br-bleu2 {
  border-color: #07a1e5; }

.fill-bleu2 {
  fill: #07a1e5; }

.bg-grad-light-bleu2 {
  background-image: linear-gradient(to bottom, #07a1e5, #13b2f8 100%); }

.bg-grad-dark-bleu2 {
  background-image: linear-gradient(to bottom, #07a1e5, #068cc7 100%); }

.bleu2-op1 {
  color: rgba(7, 161, 229, 0.1); }

.bg-bleu2-op1 {
  background-color: rgba(7, 161, 229, 0.1); }

.bleu2-op2 {
  color: rgba(7, 161, 229, 0.2); }

.bg-bleu2-op2 {
  background-color: rgba(7, 161, 229, 0.2); }

.bleu2-op3 {
  color: rgba(7, 161, 229, 0.3); }

.bg-bleu2-op3 {
  background-color: rgba(7, 161, 229, 0.3); }

.bleu2-op4 {
  color: rgba(7, 161, 229, 0.4); }

.bg-bleu2-op4 {
  background-color: rgba(7, 161, 229, 0.4); }

.bleu2-op5 {
  color: rgba(7, 161, 229, 0.5); }

.bg-bleu2-op5 {
  background-color: rgba(7, 161, 229, 0.5); }

.bleu2-op6 {
  color: rgba(7, 161, 229, 0.6); }

.bg-bleu2-op6 {
  background-color: rgba(7, 161, 229, 0.6); }

.bleu2-op7 {
  color: rgba(7, 161, 229, 0.7); }

.bg-bleu2-op7 {
  background-color: rgba(7, 161, 229, 0.7); }

.bleu2-op8 {
  color: rgba(7, 161, 229, 0.8); }

.bg-bleu2-op8 {
  background-color: rgba(7, 161, 229, 0.8); }

.rose1, [data-color="rose1"] {
  color: #ff5d7d; }

.bg-rose1, [data-bcolor="rose1"] {
  background-color: #ff5d7d; }

.br-rose1 {
  border-color: #ff5d7d; }

.fill-rose1 {
  fill: #ff5d7d; }

.bg-grad-light-rose1 {
  background-image: linear-gradient(to bottom, #ff5d7d, #ff7c96 100%); }

.bg-grad-dark-rose1 {
  background-image: linear-gradient(to bottom, #ff5d7d, #ff3e64 100%); }

.rose1-op1 {
  color: rgba(255, 93, 125, 0.1); }

.bg-rose1-op1 {
  background-color: rgba(255, 93, 125, 0.1); }

.rose1-op2 {
  color: rgba(255, 93, 125, 0.2); }

.bg-rose1-op2 {
  background-color: rgba(255, 93, 125, 0.2); }

.rose1-op3 {
  color: rgba(255, 93, 125, 0.3); }

.bg-rose1-op3 {
  background-color: rgba(255, 93, 125, 0.3); }

.rose1-op4 {
  color: rgba(255, 93, 125, 0.4); }

.bg-rose1-op4 {
  background-color: rgba(255, 93, 125, 0.4); }

.rose1-op5 {
  color: rgba(255, 93, 125, 0.5); }

.bg-rose1-op5 {
  background-color: rgba(255, 93, 125, 0.5); }

.rose1-op6 {
  color: rgba(255, 93, 125, 0.6); }

.bg-rose1-op6 {
  background-color: rgba(255, 93, 125, 0.6); }

.rose1-op7 {
  color: rgba(255, 93, 125, 0.7); }

.bg-rose1-op7 {
  background-color: rgba(255, 93, 125, 0.7); }

.rose1-op8 {
  color: rgba(255, 93, 125, 0.8); }

.bg-rose1-op8 {
  background-color: rgba(255, 93, 125, 0.8); }

.rose2, [data-color="rose2"] {
  color: #eb3e60; }

.bg-rose2, [data-bcolor="rose2"] {
  background-color: #eb3e60; }

.br-rose2 {
  border-color: #eb3e60; }

.fill-rose2 {
  fill: #eb3e60; }

.bg-grad-light-rose2 {
  background-image: linear-gradient(to bottom, #eb3e60, #ee5a77 100%); }

.bg-grad-dark-rose2 {
  background-image: linear-gradient(to bottom, #eb3e60, #e82249 100%); }

.rose2-op1 {
  color: rgba(235, 62, 96, 0.1); }

.bg-rose2-op1 {
  background-color: rgba(235, 62, 96, 0.1); }

.rose2-op2 {
  color: rgba(235, 62, 96, 0.2); }

.bg-rose2-op2 {
  background-color: rgba(235, 62, 96, 0.2); }

.rose2-op3 {
  color: rgba(235, 62, 96, 0.3); }

.bg-rose2-op3 {
  background-color: rgba(235, 62, 96, 0.3); }

.rose2-op4 {
  color: rgba(235, 62, 96, 0.4); }

.bg-rose2-op4 {
  background-color: rgba(235, 62, 96, 0.4); }

.rose2-op5 {
  color: rgba(235, 62, 96, 0.5); }

.bg-rose2-op5 {
  background-color: rgba(235, 62, 96, 0.5); }

.rose2-op6 {
  color: rgba(235, 62, 96, 0.6); }

.bg-rose2-op6 {
  background-color: rgba(235, 62, 96, 0.6); }

.rose2-op7 {
  color: rgba(235, 62, 96, 0.7); }

.bg-rose2-op7 {
  background-color: rgba(235, 62, 96, 0.7); }

.rose2-op8 {
  color: rgba(235, 62, 96, 0.8); }

.bg-rose2-op8 {
  background-color: rgba(235, 62, 96, 0.8); }

.jaune1, [data-color="jaune1"] {
  color: #ffc144; }

.bg-jaune1, [data-bcolor="jaune1"] {
  background-color: #ffc144; }

.br-jaune1 {
  border-color: #ffc144; }

.fill-jaune1 {
  fill: #ffc144; }

.bg-grad-light-jaune1 {
  background-image: linear-gradient(to bottom, #ffc144, #ffcb63 100%); }

.bg-grad-dark-jaune1 {
  background-image: linear-gradient(to bottom, #ffc144, #ffb725 100%); }

.jaune1-op1 {
  color: rgba(255, 193, 68, 0.1); }

.bg-jaune1-op1 {
  background-color: rgba(255, 193, 68, 0.1); }

.jaune1-op2 {
  color: rgba(255, 193, 68, 0.2); }

.bg-jaune1-op2 {
  background-color: rgba(255, 193, 68, 0.2); }

.jaune1-op3 {
  color: rgba(255, 193, 68, 0.3); }

.bg-jaune1-op3 {
  background-color: rgba(255, 193, 68, 0.3); }

.jaune1-op4 {
  color: rgba(255, 193, 68, 0.4); }

.bg-jaune1-op4 {
  background-color: rgba(255, 193, 68, 0.4); }

.jaune1-op5 {
  color: rgba(255, 193, 68, 0.5); }

.bg-jaune1-op5 {
  background-color: rgba(255, 193, 68, 0.5); }

.jaune1-op6 {
  color: rgba(255, 193, 68, 0.6); }

.bg-jaune1-op6 {
  background-color: rgba(255, 193, 68, 0.6); }

.jaune1-op7 {
  color: rgba(255, 193, 68, 0.7); }

.bg-jaune1-op7 {
  background-color: rgba(255, 193, 68, 0.7); }

.jaune1-op8 {
  color: rgba(255, 193, 68, 0.8); }

.bg-jaune1-op8 {
  background-color: rgba(255, 193, 68, 0.8); }

.jaune2, [data-color="jaune2"] {
  color: #e59d0a; }

.bg-jaune2, [data-bcolor="jaune2"] {
  background-color: #e59d0a; }

.br-jaune2 {
  border-color: #e59d0a; }

.fill-jaune2 {
  fill: #e59d0a; }

.bg-grad-light-jaune2 {
  background-image: linear-gradient(to bottom, #e59d0a, #f5ad19 100%); }

.bg-grad-dark-jaune2 {
  background-image: linear-gradient(to bottom, #e59d0a, #c88909 100%); }

.jaune2-op1 {
  color: rgba(229, 157, 10, 0.1); }

.bg-jaune2-op1 {
  background-color: rgba(229, 157, 10, 0.1); }

.jaune2-op2 {
  color: rgba(229, 157, 10, 0.2); }

.bg-jaune2-op2 {
  background-color: rgba(229, 157, 10, 0.2); }

.jaune2-op3 {
  color: rgba(229, 157, 10, 0.3); }

.bg-jaune2-op3 {
  background-color: rgba(229, 157, 10, 0.3); }

.jaune2-op4 {
  color: rgba(229, 157, 10, 0.4); }

.bg-jaune2-op4 {
  background-color: rgba(229, 157, 10, 0.4); }

.jaune2-op5 {
  color: rgba(229, 157, 10, 0.5); }

.bg-jaune2-op5 {
  background-color: rgba(229, 157, 10, 0.5); }

.jaune2-op6 {
  color: rgba(229, 157, 10, 0.6); }

.bg-jaune2-op6 {
  background-color: rgba(229, 157, 10, 0.6); }

.jaune2-op7 {
  color: rgba(229, 157, 10, 0.7); }

.bg-jaune2-op7 {
  background-color: rgba(229, 157, 10, 0.7); }

.jaune2-op8 {
  color: rgba(229, 157, 10, 0.8); }

.bg-jaune2-op8 {
  background-color: rgba(229, 157, 10, 0.8); }

.vert1, [data-color="vert1"] {
  color: #559605; }

.bg-vert1, [data-bcolor="vert1"] {
  background-color: #559605; }

.br-vert1 {
  border-color: #559605; }

.fill-vert1 {
  fill: #559605; }

.bg-grad-light-vert1 {
  background-image: linear-gradient(to bottom, #559605, #66b406 100%); }

.bg-grad-dark-vert1 {
  background-image: linear-gradient(to bottom, #559605, #447804 100%); }

.vert1-op1 {
  color: rgba(85, 150, 5, 0.1); }

.bg-vert1-op1 {
  background-color: rgba(85, 150, 5, 0.1); }

.vert1-op2 {
  color: rgba(85, 150, 5, 0.2); }

.bg-vert1-op2 {
  background-color: rgba(85, 150, 5, 0.2); }

.vert1-op3 {
  color: rgba(85, 150, 5, 0.3); }

.bg-vert1-op3 {
  background-color: rgba(85, 150, 5, 0.3); }

.vert1-op4 {
  color: rgba(85, 150, 5, 0.4); }

.bg-vert1-op4 {
  background-color: rgba(85, 150, 5, 0.4); }

.vert1-op5 {
  color: rgba(85, 150, 5, 0.5); }

.bg-vert1-op5 {
  background-color: rgba(85, 150, 5, 0.5); }

.vert1-op6 {
  color: rgba(85, 150, 5, 0.6); }

.bg-vert1-op6 {
  background-color: rgba(85, 150, 5, 0.6); }

.vert1-op7 {
  color: rgba(85, 150, 5, 0.7); }

.bg-vert1-op7 {
  background-color: rgba(85, 150, 5, 0.7); }

.vert1-op8 {
  color: rgba(85, 150, 5, 0.8); }

.bg-vert1-op8 {
  background-color: rgba(85, 150, 5, 0.8); }

.vert2, [data-color="vert2"] {
  color: #457c01; }

.bg-vert2, [data-bcolor="vert2"] {
  background-color: #457c01; }

.br-vert2 {
  border-color: #457c01; }

.fill-vert2 {
  fill: #457c01; }

.bg-grad-light-vert2 {
  background-image: linear-gradient(to bottom, #457c01, #569a01 100%); }

.bg-grad-dark-vert2 {
  background-image: linear-gradient(to bottom, #457c01, #345e01 100%); }

.vert2-op1 {
  color: rgba(69, 124, 1, 0.1); }

.bg-vert2-op1 {
  background-color: rgba(69, 124, 1, 0.1); }

.vert2-op2 {
  color: rgba(69, 124, 1, 0.2); }

.bg-vert2-op2 {
  background-color: rgba(69, 124, 1, 0.2); }

.vert2-op3 {
  color: rgba(69, 124, 1, 0.3); }

.bg-vert2-op3 {
  background-color: rgba(69, 124, 1, 0.3); }

.vert2-op4 {
  color: rgba(69, 124, 1, 0.4); }

.bg-vert2-op4 {
  background-color: rgba(69, 124, 1, 0.4); }

.vert2-op5 {
  color: rgba(69, 124, 1, 0.5); }

.bg-vert2-op5 {
  background-color: rgba(69, 124, 1, 0.5); }

.vert2-op6 {
  color: rgba(69, 124, 1, 0.6); }

.bg-vert2-op6 {
  background-color: rgba(69, 124, 1, 0.6); }

.vert2-op7 {
  color: rgba(69, 124, 1, 0.7); }

.bg-vert2-op7 {
  background-color: rgba(69, 124, 1, 0.7); }

.vert2-op8 {
  color: rgba(69, 124, 1, 0.8); }

.bg-vert2-op8 {
  background-color: rgba(69, 124, 1, 0.8); }

.class_nat_artistique, [data-color="class_nat_artistique"] {
  color: #ff5d7d; }

.bg-class_nat_artistique, [data-bcolor="class_nat_artistique"] {
  background-color: #ff5d7d; }

.br-class_nat_artistique {
  border-color: #ff5d7d; }

.fill-class_nat_artistique {
  fill: #ff5d7d; }

.bg-grad-light-class_nat_artistique {
  background-image: linear-gradient(to bottom, #ff5d7d, #ff7c96 100%); }

.bg-grad-dark-class_nat_artistique {
  background-image: linear-gradient(to bottom, #ff5d7d, #ff3e64 100%); }

.class_nat_artistique-op1 {
  color: rgba(255, 93, 125, 0.1); }

.bg-class_nat_artistique-op1 {
  background-color: rgba(255, 93, 125, 0.1); }

.class_nat_artistique-op2 {
  color: rgba(255, 93, 125, 0.2); }

.bg-class_nat_artistique-op2 {
  background-color: rgba(255, 93, 125, 0.2); }

.class_nat_artistique-op3 {
  color: rgba(255, 93, 125, 0.3); }

.bg-class_nat_artistique-op3 {
  background-color: rgba(255, 93, 125, 0.3); }

.class_nat_artistique-op4 {
  color: rgba(255, 93, 125, 0.4); }

.bg-class_nat_artistique-op4 {
  background-color: rgba(255, 93, 125, 0.4); }

.class_nat_artistique-op5 {
  color: rgba(255, 93, 125, 0.5); }

.bg-class_nat_artistique-op5 {
  background-color: rgba(255, 93, 125, 0.5); }

.class_nat_artistique-op6 {
  color: rgba(255, 93, 125, 0.6); }

.bg-class_nat_artistique-op6 {
  background-color: rgba(255, 93, 125, 0.6); }

.class_nat_artistique-op7 {
  color: rgba(255, 93, 125, 0.7); }

.bg-class_nat_artistique-op7 {
  background-color: rgba(255, 93, 125, 0.7); }

.class_nat_artistique-op8 {
  color: rgba(255, 93, 125, 0.8); }

.bg-class_nat_artistique-op8 {
  background-color: rgba(255, 93, 125, 0.8); }

.class_nat_course, [data-color="class_nat_course"] {
  color: #559605; }

.bg-class_nat_course, [data-bcolor="class_nat_course"] {
  background-color: #559605; }

.br-class_nat_course {
  border-color: #559605; }

.fill-class_nat_course {
  fill: #559605; }

.bg-grad-light-class_nat_course {
  background-image: linear-gradient(to bottom, #559605, #66b406 100%); }

.bg-grad-dark-class_nat_course {
  background-image: linear-gradient(to bottom, #559605, #447804 100%); }

.class_nat_course-op1 {
  color: rgba(85, 150, 5, 0.1); }

.bg-class_nat_course-op1 {
  background-color: rgba(85, 150, 5, 0.1); }

.class_nat_course-op2 {
  color: rgba(85, 150, 5, 0.2); }

.bg-class_nat_course-op2 {
  background-color: rgba(85, 150, 5, 0.2); }

.class_nat_course-op3 {
  color: rgba(85, 150, 5, 0.3); }

.bg-class_nat_course-op3 {
  background-color: rgba(85, 150, 5, 0.3); }

.class_nat_course-op4 {
  color: rgba(85, 150, 5, 0.4); }

.bg-class_nat_course-op4 {
  background-color: rgba(85, 150, 5, 0.4); }

.class_nat_course-op5 {
  color: rgba(85, 150, 5, 0.5); }

.bg-class_nat_course-op5 {
  background-color: rgba(85, 150, 5, 0.5); }

.class_nat_course-op6 {
  color: rgba(85, 150, 5, 0.6); }

.bg-class_nat_course-op6 {
  background-color: rgba(85, 150, 5, 0.6); }

.class_nat_course-op7 {
  color: rgba(85, 150, 5, 0.7); }

.bg-class_nat_course-op7 {
  background-color: rgba(85, 150, 5, 0.7); }

.class_nat_course-op8 {
  color: rgba(85, 150, 5, 0.8); }

.bg-class_nat_course-op8 {
  background-color: rgba(85, 150, 5, 0.8); }

.class_loisir_sante, [data-color="class_loisir_sante"] {
  color: #ffc144; }

.bg-class_loisir_sante, [data-bcolor="class_loisir_sante"] {
  background-color: #ffc144; }

.br-class_loisir_sante {
  border-color: #ffc144; }

.fill-class_loisir_sante {
  fill: #ffc144; }

.bg-grad-light-class_loisir_sante {
  background-image: linear-gradient(to bottom, #ffc144, #ffcb63 100%); }

.bg-grad-dark-class_loisir_sante {
  background-image: linear-gradient(to bottom, #ffc144, #ffb725 100%); }

.class_loisir_sante-op1 {
  color: rgba(255, 193, 68, 0.1); }

.bg-class_loisir_sante-op1 {
  background-color: rgba(255, 193, 68, 0.1); }

.class_loisir_sante-op2 {
  color: rgba(255, 193, 68, 0.2); }

.bg-class_loisir_sante-op2 {
  background-color: rgba(255, 193, 68, 0.2); }

.class_loisir_sante-op3 {
  color: rgba(255, 193, 68, 0.3); }

.bg-class_loisir_sante-op3 {
  background-color: rgba(255, 193, 68, 0.3); }

.class_loisir_sante-op4 {
  color: rgba(255, 193, 68, 0.4); }

.bg-class_loisir_sante-op4 {
  background-color: rgba(255, 193, 68, 0.4); }

.class_loisir_sante-op5 {
  color: rgba(255, 193, 68, 0.5); }

.bg-class_loisir_sante-op5 {
  background-color: rgba(255, 193, 68, 0.5); }

.class_loisir_sante-op6 {
  color: rgba(255, 193, 68, 0.6); }

.bg-class_loisir_sante-op6 {
  background-color: rgba(255, 193, 68, 0.6); }

.class_loisir_sante-op7 {
  color: rgba(255, 193, 68, 0.7); }

.bg-class_loisir_sante-op7 {
  background-color: rgba(255, 193, 68, 0.7); }

.class_loisir_sante-op8 {
  color: rgba(255, 193, 68, 0.8); }

.bg-class_loisir_sante-op8 {
  background-color: rgba(255, 193, 68, 0.8); }

.class_sujet_divers, [data-color="class_sujet_divers"] {
  color: #2ebfff; }

.bg-class_sujet_divers, [data-bcolor="class_sujet_divers"] {
  background-color: #2ebfff; }

.br-class_sujet_divers {
  border-color: #2ebfff; }

.fill-class_sujet_divers {
  fill: #2ebfff; }

.bg-grad-light-class_sujet_divers {
  background-image: linear-gradient(to bottom, #2ebfff, #4dc8ff 100%); }

.bg-grad-dark-class_sujet_divers {
  background-image: linear-gradient(to bottom, #2ebfff, #0fb6ff 100%); }

.class_sujet_divers-op1 {
  color: rgba(46, 191, 255, 0.1); }

.bg-class_sujet_divers-op1 {
  background-color: rgba(46, 191, 255, 0.1); }

.class_sujet_divers-op2 {
  color: rgba(46, 191, 255, 0.2); }

.bg-class_sujet_divers-op2 {
  background-color: rgba(46, 191, 255, 0.2); }

.class_sujet_divers-op3 {
  color: rgba(46, 191, 255, 0.3); }

.bg-class_sujet_divers-op3 {
  background-color: rgba(46, 191, 255, 0.3); }

.class_sujet_divers-op4 {
  color: rgba(46, 191, 255, 0.4); }

.bg-class_sujet_divers-op4 {
  background-color: rgba(46, 191, 255, 0.4); }

.class_sujet_divers-op5 {
  color: rgba(46, 191, 255, 0.5); }

.bg-class_sujet_divers-op5 {
  background-color: rgba(46, 191, 255, 0.5); }

.class_sujet_divers-op6 {
  color: rgba(46, 191, 255, 0.6); }

.bg-class_sujet_divers-op6 {
  background-color: rgba(46, 191, 255, 0.6); }

.class_sujet_divers-op7 {
  color: rgba(46, 191, 255, 0.7); }

.bg-class_sujet_divers-op7 {
  background-color: rgba(46, 191, 255, 0.7); }

.class_sujet_divers-op8 {
  color: rgba(46, 191, 255, 0.8); }

.bg-class_sujet_divers-op8 {
  background-color: rgba(46, 191, 255, 0.8); }

.class_evenement, [data-color="class_evenement"] {
  color: #07a1e5; }

.bg-class_evenement, [data-bcolor="class_evenement"] {
  background-color: #07a1e5; }

.br-class_evenement {
  border-color: #07a1e5; }

.fill-class_evenement {
  fill: #07a1e5; }

.bg-grad-light-class_evenement {
  background-image: linear-gradient(to bottom, #07a1e5, #13b2f8 100%); }

.bg-grad-dark-class_evenement {
  background-image: linear-gradient(to bottom, #07a1e5, #068cc7 100%); }

.class_evenement-op1 {
  color: rgba(7, 161, 229, 0.1); }

.bg-class_evenement-op1 {
  background-color: rgba(7, 161, 229, 0.1); }

.class_evenement-op2 {
  color: rgba(7, 161, 229, 0.2); }

.bg-class_evenement-op2 {
  background-color: rgba(7, 161, 229, 0.2); }

.class_evenement-op3 {
  color: rgba(7, 161, 229, 0.3); }

.bg-class_evenement-op3 {
  background-color: rgba(7, 161, 229, 0.3); }

.class_evenement-op4 {
  color: rgba(7, 161, 229, 0.4); }

.bg-class_evenement-op4 {
  background-color: rgba(7, 161, 229, 0.4); }

.class_evenement-op5 {
  color: rgba(7, 161, 229, 0.5); }

.bg-class_evenement-op5 {
  background-color: rgba(7, 161, 229, 0.5); }

.class_evenement-op6 {
  color: rgba(7, 161, 229, 0.6); }

.bg-class_evenement-op6 {
  background-color: rgba(7, 161, 229, 0.6); }

.class_evenement-op7 {
  color: rgba(7, 161, 229, 0.7); }

.bg-class_evenement-op7 {
  background-color: rgba(7, 161, 229, 0.7); }

.class_evenement-op8 {
  color: rgba(7, 161, 229, 0.8); }

.bg-class_evenement-op8 {
  background-color: rgba(7, 161, 229, 0.8); }

.opacity-0 {
  opacity: 0; }

.opacity-10 {
  opacity: 0.1; }

.opacity-20 {
  opacity: 0.2; }

.opacity-30 {
  opacity: 0.3; }

.opacity-40 {
  opacity: 0.4; }

.opacity-50 {
  opacity: 0.5; }

.opacity-60 {
  opacity: 0.6; }

.opacity-70 {
  opacity: 0.7; }

.opacity-80 {
  opacity: 0.8; }

.opacity-90 {
  opacity: 0.9; }

.opacity-100 {
  opacity: 1; }

.top-0-rem {
  top: 0rem; }

.right-0-rem {
  right: 0rem; }

.bottom-0-rem {
  bottom: 0rem; }

.left-0-rem {
  left: 0rem; }

.top-0-p {
  top: 0%; }

.right-0-p {
  right: 0%; }

.bottom-0-p {
  bottom: 0%; }

.left-0-p {
  left: 0%; }

.top-1-rem {
  top: 1rem; }

.right-1-rem {
  right: 1rem; }

.bottom-1-rem {
  bottom: 1rem; }

.left-1-rem {
  left: 1rem; }

.top-1-p {
  top: 10%; }

.right-1-p {
  right: 10%; }

.bottom-1-p {
  bottom: 10%; }

.left-1-p {
  left: 10%; }

.top-2-rem {
  top: 2rem; }

.right-2-rem {
  right: 2rem; }

.bottom-2-rem {
  bottom: 2rem; }

.left-2-rem {
  left: 2rem; }

.top-2-p {
  top: 20%; }

.right-2-p {
  right: 20%; }

.bottom-2-p {
  bottom: 20%; }

.left-2-p {
  left: 20%; }

.top-3-rem {
  top: 3rem; }

.right-3-rem {
  right: 3rem; }

.bottom-3-rem {
  bottom: 3rem; }

.left-3-rem {
  left: 3rem; }

.top-3-p {
  top: 30%; }

.right-3-p {
  right: 30%; }

.bottom-3-p {
  bottom: 30%; }

.left-3-p {
  left: 30%; }

.top-4-rem {
  top: 4rem; }

.right-4-rem {
  right: 4rem; }

.bottom-4-rem {
  bottom: 4rem; }

.left-4-rem {
  left: 4rem; }

.top-4-p {
  top: 40%; }

.right-4-p {
  right: 40%; }

.bottom-4-p {
  bottom: 40%; }

.left-4-p {
  left: 40%; }

.top-5-rem {
  top: 5rem; }

.right-5-rem {
  right: 5rem; }

.bottom-5-rem {
  bottom: 5rem; }

.left-5-rem {
  left: 5rem; }

.top-5-p {
  top: 50%; }

.right-5-p {
  right: 50%; }

.bottom-5-p {
  bottom: 50%; }

.left-5-p {
  left: 50%; }

.top-6-rem {
  top: 6rem; }

.right-6-rem {
  right: 6rem; }

.bottom-6-rem {
  bottom: 6rem; }

.left-6-rem {
  left: 6rem; }

.top-6-p {
  top: 60%; }

.right-6-p {
  right: 60%; }

.bottom-6-p {
  bottom: 60%; }

.left-6-p {
  left: 60%; }

.top-7-rem {
  top: 7rem; }

.right-7-rem {
  right: 7rem; }

.bottom-7-rem {
  bottom: 7rem; }

.left-7-rem {
  left: 7rem; }

.top-7-p {
  top: 70%; }

.right-7-p {
  right: 70%; }

.bottom-7-p {
  bottom: 70%; }

.left-7-p {
  left: 70%; }

.top-8-rem {
  top: 8rem; }

.right-8-rem {
  right: 8rem; }

.bottom-8-rem {
  bottom: 8rem; }

.left-8-rem {
  left: 8rem; }

.top-8-p {
  top: 80%; }

.right-8-p {
  right: 80%; }

.bottom-8-p {
  bottom: 80%; }

.left-8-p {
  left: 80%; }

.top-9-rem {
  top: 9rem; }

.right-9-rem {
  right: 9rem; }

.bottom-9-rem {
  bottom: 9rem; }

.left-9-rem {
  left: 9rem; }

.top-9-p {
  top: 90%; }

.right-9-p {
  right: 90%; }

.bottom-9-p {
  bottom: 90%; }

.left-9-p {
  left: 90%; }

.top-10-rem {
  top: 10rem; }

.right-10-rem {
  right: 10rem; }

.bottom-10-rem {
  bottom: 10rem; }

.left-10-rem {
  left: 10rem; }

.top-10-p {
  top: 100%; }

.right-10-p {
  right: 100%; }

.bottom-10-p {
  bottom: 100%; }

.left-10-p {
  left: 100%; }

.xs_down_hide, .xs_down_hide.df {
  display: none; }
  @media (min-width: 0px) {
    .xs_down_hide, .xs_down_hide.df {
      display: block; }
      .xs_down_hide.xs-df, .xs_down_hide.df, .xs_down_hide.df.xs-df, .xs_down_hide.df.df {
        display: flex; } }

.sm_down_hide, .sm_down_hide.df {
  display: none; }
  @media (min-width: 544px) {
    .sm_down_hide, .sm_down_hide.df {
      display: block; }
      .sm_down_hide.sm-df, .sm_down_hide.df, .sm_down_hide.df.sm-df, .sm_down_hide.df.df {
        display: flex; } }

.md_down_hide, .md_down_hide.df {
  display: none; }
  @media (min-width: 768px) {
    .md_down_hide, .md_down_hide.df {
      display: block; }
      .md_down_hide.md-df, .md_down_hide.df, .md_down_hide.df.md-df, .md_down_hide.df.df {
        display: flex; } }

.lg_down_hide, .lg_down_hide.df {
  display: none; }
  @media (min-width: 992px) {
    .lg_down_hide, .lg_down_hide.df {
      display: block; }
      .lg_down_hide.lg-df, .lg_down_hide.df, .lg_down_hide.df.lg-df, .lg_down_hide.df.df {
        display: flex; } }

.xl_down_hide, .xl_down_hide.df {
  display: none; }
  @media (min-width: 1280px) {
    .xl_down_hide, .xl_down_hide.df {
      display: block; }
      .xl_down_hide.xl-df, .xl_down_hide.df, .xl_down_hide.df.xl-df, .xl_down_hide.df.df {
        display: flex; } }

.xxl_down_hide, .xxl_down_hide.df {
  display: none; }
  @media (min-width: 1920px) {
    .xxl_down_hide, .xxl_down_hide.df {
      display: block; }
      .xxl_down_hide.xxl-df, .xxl_down_hide.df, .xxl_down_hide.df.xxl-df, .xxl_down_hide.df.df {
        display: flex; } }

.xs_up_hide, .xs_up_hide.df {
  display: block; }
  .xs_up_hide.xs-df, .xs_up_hide.df, .xs_up_hide.df.xs-df, .xs_up_hide.df.df {
    display: flex; }
  @media (max-width: 0px) {
    .xs_up_hide, .xs_up_hide.df {
      display: none; } }

.sm_up_hide, .sm_up_hide.df {
  display: block; }
  .sm_up_hide.sm-df, .sm_up_hide.df, .sm_up_hide.df.sm-df, .sm_up_hide.df.df {
    display: flex; }
  @media (max-width: 544px) {
    .sm_up_hide, .sm_up_hide.df {
      display: none; } }

.md_up_hide, .md_up_hide.df {
  display: block; }
  .md_up_hide.md-df, .md_up_hide.df, .md_up_hide.df.md-df, .md_up_hide.df.df {
    display: flex; }
  @media (max-width: 768px) {
    .md_up_hide, .md_up_hide.df {
      display: none; } }

.lg_up_hide, .lg_up_hide.df {
  display: block; }
  .lg_up_hide.lg-df, .lg_up_hide.df, .lg_up_hide.df.lg-df, .lg_up_hide.df.df {
    display: flex; }
  @media (max-width: 992px) {
    .lg_up_hide, .lg_up_hide.df {
      display: none; } }

.xl_up_hide, .xl_up_hide.df {
  display: block; }
  .xl_up_hide.xl-df, .xl_up_hide.df, .xl_up_hide.df.xl-df, .xl_up_hide.df.df {
    display: flex; }
  @media (max-width: 1280px) {
    .xl_up_hide, .xl_up_hide.df {
      display: none; } }

.xxl_up_hide, .xxl_up_hide.df {
  display: block; }
  .xxl_up_hide.xxl-df, .xxl_up_hide.df, .xxl_up_hide.df.xxl-df, .xxl_up_hide.df.df {
    display: flex; }
  @media (max-width: 1920px) {
    .xxl_up_hide, .xxl_up_hide.df {
      display: none; } }

.df {
  display: flex; }

.fdc {
  flex-direction: column; }

.fdr {
  flex-direction: row; }

.fdcr {
  flex-direction: column-reverse; }

.fdrr {
  flex-direction: row-reverse; }

.jcc {
  justify-content: center; }

.jcfs {
  justify-content: flex-start; }

.jcfe {
  justify-content: flex-end; }

.jcsa {
  justify-content: space-around; }

.jcsb {
  justify-content: space-between; }

.aifs {
  align-items: flex-start; }

.aife {
  align-items: flex-end; }

.aic {
  align-items: center; }

.ais {
  align-items: stretch; }

.fwnw {
  flex-wrap: nowrap; }

.fww {
  flex-wrap: wrap; }

.mra {
  margin-right: auto; }

.mla {
  margin-left: auto; }

.mta {
  margin-top: auto; }

.mba {
  margin-bottom: auto; }

.fg1 {
  flex-grow: 1; }

.fgi {
  flex-grow: initial; }

@media (min-width: 0px) {
  .xs-df {
    display: flex; }
  .xs-db {
    display: block; }
  .xs-fdc {
    flex-direction: column; }
  .xs-fdr {
    flex-direction: row; }
  .xs-fdcr {
    flex-direction: column-reverse; }
  .xs-fdrr {
    flex-direction: row-reverse; }
  .xs-jcc {
    justify-content: center; }
  .xs-jcfs {
    justify-content: flex-start; }
  .xs-jcfe {
    justify-content: flex-end; }
  .xs-jcsa {
    justify-content: space-around; }
  .xs-jcsb {
    justify-content: space-between; }
  .xs-aifs {
    align-items: flex-start; }
  .xs-aife {
    align-items: flex-end; }
  .xs-aic {
    align-items: center; }
  .xs-ais {
    align-items: stretch; }
  .xs-fwnw {
    flex-wrap: nowrap; }
  .xs-fww {
    flex-wrap: wrap; }
  .xs-mra {
    margin-right: auto; }
  .xs-mla {
    margin-left: auto; }
  .xs-mta {
    margin-top: auto; }
  .xs-mba {
    margin-bottom: auto; }
  .xs-fg1 {
    flex-grow: 1; }
  .xs-fgi {
    flex-grow: initial; } }

@media (min-width: 544px) {
  .sm-df {
    display: flex; }
  .sm-db {
    display: block; }
  .sm-fdc {
    flex-direction: column; }
  .sm-fdr {
    flex-direction: row; }
  .sm-fdcr {
    flex-direction: column-reverse; }
  .sm-fdrr {
    flex-direction: row-reverse; }
  .sm-jcc {
    justify-content: center; }
  .sm-jcfs {
    justify-content: flex-start; }
  .sm-jcfe {
    justify-content: flex-end; }
  .sm-jcsa {
    justify-content: space-around; }
  .sm-jcsb {
    justify-content: space-between; }
  .sm-aifs {
    align-items: flex-start; }
  .sm-aife {
    align-items: flex-end; }
  .sm-aic {
    align-items: center; }
  .sm-ais {
    align-items: stretch; }
  .sm-fwnw {
    flex-wrap: nowrap; }
  .sm-fww {
    flex-wrap: wrap; }
  .sm-mra {
    margin-right: auto; }
  .sm-mla {
    margin-left: auto; }
  .sm-mta {
    margin-top: auto; }
  .sm-mba {
    margin-bottom: auto; }
  .sm-fg1 {
    flex-grow: 1; }
  .sm-fgi {
    flex-grow: initial; } }

@media (min-width: 768px) {
  .md-df {
    display: flex; }
  .md-db {
    display: block; }
  .md-fdc {
    flex-direction: column; }
  .md-fdr {
    flex-direction: row; }
  .md-fdcr {
    flex-direction: column-reverse; }
  .md-fdrr {
    flex-direction: row-reverse; }
  .md-jcc {
    justify-content: center; }
  .md-jcfs {
    justify-content: flex-start; }
  .md-jcfe {
    justify-content: flex-end; }
  .md-jcsa {
    justify-content: space-around; }
  .md-jcsb {
    justify-content: space-between; }
  .md-aifs {
    align-items: flex-start; }
  .md-aife {
    align-items: flex-end; }
  .md-aic {
    align-items: center; }
  .md-ais {
    align-items: stretch; }
  .md-fwnw {
    flex-wrap: nowrap; }
  .md-fww {
    flex-wrap: wrap; }
  .md-mra {
    margin-right: auto; }
  .md-mla {
    margin-left: auto; }
  .md-mta {
    margin-top: auto; }
  .md-mba {
    margin-bottom: auto; }
  .md-fg1 {
    flex-grow: 1; }
  .md-fgi {
    flex-grow: initial; } }

@media (min-width: 992px) {
  .lg-df {
    display: flex; }
  .lg-db {
    display: block; }
  .lg-fdc {
    flex-direction: column; }
  .lg-fdr {
    flex-direction: row; }
  .lg-fdcr {
    flex-direction: column-reverse; }
  .lg-fdrr {
    flex-direction: row-reverse; }
  .lg-jcc {
    justify-content: center; }
  .lg-jcfs {
    justify-content: flex-start; }
  .lg-jcfe {
    justify-content: flex-end; }
  .lg-jcsa {
    justify-content: space-around; }
  .lg-jcsb {
    justify-content: space-between; }
  .lg-aifs {
    align-items: flex-start; }
  .lg-aife {
    align-items: flex-end; }
  .lg-aic {
    align-items: center; }
  .lg-ais {
    align-items: stretch; }
  .lg-fwnw {
    flex-wrap: nowrap; }
  .lg-fww {
    flex-wrap: wrap; }
  .lg-mra {
    margin-right: auto; }
  .lg-mla {
    margin-left: auto; }
  .lg-mta {
    margin-top: auto; }
  .lg-mba {
    margin-bottom: auto; }
  .lg-fg1 {
    flex-grow: 1; }
  .lg-fgi {
    flex-grow: initial; } }

@media (min-width: 1280px) {
  .xl-df {
    display: flex; }
  .xl-db {
    display: block; }
  .xl-fdc {
    flex-direction: column; }
  .xl-fdr {
    flex-direction: row; }
  .xl-fdcr {
    flex-direction: column-reverse; }
  .xl-fdrr {
    flex-direction: row-reverse; }
  .xl-jcc {
    justify-content: center; }
  .xl-jcfs {
    justify-content: flex-start; }
  .xl-jcfe {
    justify-content: flex-end; }
  .xl-jcsa {
    justify-content: space-around; }
  .xl-jcsb {
    justify-content: space-between; }
  .xl-aifs {
    align-items: flex-start; }
  .xl-aife {
    align-items: flex-end; }
  .xl-aic {
    align-items: center; }
  .xl-ais {
    align-items: stretch; }
  .xl-fwnw {
    flex-wrap: nowrap; }
  .xl-fww {
    flex-wrap: wrap; }
  .xl-mra {
    margin-right: auto; }
  .xl-mla {
    margin-left: auto; }
  .xl-mta {
    margin-top: auto; }
  .xl-mba {
    margin-bottom: auto; }
  .xl-fg1 {
    flex-grow: 1; }
  .xl-fgi {
    flex-grow: initial; } }

@media (min-width: 1280px) {
  .xxl-df {
    display: flex; }
  .xxl-db {
    display: block; }
  .xxl-fdc {
    flex-direction: column; }
  .xxl-fdr {
    flex-direction: row; }
  .xxl-fdcr {
    flex-direction: column-reverse; }
  .xxl-fdrr {
    flex-direction: row-reverse; }
  .xxl-jcc {
    justify-content: center; }
  .xxl-jcfs {
    justify-content: flex-start; }
  .xxl-jcfe {
    justify-content: flex-end; }
  .xxl-jcsa {
    justify-content: space-around; }
  .xxl-jcsb {
    justify-content: space-between; }
  .xxl-aifs {
    align-items: flex-start; }
  .xxl-aife {
    align-items: flex-end; }
  .xxl-aic {
    align-items: center; }
  .xxl-ais {
    align-items: stretch; }
  .xxl-fwnw {
    flex-wrap: nowrap; }
  .xxl-fww {
    flex-wrap: wrap; }
  .xxl-mra {
    margin-right: auto; }
  .xxl-mla {
    margin-left: auto; }
  .xxl-mta {
    margin-top: auto; }
  .xxl-mba {
    margin-bottom: auto; }
  .xxl-fg1 {
    flex-grow: 1; }
  .xxl-fgi {
    flex-grow: initial; } }

@media (min-width: 1280px) {
  .xxxl-df {
    display: flex; }
  .xxxl-db {
    display: block; }
  .xxxl-fdc {
    flex-direction: column; }
  .xxxl-fdr {
    flex-direction: row; }
  .xxxl-fdcr {
    flex-direction: column-reverse; }
  .xxxl-fdrr {
    flex-direction: row-reverse; }
  .xxxl-jcc {
    justify-content: center; }
  .xxxl-jcfs {
    justify-content: flex-start; }
  .xxxl-jcfe {
    justify-content: flex-end; }
  .xxxl-jcsa {
    justify-content: space-around; }
  .xxxl-jcsb {
    justify-content: space-between; }
  .xxxl-aifs {
    align-items: flex-start; }
  .xxxl-aife {
    align-items: flex-end; }
  .xxxl-aic {
    align-items: center; }
  .xxxl-ais {
    align-items: stretch; }
  .xxxl-fwnw {
    flex-wrap: nowrap; }
  .xxxl-fww {
    flex-wrap: wrap; }
  .xxxl-mra {
    margin-right: auto; }
  .xxxl-mla {
    margin-left: auto; }
  .xxxl-mta {
    margin-top: auto; }
  .xxxl-mba {
    margin-bottom: auto; }
  .xxxl-fg1 {
    flex-grow: 1; }
  .xxxl-fgi {
    flex-grow: initial; } }

.overlay {
  position: absolute;
  top: -4rem;
  left: 0;
  right: 0;
  height: 420px;
  z-index: -1; }
  .overlay > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .overlay > div.overlay_cover.blur {
      background-size: cover;
      background-position: center center;
      top: -5%;
      bottom: -5%;
      left: -5%;
      right: -5%;
      filter: blur(10px); }

.cp {
  cursor: pointer; }

.ci {
  cursor: initial; }

.h100 {
  height: 100%; }

.w100 {
  width: 100%; }

.img_resp {
  max-width: 100%;
  height: auto; }

.img_fullw {
  width: 100%;
  height: auto; }

.img_fullh {
  height: 100%;
  width: auto; }

.posr {
  position: relative; }

.posa {
  position: absolute; }

.posf {
  position: fixed; }

.db {
  display: block; }

.dn {
  display: none; }

.dib {
  display: inline-block; }

.df {
  display: flex; }
  .df.fdc {
    flex-direction: column; }
  .df.jcc {
    justify-content: center; }
  .df.jcfs {
    justify-content: flex-start; }
  .df.jcfe {
    justify-content: flex-end; }
  .df.jcsa {
    justify-content: space-around; }
  .df.jcsb {
    justify-content: space-between; }
  .df.aifs {
    align-items: flex-start; }
  .df.aife {
    align-items: flex-end; }
  .df.aic {
    align-items: center; }
  .df.ais {
    align-items: stretch; }

.fwnw {
  flex-wrap: nowrap; }

.fww {
  flex-wrap: wrap; }

.mra {
  margin-right: auto; }

.mla {
  margin-left: auto; }

.mta {
  margin-top: auto; }

.mba {
  margin-bottom: auto; }

.border.top {
  border-top-width: 0.5px; }

.border.left {
  border-left-width: 0.5px; }

.border.bottom {
  border-bottom-width: 0.5px; }

.border.right {
  border-right-width: 0.5px; }

.border.solid {
  border-style: solid; }

.ovh {
  overflow: hidden; }

.ovxh {
  overflow-x: hidden; }

.ovyh {
  overflow-y: hidden; }

.ovxa {
  overflow-x: auto; }

.ovya {
  overflow-y: auto; }

.scroll-line {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3003;
  margin-bottom: 0px;
  width: 0%;
  background: red; }

.md_down_hide {
  display: none; }
  @media (min-width: 768px) {
    .md_down_hide {
      display: block; }
      .md_down_hide.df, .md_down_hide.xs-df, .md_down_hide .md-df {
        display: flex; } }

.md_up_hide {
  display: block; }
  .md_up_hide.df, .md_up_hide.xs-df, .md_up_hide .md-df {
    display: flex; }
  @media (min-width: 768px) {
    .md_up_hide {
      display: none; } }

.visuallyhidden {
  position: absolute;
  margin: -1px;
  padding: 0;
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0); }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  position: static;
  margin: 0;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.scrollable.vertical::-webkit-scrollbar, body::-webkit-scrollbar, #main_menu_container::-webkit-scrollbar {
  width: 12px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: rgba(7, 161, 229, 0.1); }

.scrollable.vertical::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb, #main_menu_container::-webkit-scrollbar-thumb {
  background-color: #2ebfff;
  min-height: 100px; }

.scrollable.horizontal::-webkit-scrollbar, body.horizontal::-webkit-scrollbar {
  height: 6px;
  padding-top: 3px;
  padding-bottom: 3px; }

.scrollable.horizontal::-webkit-scrollbar-thumb, body.horizontal::-webkit-scrollbar-thumb {
  min-width: 100px; }

.scrollable::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-clip: content-box;
  border-radius: 3em; }

.scrollable::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background: transparent; }

.scroll.vertical, body.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%; }

.scroll.horizontal {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%; }

.padding_gutter.l {
  padding-left: 0.5rem; }
  @media (min-width: 544px) {
    .padding_gutter.l {
      padding-left: 1rem; } }
  @media (min-width: 768px) {
    .padding_gutter.l {
      padding-left: 2rem; } }

.padding_gutter.r {
  padding-right: 0.5rem; }
  @media (min-width: 544px) {
    .padding_gutter.r {
      padding-right: 1rem; } }
  @media (min-width: 768px) {
    .padding_gutter.r {
      padding-right: 2rem; } }

.padding_gutter.lr {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
  @media (min-width: 544px) {
    .padding_gutter.lr {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 768px) {
    .padding_gutter.lr {
      padding-left: 2rem;
      padding-right: 2rem; } }

.padding_gutter.b {
  padding-bottom: 0.5rem; }
  @media (min-width: 544px) {
    .padding_gutter.b {
      padding-bottom: 1rem; } }
  @media (min-width: 768px) {
    .padding_gutter.b {
      padding-bottom: 2rem; } }

.padding_gutter.t {
  padding-top: 0.5rem; }
  @media (min-width: 544px) {
    .padding_gutter.t {
      padding-top: 1rem; } }
  @media (min-width: 768px) {
    .padding_gutter.t {
      padding-top: 2rem; } }

.padding_gutter.bt {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem; }
  @media (min-width: 544px) {
    .padding_gutter.bt {
      padding-bottom: 1rem;
      padding-top: 1rem; } }
  @media (min-width: 768px) {
    .padding_gutter.bt {
      padding-bottom: 2rem;
      padding-top: 2rem; } }

.padding_gutter.all {
  padding: 0.5rem; }
  @media (min-width: 544px) {
    .padding_gutter.all {
      padding: 1rem; } }
  @media (min-width: 768px) {
    .padding_gutter.all {
      padding: 2rem; } }

.margin_gutter.l {
  margin-left: 0.5rem; }
  @media (min-width: 544px) {
    .margin_gutter.l {
      margin-left: 1rem; } }
  @media (min-width: 768px) {
    .margin_gutter.l {
      margin-left: 2rem; } }

.margin_gutter.r {
  margin-right: 0.5rem; }
  @media (min-width: 544px) {
    .margin_gutter.r {
      margin-right: 1rem; } }
  @media (min-width: 768px) {
    .margin_gutter.r {
      margin-right: 2rem; } }

.margin_gutter.lr {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }
  @media (min-width: 544px) {
    .margin_gutter.lr {
      margin-left: 1rem;
      margin-right: 1rem; } }
  @media (min-width: 768px) {
    .margin_gutter.lr {
      margin-left: 2rem;
      margin-right: 2rem; } }

.margin_gutter.b {
  margin-bottom: 0.5rem; }
  @media (min-width: 544px) {
    .margin_gutter.b {
      margin-bottom: 1rem; } }
  @media (min-width: 768px) {
    .margin_gutter.b {
      margin-bottom: 2rem; } }

.margin_gutter.t {
  margin-top: 0.5rem; }
  @media (min-width: 544px) {
    .margin_gutter.t {
      margin-top: 1rem; } }
  @media (min-width: 768px) {
    .margin_gutter.t {
      margin-top: 2rem; } }

.margin_gutter.bt {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem; }
  @media (min-width: 544px) {
    .margin_gutter.bt {
      margin-bottom: 1rem;
      margin-top: 1rem; } }
  @media (min-width: 768px) {
    .margin_gutter.bt {
      margin-bottom: 2rem;
      margin-top: 2rem; } }

.margin_gutter.all {
  margin: 0.5rem; }
  @media (min-width: 544px) {
    .margin_gutter.all {
      margin: 1rem; } }
  @media (min-width: 768px) {
    .margin_gutter.all {
      margin: 2rem; } }

.items_margin.l {
  margin-left: 0.25rem; }
  @media (min-width: 544px) {
    .items_margin.l {
      margin-left: 0.5rem; } }
  @media (min-width: 768px) {
    .items_margin.l {
      margin-left: 1rem; } }

.items_margin.r {
  margin-right: 0.25rem; }
  @media (min-width: 544px) {
    .items_margin.r {
      margin-right: 0.5rem; } }
  @media (min-width: 768px) {
    .items_margin.r {
      margin-right: 1rem; } }

.items_margin.lr {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }
  @media (min-width: 544px) {
    .items_margin.lr {
      margin-left: 0.5rem;
      margin-right: 0.5rem; } }
  @media (min-width: 768px) {
    .items_margin.lr {
      margin-left: 1rem;
      margin-right: 1rem; } }

.items_margin.all {
  margin: 0.25rem; }
  @media (min-width: 544px) {
    .items_margin.all {
      margin: 0.5rem; } }
  @media (min-width: 768px) {
    .items_margin.all {
      margin: 1rem; } }

.spacer {
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .spacer {
      margin-bottom: 4rem; } }
  @media (min-width: 992px) {
    .spacer {
      margin-bottom: 6rem; } }
  @media (min-width: 1280px) {
    .spacer {
      margin-bottom: 10rem; } }
  @media (min-width: 1920px) {
    .spacer {
      margin-bottom: 14rem; } }

.spacer_min {
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .spacer_min {
      margin-bottom: 4rem; } }
  @media (min-width: 992px) {
    .spacer_min {
      margin-bottom: 6rem; } }
  @media (min-width: 1280px) {
    .spacer_min {
      margin-bottom: 8rem; } }

.inner_spacer {
  padding: 4rem 2rem; }
  @media (min-width: 768px) {
    .inner_spacer {
      padding: 8rem 2rem; } }
  @media (min-width: 992px) {
    .inner_spacer {
      padding: 8rem 2rem; } }
  @media (min-width: 1280px) {
    .inner_spacer {
      padding: 8rem 4rem; } }
  @media (min-width: 1920px) {
    .inner_spacer {
      padding: 8rem 12rem; } }

.inner_spacer_bottom {
  padding: 0rem 2rem 4rem; }
  @media (min-width: 768px) {
    .inner_spacer_bottom {
      padding: 0rem 2rem 8rem; } }
  @media (min-width: 992px) {
    .inner_spacer_bottom {
      padding: 0rem 2rem 8rem; } }
  @media (min-width: 1280px) {
    .inner_spacer_bottom {
      padding: 0rem 4rem 8rem; } }
  @media (min-width: 1920px) {
    .inner_spacer_bottom {
      padding: 0rem 12rem 8rem; } }

.inner_spacer_top {
  padding-top: 4rem; }
  @media (min-width: 768px) {
    .inner_spacer_top {
      padding-top: 4rem; } }
  @media (min-width: 992px) {
    .inner_spacer_top {
      padding-top: 6rem; } }
  @media (min-width: 1280px) {
    .inner_spacer_top {
      padding-top: 8rem; } }
  @media (min-width: 1920px) {
    .inner_spacer_top {
      padding-top: 8rem; } }

.inner_spacer_y {
  padding: 4rem 2rem; }
  @media (min-width: 768px) {
    .inner_spacer_y {
      padding: 4rem 2rem; } }
  @media (min-width: 992px) {
    .inner_spacer_y {
      padding: 6rem 2rem; } }
  @media (min-width: 1280px) {
    .inner_spacer_y {
      padding: 10rem 2rem; } }
  @media (min-width: 1920px) {
    .inner_spacer_y {
      padding: 14rem 2rem; } }

.inner_spacer_y_min {
  padding-top: 4rem;
  padding-bottom: 4rem; }
  @media (min-width: 768px) {
    .inner_spacer_y_min {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  @media (min-width: 992px) {
    .inner_spacer_y_min {
      padding-top: 6rem;
      padding-bottom: 6rem; } }
  @media (min-width: 1280px) {
    .inner_spacer_y_min {
      padding-top: 8rem;
      padding-bottom: 8rem; } }

.fullAbsolute, .bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.fullFixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.bg-overlay {
  overflow: hidden; }

.split_contents_root {
  width: 100%; }
  @media (min-width: 768px) {
    .split_contents_root {
      display: flex;
      flex-direction: row; } }
  .split_contents_root.fullheight .split {
    height: 100%; }
  @media (min-width: 768px) {
    .split_contents_root.centered {
      flex-direction: row;
      align-items: center;
      justify-content: center; } }
  @media (min-width: 768px) {
    .split_contents_root.reversed {
      flex-direction: row-reverse; } }
  .split_contents_root .split {
    width: 100%; }
    @media (min-width: 768px) {
      .split_contents_root .split {
        width: 50%; } }
    .split_contents_root .split.block-texte {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .split_contents_root .split.block-texte > .inner {
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (min-width: 768px) {
          .split_contents_root .split.block-texte > .inner {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto; } }
    .split_contents_root .split.block-image-bg {
      position: relative;
      min-height: 400px; }
      .split_contents_root .split.block-image-bg > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        background-size: cover;
        background-position: center center; }

.font1, h3.title, .h3, a.bouton, .activityCard .activityCard_inner a.activityMyButton, button.bouton, .activityCard .activityCard_inner button.activityMyButton, button, body, .form_block .field_blocks .field_form input:not([type='checkbox']),
.form_block .field_blocks .field_form textarea, .form_block .field_blocks .field_form select {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400; }

.font2, h1.title, .h1, h2.title, .h2, #popover_form > .inner form .form_top .title, #main_menu_container #main_menu_inner #main_menu_wrapper .menu_titre .title, #hero_top .title, .form_top .title, #system-message-container #system-message > div .alert-heading, #footer h3 {
  font-family: 'Londrina Solid', cursive;
  font-weight: 900; }

.sb, h3.title, .h3, .paragraphe strong, .paragraphe a, a.tags .hash, a.bouton, .activityCard .activityCard_inner a.activityMyButton, button.bouton, .activityCard .activityCard_inner button.activityMyButton, button, ul.nav li.active > a, .activityCard .activityCard_inner .activityTitle, #block_form label, .form_block .infos, .form_block .field_blocks .field_label, #system-message-container #system-message > div a.close, .blog.content .content_wrapper .header_block .description_block p, .blog.content .content_wrapper .header_block .article_infos .tags, .groupx_block.paragraphe a, .groupx_block.photo span, .groupx_block.fichier .box a, .groupx_block.lien .box a, .categorie_item span, .page_header .page_header_wrapper .title.parent_cat, .page_header .page_header_wrapper .title_block.article .titre_top {
  font-weight: 600; }

.xbi {
  font-weight: 800;
  font-style: italic; }

body {
  color: #4d4d4d; }

.disabled-color {
  color: #95A2B1; }

.ls {
  letter-spacing: 0.1em; }

.caps {
  text-transform: uppercase; }

.tal {
  text-align: left; }

.tac {
  text-align: center; }

.tar {
  text-align: right; }

a,
a:hover,
a:visited,
a:focus {
  color: inherit;
  text-decoration: none;
  outline: 0; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.box-shadow, a.tags {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2); }

/* 
button.bouton {
	text-transform: uppercase;
	@extend .sb;
	padding: (($gutter/2) - 0.3) ($gutter - 0.3);
	text-align: center;
	border-radius: 3em;
	letter-spacing: 0.1em;

	transition: color, background-color 0.3s;

	color: $blanc;
	background-color: $bleu1;
	border: 0.3em solid $bleu1;

	&:hover {
		color: $bleu1;
		background-color: $blanc;
	}

	&.reverse {
		color: $bleu1;
		background-color: $blanc;
		border: 0.3em solid $blanc;

		&:hover {
			color: $blanc;
			background-color: $bleu1;
		}
	}
} */
.important {
  padding: 0.1em 0.25em;
  line-height: 1.9; }

.block_waves {
  margin-bottom: 8rem;
  width: 100%;
  text-align: center; }
  .block_waves .block_waves_inner .block_texte {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto; }

.svg_wrapper {
  font-size: 0; }

.title {
  line-height: 1.2;
  display: block;
  hyphens: auto; }
  .title.centered {
    text-align: center; }

h1.title, .h1 {
  font-size: 200%;
  letter-spacing: 0.05rem; }
  @media (min-width: 768px) {
    h1.title, .h1 {
      font-size: 300%; } }
  @media (min-width: 992px) {
    h1.title, .h1 {
      font-size: 500%; } }

h2.title, .h2 {
  font-size: 180%;
  letter-spacing: 0.05rem; }
  @media (min-width: 768px) {
    h2.title, .h2 {
      font-size: 200%; } }
  @media (min-width: 992px) {
    h2.title, .h2 {
      font-size: 350%; } }

h3.title, .h3 {
  font-size: 150%; }
  @media (min-width: 768px) {
    h3.title, .h3 {
      font-size: 180%; } }
  @media (min-width: 992px) {
    h3.title, .h3 {
      font-size: 200%; } }

p {
  line-height: 1.7; }

.paragraphe a {
  color: #2ebfff; }

a.tags {
  display: inline-block;
  padding: 0.2em 0.5em;
  font-size: 1rem;
  border-radius: 0.3em;
  margin-bottom: 0.5rem;
  color: #FEFEFE; }
  a.tags span {
    text-transform: lowercase;
    display: inline-block; }
  a.tags .hash {
    line-height: 1;
    font-size: 130%;
    transform: translateY(7%);
    margin-right: 0.2em; }

.boutons {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap; }

a.bouton, .activityCard .activityCard_inner a.activityMyButton, button.bouton, .activityCard .activityCard_inner button.activityMyButton, button {
  display: inline-block;
  text-transform: uppercase;
  font-size: 80%;
  letter-spacing: 0.6px;
  padding: 0.66667rem 1rem;
  /* & + a.bouton,	& + button.bouton{
		margin-left: $gutter/2;
	} */ }
  a.bouton.bouton, .activityCard .activityCard_inner a.activityMyButton, button.bouton.bouton, .activityCard .activityCard_inner button.activityMyButton, button.bouton, .activityCard .activityCard_inner button.activityMyButton {
    color: #FEFEFE; }

.block_texte > *:not(:last-child) {
  margin-bottom: 2rem; }

.block_texte.reverse .title, .block_texte.reverse .description {
  color: #FEFEFE; }

.block_texte.reverse .bouton, .block_texte.reverse .activityCard .activityCard_inner .activityMyButton, .activityCard .activityCard_inner .block_texte.reverse .activityMyButton {
  background-color: #FEFEFE;
  color: #2ebfff; }

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid rgba(51, 51, 51, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1; }

/* @font-face {
    font-family: "aquasynchro";
    src: url("../fonts/aquasynchro.eot");
    src: url("../fonts/aquasynchro.eot?#iefix") format("embedded-opentype"),
         url("../fonts/aquasynchro.woff") format("woff"),
         url("../fonts/aquasynchro.ttf") format("truetype"),
         url("../fonts/aquasynchro.svg#aquasynchro") format("svg");
    font-weight: normal;
    font-style: normal;
}
 
[data-icon]:before,
  .cck_button_del > span:before,
  .cck_button_add > span:before,
  .cck_button_drag > span:before {
    font-family: "aquasynchro" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before,
.cck_button_del > span:before,
.cck_button_add > span:before,
.cck_button_drag > span:before {
    vertical-align: middle;
    font-family: "aquasynchro" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\61";
}
.icon-envelope-o:before {
  content: "\62";
}
.icon-caret-right:before {
  content: "\63";
}
.icon-caret-left:before {
  content: "\64";
}
.icon-trash:before {
  content: "\67";
}
.icon-picture-o:before {
  content: "\68";
}
.icon-list-ul:before {
  content: "\69";
}
.icon-align-justify:before {
  content: "\6a";
}
.icon-arrows:before {
  content: "\6b";
}
.icon-plus:before {
  content: "\65";
}
.icon-minus:before {
  content: "\66";
}
.icon-eye:before {
  content: "\6c";
}
.icon-pencil:before {
  content: "\6d";
} */
[class^="icon-"],
[class*=" icon-"] {
  line-height: 1; }

[icon-size="1"] {
  font-size: 1.1rem; }

.cck_cgx.cck_cgx_button {
  width: 7rem;
  margin-left: 1rem; }
  .cck_cgx.cck_cgx_button .cck_button {
    display: inline-block;
    vertical-align: middle; }
    .cck_cgx.cck_cgx_button .cck_button + .cck_button {
      margin-left: 0.5rem; }
    .cck_cgx.cck_cgx_button .cck_button_del, .cck_cgx.cck_cgx_button .cck_button_add, .cck_cgx.cck_cgx_button .cck_button_drag {
      width: 1.5rem;
      height: 1.5rem;
      overflow: visible; }
      .cck_cgx.cck_cgx_button .cck_button_del > span, .cck_cgx.cck_cgx_button .cck_button_add > span, .cck_cgx.cck_cgx_button .cck_button_drag > span {
        float: none;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        vertical-align: middle;
        font-size: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1;
        padding: 0;
        outline: 0; }
    .cck_cgx.cck_cgx_button .cck_button_del > span {
      margin-right: 1rem; }
      .cck_cgx.cck_cgx_button .cck_button_del > span:before {
        content: "\67";
        color: red; }
    .cck_cgx.cck_cgx_button .cck_button_add > span {
      margin-right: 1rem; }
      .cck_cgx.cck_cgx_button .cck_button_add > span:before {
        content: "\65";
        color: green; }
    .cck_cgx.cck_cgx_button .cck_button_drag > span:before {
      content: "\6b";
      color: blue; }

body {
  position: relative;
  overflow-y: scroll; }

#popover_form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden; }
  #popover_form > .inner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FEFEFE;
    transform-style: preserve-3d;
    perspective: 600px; }
    #popover_form > .inner .form_bg {
      position: absolute;
      top: 0;
      height: 100%;
      width: 50%;
      right: 0;
      background-color: #2ebfff;
      z-index: 0;
      transform: translateX(100%); }
    #popover_form > .inner form {
      z-index: 10;
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 70%;
      transform: translate(-50%, -50%) rotateY(-90deg);
      transform-origin: left center;
      background-color: #FEFEFE;
      padding: 4rem 0;
      box-shadow: 0rem 1.3rem 1.5rem -1.5rem rgba(46, 191, 255, 0.7); }
      @media (min-width: 992px) {
        #popover_form > .inner form {
          padding: 4rem 4rem 2rem; } }
      #popover_form > .inner form .fermer_form {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.6em;
        z-index: 1000;
        font-family: arial;
        color: #07a1e5; }
        #popover_form > .inner form .fermer_form:hover {
          color: #2ebfff; }
      #popover_form > .inner form .form_top {
        margin-bottom: 2rem; }
        #popover_form > .inner form .form_top .form_icon {
          text-align: center;
          display: block;
          margin-bottom: 0.5rem;
          font-size: 300%;
          color: #2ebfff; }
        #popover_form > .inner form .form_top .title {
          letter-spacing: 0.25em;
          text-transform: uppercase;
          color: #2ebfff; }
        #popover_form > .inner form .form_top .form_top_texte {
          font-size: 200%; }
      #popover_form > .inner form .form_fields {
        margin-bottom: 4rem; }
        #popover_form > .inner form .form_fields .form_field + .form_field {
          margin-top: 2rem; }
        #popover_form > .inner form .form_fields .form_field > * {
          display: block; }
        #popover_form > .inner form .form_fields .form_field label {
          margin-bottom: 0.25rem; }
        #popover_form > .inner form .form_fields .form_field[data-form-field="honey"] {
          display: none; }
      #popover_form > .inner form .form_button {
        margin-bottom: 2rem; }

input[type="text"],
textarea {
  width: 80%;
  padding: 0.5rem 1rem;
  border-right-width: 0;
  border-top-width: 0;
  border-left-width: 0;
  border-bottom-width: 0.6px;
  border-bottom-color: #2ebfff; }

textarea {
  min-height: 5rem; }

[data-form="contact"] {
  cursor: pointer; }

body {
  overflow-y: auto; }
  body[data-mail-opened="false"] {
    overflow-y: auto; }
    body[data-mail-opened="false"] .form_wrapper {
      opacity: 0;
      visibility: hidden;
      overflow-y: hidden; }
      body[data-mail-opened="false"] .form_wrapper .form {
        opacity: 0;
        visibility: hidden; }
  body[data-mail-opened="true"] {
    overflow-y: hidden; }
    body[data-mail-opened="true"] .form_wrapper {
      overflow-y: auto;
      overflow-x: hidden; }

#navTop {
  width: 100%;
  background-color: #2ebfff;
  color: #FEFEFE;
  margin-bottom: 6rem; }
  #navTop .navTop_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    padding: 0.14286rem 0.14286rem; }
    @media (min-width: 992px) {
      #navTop .navTop_inner {
        padding: 1rem 1rem; } }

.joomMenu {
  display: none; }
  @media (min-width: 768px) {
    .joomMenu {
      display: flex; } }
  .joomMenu > a {
    font-size: 80%; }
    .joomMenu > a + a {
      margin-left: 1rem; }

svg * {
  transform-box: fill-box; }

#logo {
  height: 6.5rem;
  width: 6.5rem;
  position: relative;
  transform: translateY(2rem);
  margin-left: 2rem;
  margin-right: 2rem; }
  #logo .logo_wrapper {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center; }
    #logo .logo_wrapper svg {
      display: block;
      height: 100%;
      width: 100%; }
    #logo .logo_wrapper #logo_svg #logo_g > *:not(#cercle) {
      visibility: hidden;
      opacity: 0;
      transform: translateY(100%); }
    #logo .logo_wrapper #logo_svg #logo_g #cercle {
      visibility: hidden;
      opacity: 0;
      transform: scale(0); }
    #logo .logo_wrapper #logo_svg #logo_g #texte {
      transform: translateY(-100%); }

#joomMenu1, #social {
  margin-left: auto; }

#social {
  margin-right: 0;
  justify-self: flex-end;
  font-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  #social > a {
    display: block;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FEFEFE;
    border-radius: 50%; }
    #social > a + a {
      margin-left: 1rem; }
    #social > a span {
      color: #2ebfff; }

#menu_ico #menu_icon {
  padding: 2rem;
  cursor: pointer; }
  #menu_ico #menu_icon .bar {
    transform: translateY(0rem) rotate(0deg);
    display: block;
    height: 0.15rem;
    width: 2rem;
    background-color: #FEFEFE; }
    #menu_ico #menu_icon .bar:nth-child(2) {
      width: 1.5rem;
      margin-left: 0.5rem; }
    #menu_ico #menu_icon .bar + .bar {
      margin-top: 0.4rem; }

#main_menu_container {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #07a1e5;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden; }
  #main_menu_container #main_menu_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    #main_menu_container #main_menu_inner #main_menu_wrapper {
      margin-top: 5rem;
      margin-bottom: 5rem; }
      #main_menu_container #main_menu_inner #main_menu_wrapper .menu_titre .title {
        font-size: 200%;
        letter-spacing: 10px;
        color: #FEFEFE; }

ul.nav {
  color: #FEFEFE; }
  ul.nav a + ul {
    margin-top: 0.33333rem; }
  ul.nav li.active > a {
    text-decoration: wavy underline; }
  ul.nav > li {
    margin-bottom: 0.33333rem; }
    ul.nav > li > a {
      font-size: 120%; }
    ul.nav > li.parent > a:before {
      content: "-";
      margin-right: 0.25rem; }
    ul.nav > li > ul {
      padding-left: 1.33333rem; }
      ul.nav > li > ul > li {
        margin-bottom: 0.33333rem; }
        ul.nav > li > ul > li > a {
          font-size: 100%; }
        ul.nav > li > ul > li > ul {
          padding-left: 4rem; }
          ul.nav > li > ul > li > ul > li {
            margin-bottom: 0.25rem; }
            ul.nav > li > ul > li > ul > li a {
              font-size: 100%; }

.menu_titre {
  margin-bottom: 0.33333rem; }

#hero_top .title {
  font-size: 10vw;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 4px; }
  @media (min-width: 768px) {
    #hero_top .title {
      font-size: 300%;
      text-align: left; } }

#carousel {
  width: 100%;
  position: relative;
  margin-bottom: 4rem; }
  @media (min-width: 992px) {
    #carousel {
      margin-bottom: 0; } }
  #carousel .carousel_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse; }
    @media (min-width: 992px) {
      #carousel .carousel_wrapper {
        min-height: 75vh; } }
    #carousel .carousel_wrapper .carousel_split {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (min-width: 992px) {
        #carousel .carousel_wrapper .carousel_split {
          width: 50%; } }
      #carousel .carousel_wrapper .carousel_split#carousel_right {
        padding-left: 2rem;
        padding-right: 2rem;
        text-align: center; }
        #carousel .carousel_wrapper .carousel_split#carousel_right #carouselTitle {
          padding-left: 1rem;
          padding-right: 1rem; }
      #carousel .carousel_wrapper .carousel_split#carousel_left {
        padding-left: 2rem;
        padding-right: 2rem; }
      @media (min-width: 992px) {
        #carousel .carousel_wrapper .carousel_split {
          padding-bottom: 6rem; }
          #carousel .carousel_wrapper .carousel_split#carousel_left {
            padding-left: 8rem; }
            #carousel .carousel_wrapper .carousel_split#carousel_left .carousel_split_inner {
              min-width: 80%; }
          #carousel .carousel_wrapper .carousel_split#carousel_right {
            text-align: left;
            padding-right: 8rem;
            justify-content: flex-start; }
            #carousel .carousel_wrapper .carousel_split#carousel_right .carousel_split_inner {
              max-width: 600px; }
            #carousel .carousel_wrapper .carousel_split#carousel_right #carouselTitle {
              padding-left: 0;
              padding-right: 0; } }
    #carousel .carousel_wrapper .carousel_nav {
      top: -0.5rem;
      position: absolute;
      width: 4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (min-width: 992px) {
        #carousel .carousel_wrapper .carousel_nav {
          top: 40%; } }
      #carousel .carousel_wrapper .carousel_nav span {
        color: #FEFEFE;
        font-size: 200%; }
      #carousel .carousel_wrapper .carousel_nav#prev {
        left: 0; }
      #carousel .carousel_wrapper .carousel_nav#next {
        right: 0; }

.block_image_carousel {
  position: relative;
  width: 100%;
  height: 100%; }
  .block_image_carousel .pix_wrapper {
    display: flex;
    align-items: center;
    justify-content: center; }
    .block_image_carousel .pix_wrapper.waves_wrapper, .block_image_carousel .pix_wrapper.activity_wrapper {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0; }
    .block_image_carousel .pix_wrapper > img {
      max-width: 100%;
      height: auto; }
    .block_image_carousel .pix_wrapper.waves_wrapper {
      animation: waves 2s ease-in-out infinite; }

@keyframes waves {
  0%, 100% {
    transform: translate(0px, 0px); }
  50% {
    transform: translate(2px, 2px); } }

#activitiesCards {
  margin-bottom: 4rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%; }
  @media (min-width: 992px) {
    #activitiesCards {
      width: 80%; } }
  #activitiesCards .activitiesCards_inner {
    width: 100%; }
    @media (min-width: 992px) {
      #activitiesCards .activitiesCards_inner {
        flex-wrap: wrap;
        display: flex;
        justify-content: center; } }

.activityCard {
  margin-bottom: 4rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 992px) {
    .activityCard {
      width: 30%; }
      .activityCard + .activityCard {
        margin-left: 4%; } }
  .activityCard .activityCard_inner {
    background-color: #FEFEFE;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 4rem 2rem 2rem 2rem;
    text-align: center; }
    .activityCard .activityCard_inner .activityLine {
      margin-bottom: 2rem;
      margin-top: 1rem; }
      .activityCard .activityCard_inner .activityLine div {
        margin-left: auto;
        margin-right: auto;
        width: 50px;
        height: 8px; }
    .activityCard .activityCard_inner .activityTitle {
      margin-bottom: 2rem;
      font-size: 150%; }
    .activityCard .activityCard_inner .activityDescription {
      text-align: left;
      margin-bottom: 2rem; }
    .activityCard .activityCard_inner .activityMyButton {
      margin-bottom: 2rem; }

.activityPicture {
  margin-bottom: -2rem;
  text-align: center; }
  .activityPicture img {
    display: inline-block; }

#block_form {
  max-width: 800px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;
  margin-bottom: 2rem; }
  #block_form > * {
    margin-bottom: 2rem; }
  #block_form label {
    display: inline-block;
    margin-bottom: 0.5rem; }

.form_top .form_icon {
  text-align: center;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 300%;
  color: #2ebfff; }

.form_top .title {
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #2ebfff; }

.form_top .form_top_texte {
  font-size: 200%; }

.form_block .infos {
  color: #2ebfff;
  font-size: 200%; }

.form_block .field_blocks {
  padding: 1rem;
  padding-left: 2rem;
  border-left: 4px solid #2ebfff; }
  .form_block .field_blocks .field_block {
    width: 100%; }
    .form_block .field_blocks .field_block + .field_block {
      margin-top: 2rem; }
  .form_block .field_blocks .field_label {
    display: block;
    margin-bottom: 6px; }
  .form_block .field_blocks .field_form .checkboxes label {
    margin-left: 0.2rem;
    margin-right: 0.5rem; }
  .form_block .field_blocks .field_form input:not([type='checkbox']),
  .form_block .field_blocks .field_form textarea {
    display: block;
    padding: 8px;
    border-color: #2ebfff;
    width: 100%;
    outline: 0;
    border-width: 0;
    border-color: #2ebfff;
    border-style: solid;
    border-left-width: 3px;
    border-bottom-width: 3px;
    transition: all 0.3s; }
    .form_block .field_blocks .field_form input:not([type='checkbox']):focus,
    .form_block .field_blocks .field_form textarea:focus {
      border-left-width: 2px;
      border-bottom-width: 2px; }
  .form_block .field_blocks .field_form select {
    padding: 8px;
    min-width: 300px; }

.cck_group_x {
  width: 100%; }
  .cck_group_x .cck_form_group_x {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end; }
    .cck_group_x .cck_form_group_x + .cck_form_group_x {
      margin-top: 2rem; }
    .cck_group_x .cck_form_group_x .horizontal_gx .cck_form_group_x .cck_cgx_form {
      width: 100%;
      float: none;
      display: block;
      background: aliceblue;
      padding: 2rem; }
      .cck_group_x .cck_form_group_x .horizontal_gx .cck_form_group_x .cck_cgx_form .cck_select_simple {
        margin-bottom: 1rem; }

#system-message-container {
  margin-bottom: 4rem; }
  #system-message-container #system-message {
    display: flex;
    justify-content: center; }
    #system-message-container #system-message > div {
      max-width: 400px;
      padding: 1rem 2rem;
      color: #FEFEFE;
      background-color: rgba(46, 191, 255, 0.7); }
      #system-message-container #system-message > div.alert-message {
        color: #FEFEFE;
        background-color: rgba(255, 0, 0, 0.7); }
      #system-message-container #system-message > div .alert-heading {
        font-size: 150%;
        letter-spacing: 4px;
        text-transform: uppercase; }
      #system-message-container #system-message > div a.close {
        font-size: 200%;
        cursor: pointer;
        border: 2px solid #fff;
        padding: 0 0.5rem; }
      #system-message-container #system-message > div a.close + h4 {
        margin-bottom: 10px;
        display: inline-block;
        margin-left: 10px; }

.admin_block {
  text-align: center; }
  .admin_block a {
    display: inline-block;
    border: solid 2px;
    border-radius: 0.25rem;
    padding: 0.33333rem 0.5rem; }
    .admin_block a.supprimer {
      color: #ff5252; }
    .admin_block a.editer {
      color: #2abb6b; }
    .admin_block a + a {
      margin-left: 0.5rem; }
    .admin_block a:before {
      margin-right: 0.25rem; }

.blog.content {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 2rem 2rem 2rem;
  margin-bottom: 2rem; }
  .blog.content .content_wrapper .header_block {
    text-align: center;
    margin-bottom: 2rem; }
    .blog.content .content_wrapper .header_block .titre_block {
      margin-bottom: 2rem; }
    .blog.content .content_wrapper .header_block .tags_block {
      margin-bottom: 2rem; }
    .blog.content .content_wrapper .header_block .description_block {
      margin-bottom: 1rem; }
    .blog.content .content_wrapper .header_block .image_couverture {
      position: relative; }
      .blog.content .content_wrapper .header_block .image_couverture .img_content {
        position: relative;
        width: 100%;
        height: auto; }
    .blog.content .content_wrapper .header_block .article_infos {
      text-align: left; }
      .blog.content .content_wrapper .header_block .article_infos .tags {
        display: block;
        color: #2ebfff;
        margin-bottom: 5px;
        letter-spacing: 0.5px;
        border-top-left-radius: 0rem;
        border-top-right-radius: 0rem; }
        .blog.content .content_wrapper .header_block .article_infos .tags.agenda.tags .hash_text:before {
          line-height: 1;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.6rem; }
  .blog.content .content_wrapper .content_block .groupx_block + .groupx_block.titre,
  .blog.content .content_wrapper .content_block .groupx_block + .groupx_block.sous_titre {
    margin-top: 4rem; }
  .blog.content .content_wrapper .content_block .groupx_block + .groupx_block {
    margin-top: 2rem; }

.groupx_block {
  margin-bottom: 2rem; }
  .groupx_block.paragraphe a {
    text-decoration: underline; }
  .groupx_block.paragraphe li {
    line-height: 1.7;
    list-style: inside;
    list-style-type: square;
    margin-bottom: 1rem; }
  .groupx_block.video {
    text-align: center; }
    .groupx_block.video .video-container {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%; }
      .groupx_block.video .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  .groupx_block.photo {
    text-align: center; }
    .groupx_block.photo img {
      margin-bottom: 0.5rem;
      margin-right: auto;
      margin-left: auto; }
    .groupx_block.photo span {
      display: block;
      line-height: 1; }
      .groupx_block.photo span i {
        margin-right: 0.5rem;
        color: #2ebfff;
        display: inline-block;
        vertical-align: top; }
    .groupx_block.photo [data-width="25"] img {
      width: 25%; }
    .groupx_block.photo [data-width="50"] img {
      width: 50%; }
    .groupx_block.photo [data-width="75"] img {
      width: 75%; }
    .groupx_block.photo [data-width="100"] img {
      width: 100%; }
  .groupx_block.fichier {
    text-align: center; }
    .groupx_block.fichier .box {
      display: inline-block;
      padding: 2rem;
      background: aliceblue; }
      .groupx_block.fichier .box span i {
        margin-right: 0.1rem;
        color: #2ebfff;
        display: inline-block;
        vertical-align: text-bottom; }
      .groupx_block.fichier .box a {
        text-decoration: underline; }
  .groupx_block.lien {
    text-align: center; }
    .groupx_block.lien .box {
      display: inline-block;
      padding: 2rem;
      background: #e8def8; }
      .groupx_block.lien .box span i {
        margin-right: 0.1rem;
        color: #2ebfff;
        display: inline-block;
        vertical-align: text-bottom; }
      .groupx_block.lien .box a {
        text-decoration: underline; }

.titre_galerie {
  text-align: center; }

.galerie_block {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .galerie_block .galerie_photo_item {
    text-align: center;
    margin-bottom: 1rem; }
    .galerie_block .galerie_photo_item + .galerie_photo_item {
      margin-left: 2rem; }
    .galerie_block .galerie_photo_item a {
      display: block; }
    .galerie_block .galerie_photo_item span {
      display: block; }
      .galerie_block .galerie_photo_item span i {
        margin-right: 0.5rem;
        color: #2ebfff;
        display: inline-block;
        vertical-align: text-bottom; }

.item_list_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem; }

.intro.blog {
  display: inline-block;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
  hyphens: auto;
  background-color: #FEFEFE;
  width: 98%;
  margin: 0 2% 2rem 2%; }
  @media (min-width: 768px) {
    .intro.blog {
      width: 46%; } }
  @media (min-width: 992px) {
    .intro.blog {
      width: 29.33%; } }
  .intro.blog .intro_wrapper .top {
    position: relative; }
    .intro.blog .intro_wrapper .top .wrapper_image_couverture .couverture {
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%; }
  .intro.blog .intro_wrapper .bottom {
    padding: 2rem; }
    .intro.blog .intro_wrapper .bottom .titre_block {
      margin-bottom: 1rem;
      margin-top: -4rem;
      text-align: center; }
      .intro.blog .intro_wrapper .bottom .titre_block a {
        background-color: #FEFEFE;
        padding: 0.5rem 1rem;
        display: inline-block;
        transform: scale(1) rotate(0deg);
        transition: transform 0.3s; }
        .intro.blog .intro_wrapper .bottom .titre_block a:hover {
          transform: scale(1.1) rotate(5deg); }

.tags_block {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem; }
  .tags_block a {
    transform: scale(1) rotate(0deg);
    transition: transform 0.3s;
    z-index: 0; }
    .tags_block a:hover {
      transform: scale(1.2) rotate(-5deg);
      z-index: 1; }

.blog_list_links {
  margin-top: 2rem;
  width: 100%;
  text-align: center; }
  .blog_list_links > a {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
    .blog_list_links > a + a {
      margin-left: 1rem; }
    .blog_list_links > a:hover {
      box-shadow: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }

.categorie_item span {
  font-size: 90%; }
  .categorie_item span:before {
    vertical-align: middle;
    margin-right: 0.25rem; }

.page_header {
  text-align: center; }
  .page_header.list, .page_header.search {
    margin-bottom: 2rem; }
  .page_header .page_header_wrapper {
    display: inline-block; }
    .page_header .page_header_wrapper .title.parent_cat {
      text-transform: uppercase;
      font-size: 120%; }
    .page_header .page_header_wrapper .title_block.article .titre_top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.25rem;
      font-size: 1.2rem; }
      .page_header .page_header_wrapper .title_block.article .titre_top .menu_parent {
        opacity: 0.8; }

.type_filter {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem; }
  .type_filter .sujet.tags {
    font-size: 150%; }
    .type_filter .sujet.tags .hash {
      vertical-align: top;
      margin-right: 1rem; }

#footer {
  color: #FEFEFE; }
  @media (min-width: 992px) {
    #footer > .inner {
      display: flex; } }
  #footer > .inner > div {
    display: flex;
    justify-content: center; }
    #footer > .inner > div > div {
      max-width: 400px;
      margin-top: 4rem;
      margin-bottom: 4rem; }
  #footer > .inner .left {
    background-color: #2ebfff;
    width: 100%; }
    @media (min-width: 992px) {
      #footer > .inner .left {
        width: 70%; } }
  #footer > .inner .right {
    background-color: #07a1e5;
    width: 100%; }
    @media (min-width: 992px) {
      #footer > .inner .right {
        width: 30%; } }
  #footer h3 {
    font-size: 150%;
    text-transform: uppercase;
    letter-spacing: 4px; }
  #footer .bloc_text {
    padding-left: 2rem;
    padding-right: 2rem; }
    #footer .bloc_text > * {
      margin-bottom: 2rem; }

.socials {
  display: flex;
  gap: 0.5rem; }
  .socials .social {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #FEFEFE;
    color: #2ebfff;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1) rotate(0deg); }
    .socials .social i:before {
      vertical-align: middle;
      line-height: 1; }
    .socials .social:hover {
      transform: scale(1.2) rotate(10deg); }

body > .login {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto; }

.gestion {
  text-align: center;
  padding-bottom: 4rem; }
  .gestion.search {
    margin-bottom: 4rem; }
  .gestion .titre_gestion .box a {
    display: inline-block;
    background-color: #2ebfff;
    padding: 1rem 2rem;
    color: #FEFEFE;
    box-shadow: 0 0.25rem 0.5rem rgba(77, 77, 77, 0.5); }
  .gestion .titre_gestion .box #blog_categorie, .gestion .titre_gestion .box #blog_ordre {
    box-shadow: 0 0.25rem 0.5rem rgba(77, 77, 77, 0.5);
    padding: 1rem 1rem; }
  .gestion .titre_gestion.titre_gestion {
    margin-top: 1rem; }

.gestion_site_item {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem; }
  .gestion_site_item .box {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .gestion_site_item .box .blog_list_cover {
      width: 2rem;
      height: 2rem; }
      .gestion_site_item .box .blog_list_cover > .inner {
        background-size: cover;
        background-position: 50% 50%;
        width: 100%;
        height: 100%; }
    .gestion_site_item .box a {
      background-color: aliceblue;
      margin-left: 1rem;
      padding: 0.5rem; }
