// Colours
$bleu1:#2ebfff;
$bleu2:#07a1e5;
$rose1:#ff5d7d;
$rose2:#eb3e60;
$jaune1:#ffc144;
$jaune2:#e59d0a;
$vert1:#559605;
$vert2:#457c01;

[data-color=color-club] {
    color: $bleu1;
}
.bg-color-club{
    background-color: $bleu1;
}


$bleu4:#2e94ff;


//$bleu : #00ADB4;
//$vert : #5AE36F;
//$rouge : #F24D4E;
//$or : #D6A34D;
//$violet : #7F61FF;
//$violet_sombre : #171823;
//$violet_sombre2 : #0F111A;

$blanc: #FEFEFE;
$noir: #4d4d4d;

$gris_sombre: #0F0F0F;

$disabled: #95A2B1;


// Gradiants
$bg-gradiant-wrapper: linear-gradient(-30deg, #17181a, #030308);
$bg-gradiant-header-lr: linear-gradient(145deg, rgba($blanc, 0.15) 0%, rgba($gris_sombre, 0) 50%);
$bg-gradiant-header-rl: linear-gradient(-145deg, rgba($blanc, 0.15) 0%, rgba($gris_sombre, 0) 50%);
//$bg-gradiant-header2: radial-gradient(ellipse at center, rgba($blanc, 0.2) 0%, rgba($noir, 0) 50%);
//$bg-gradiant-header: radial-gradient(ellipse at center, rgba($blanc, 0.15) 0%, rgba($gris_sombre, 0) 50%);

//@include gradient($angle, $bleu1, $bleu2);

