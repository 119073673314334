.padding_gutter {
    &.l{
        padding-left: $gutter / 4;
        @media #{$sm}{  padding-left: $gutter / 2;   }
        @media #{$md}{  padding-left: $gutter;  }
    }
    &.r{
        padding-right: $gutter / 4;
        @media #{$sm}{  padding-right: $gutter / 2;   }
        @media #{$md}{  padding-right: $gutter;  }
    }
    &.lr{
        padding-left: $gutter / 4;
        padding-right: $gutter / 4;
        @media #{$sm}{  padding-left: $gutter / 2;   padding-right: $gutter / 2;   }
        @media #{$md}{  padding-left: $gutter;       padding-right: $gutter;   }
    }
    &.b{
        padding-bottom: $gutter / 4;
        @media #{$sm}{  padding-bottom: $gutter / 2;   }
        @media #{$md}{  padding-bottom: $gutter;  }
    }
    &.t{
        padding-top: $gutter / 4;
        @media #{$sm}{  padding-top: $gutter / 2;   }
        @media #{$md}{  padding-top: $gutter;  }
    }
    &.bt{
        padding-bottom: $gutter / 4;
        padding-top: $gutter / 4;
        @media #{$sm}{  padding-bottom: $gutter / 2;   padding-top: $gutter / 2;   }
        @media #{$md}{  padding-bottom: $gutter;       padding-top: $gutter;   }
    }
    &.all{
       padding: $gutter / 4;
        @media #{$sm}{  padding: $gutter / 2;   }
        @media #{$md}{  padding: $gutter;  } 
    }
}

.margin_gutter {
    &.l{
        margin-left: $gutter / 4;
        @media #{$sm}{  margin-left: $gutter / 2;   }
        @media #{$md}{  margin-left: $gutter;  }
    }
    &.r{
        margin-right: $gutter / 4;
        @media #{$sm}{  margin-right: $gutter / 2;   }
        @media #{$md}{  margin-right: $gutter;  }
    }
    &.lr{
        margin-left: $gutter / 4;
        margin-right: $gutter / 4;
        @media #{$sm}{  margin-left: $gutter / 2;   margin-right: $gutter / 2;   }
        @media #{$md}{  margin-left: $gutter;       margin-right: $gutter;   }
    }
    &.b{
        margin-bottom: $gutter / 4;
        @media #{$sm}{  margin-bottom: $gutter / 2;   }
        @media #{$md}{  margin-bottom: $gutter;  }
    }
    &.t{
        margin-top: $gutter / 4;
        @media #{$sm}{  margin-top: $gutter / 2;   }
        @media #{$md}{  margin-top: $gutter;  }
    }
    &.bt{
        margin-bottom: $gutter / 4;
        margin-top: $gutter / 4;
        @media #{$sm}{  margin-bottom: $gutter / 2;   margin-top: $gutter / 2;   }
        @media #{$md}{  margin-bottom: $gutter;       margin-top: $gutter;   }
    }
    &.all{
       margin: $gutter / 4;
        @media #{$sm}{  margin: $gutter / 2;   }
        @media #{$md}{  margin: $gutter;  } 
    }
}



.items_margin {
    &.l{
        margin-left: $gutter / 8;
        @media #{$sm}{  margin-left: $gutter / 4;   }
        @media #{$md}{  margin-left: $gutter / 2;  }
    }
    &.r{
        margin-right: $gutter / 8;
        @media #{$sm}{  margin-right: $gutter / 4;   }
        @media #{$md}{  margin-right: $gutter / 2;  }
    }
    &.lr{
        margin-left: $gutter / 8;
        margin-right: $gutter / 8;
        @media #{$sm}{  margin-left: $gutter / 4;   margin-right: $gutter / 4;   }
        @media #{$md}{  margin-left: $gutter / 2;       margin-right: $gutter / 2;   }
    }
    &.all{
       margin: $gutter / 8;
        @media #{$sm}{  margin: $gutter / 4;   }
        @media #{$md}{  margin: $gutter / 2;  } 
    }
}

.spacer{
    margin-bottom: $gutter*1;
    @media #{$md}{margin-bottom: $gutter*2;}
    @media #{$lg}{margin-bottom: $gutter*3;}
    @media #{$xl}{margin-bottom: $gutter*5;}
    @media #{$xxl}{margin-bottom: $gutter*7;}
}
.spacer_min{
    margin-bottom: $gutter*1;
    @media #{$md}{margin-bottom: $gutter*2;}
    @media #{$lg}{margin-bottom: $gutter*3;}
    @media #{$xl}{margin-bottom: $gutter*4;}
}


.inner_spacer{
    padding: $gutter*2 $gutter;
    @media #{$md}{padding: $gutter*4 $gutter;}
    @media #{$lg}{padding: $gutter*4 $gutter*1;}
    @media #{$xl}{padding: $gutter*4 $gutter*2;}
    @media #{$xxl}{padding: $gutter*4 $gutter*6;}
}

.inner_spacer_bottom{
    padding: $gutter*0 $gutter $gutter*2;
    @media #{$md}{ padding: $gutter*0 $gutter $gutter*4;}
    @media #{$lg}{ padding: $gutter*0 $gutter*1 $gutter*4;}
    @media #{$xl}{ padding: $gutter*0 $gutter*2 $gutter*4;}
    @media #{$xxl}{padding: $gutter*0 $gutter*6 $gutter*4;}
}

.inner_spacer_top{
    padding-top: $gutter*2;
    @media #{$md}{padding-top: $gutter*2;}
    @media #{$lg}{padding-top: $gutter*3;}
    @media #{$xl}{padding-top: $gutter*4;}
    @media #{$xxl}{padding-top: $gutter*4;}
}

.inner_spacer_y{
    padding: $gutter*2 $gutter;
    @media #{$md}{padding: $gutter*2 $gutter;}
    @media #{$lg}{padding: $gutter*3 $gutter;}
    @media #{$xl}{padding: $gutter*5 $gutter;}
    @media #{$xxl}{padding: $gutter*7 $gutter;}
}
.inner_spacer_y_min{
    padding-top: $gutter*2; padding-bottom: $gutter*2;
    @media #{$md}{padding-top: $gutter*2; padding-bottom: $gutter*2;}
    @media #{$lg}{padding-top: $gutter*3; padding-bottom: $gutter*3;}
    @media #{$xl}{padding-top: $gutter*4; padding-bottom: $gutter*4;}
}


.fullAbsolute{
    position: absolute;top: 0;right: 0;bottom: 0;left: 0;
}

.fullFixed{
    position: fixed;top: 0;right: 0;bottom: 0;left: 0;
}

.bg-overlay{
    @extend .fullAbsolute;
    overflow: hidden;
}

.split_contents_root{
    width: 100%;

    @media #{$header_switch} {
        display: flex;
        flex-direction: row;
        //height: 100%;
    }

    &.fullheight{
        .split{
            height: 100%;
        }
    }
    &.centered{
        @media #{$header_switch}{
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        .split{
        }
    }
    &.reversed {
        @media #{$header_switch} {
            flex-direction: row-reverse;
        }

    }
    .split{
        width: 100%;
        @media #{$header_switch}{
            width: 50%;
        }
        &:nth-of-type(1){}
        &:nth-of-type(2){}
        &.block-texte{
            display: flex;
            flex-direction: column;
            justify-content: center;
            >.inner{
                padding-left: 1rem;
                padding-right: 1rem;
                @media #{$header_switch} {
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        &.block-image-bg {
            position: relative;
            min-height: 400px;
            > div{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
                background-size: cover;
                background-position: center center;
            }
        }
    }
}